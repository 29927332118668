import { action, observable, computed, reaction } from 'mobx';

import { AuthService } from 'services';
import { CredentialsModel, ProfileModel, Role } from 'models';
import config from 'config';

export class AuthStore {
  private readonly authService = new AuthService();

  @observable profile: ProfileModel | null = null;
  @observable token: string | null = null;
  @observable refreshToken: string | null = null;

  constructor() {
    reaction(
      () => this.token,
      (token) => {
        if (token) {
          localStorage.setItem(config.API_TOKEN_KEY, token);
        } else {
          localStorage.removeItem(config.API_TOKEN_KEY);
        }
      }
    );
    reaction(
      () => this.refreshToken,
      (refreshToken) => {
        if (refreshToken) {
          localStorage.setItem(config.REFRESH_TOKEN_KEY, refreshToken);
        } else {
          localStorage.removeItem(config.REFRESH_TOKEN_KEY);
        }
      }
    );
  }

  @computed get isLoggedIn() {
    return Boolean(this.token);
  }

  @action.bound
  async login(credentials: CredentialsModel) {
    const { accessToken, refreshToken, user } = await this.authService.login(credentials);

    this.token = accessToken;
    this.refreshToken = refreshToken;
    this.profile = user;
  }

  @action.bound
  async initializeToken() {
    this.token = localStorage.getItem(config.API_TOKEN_KEY);
    this.refreshToken = localStorage.getItem(config.REFRESH_TOKEN_KEY);
  }

  @action.bound
  async getProfile() {
    this.profile = await this.authService.getProfile();
    return this.profile;
  }

  @computed get roles() {
    return (this.profile ? this.profile.roles.map((role) => role.name) || [] : []) as Role[];
  }

  @action.bound
  async logout() {
    try {
      await this.authService.logout();
    } catch (error) {
      //console.log(error);
    } finally {
      await this.clientLogout();
    }
  }

  @action.bound
  async clientLogout() {
    setTimeout(() => {
      this.token = null;
      this.refreshToken = null;
      this.profile = null;
    });
  }

  @action.bound
  async fetchRefreshToken() {
    if (this.refreshToken) {
      const { accessToken, refreshToken } = await this.authService.fetchRefreshToken(this.refreshToken);

      if (!accessToken || !refreshToken) {
        await this.clientLogout();
      }

      this.token = accessToken;
      this.refreshToken = refreshToken;
    } else {
      await this.clientLogout();
    }
  }
}
