import moment, { Moment } from 'moment';
import { locale } from 'i18n';
import 'moment/locale/en-gb';

moment.locale(locale);

export { moment };

export const DATE_FORMAT = 'L';
export const TIME_FORMAT = 'LTS';

export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const DTO_DATE_FORMAT = 'YYYY-MM-DD';
export const DTO_TIME_FORMAT = 'hh:mm:ss';
export const DTO_DATE_TIME_FORMAT = `${DTO_DATE_FORMAT} ${DTO_TIME_FORMAT}`;

export type InputDateType = number | Date | string | Moment;
export type DateType = Moment;

interface Options {
  utc?: boolean;
}
export function formatDate(
  date?: InputDateType | null,
  formatString: string = DATE_TIME_FORMAT,
  { utc }: Options = { utc: true }
) {
  if (!date) {
    return '';
  }

  const momentDate = utc ? moment.utc(date) : moment(date);

  return momentDate.format(formatString);
}

export function isSameDay(value: InputDateType, comparing: InputDateType) {
  return moment(value).isSame(moment(comparing), 'day');
}

export function getDateInstance(value: InputDateType): DateType {
  return moment(value);
}

export function getMonthsForYear(year: number): DateType[] {
  return Array.from({ length: 12 }).map((_, i) => {
    const month = `${i + 1}`.padStart(2, '0');
    return getDateInstance(`${year}-${month}-01`);
  });
}

export function getYearRange(from = 0, to = 0) {
  const range: DateType[] = [];

  for (let i = from; i <= to; i++) {
    range.push(getDateInstance(`${i}-01-01`));
  }

  return range;
}
