import { EffectCallback, useLayoutEffect, useState } from 'react';
import { throttle } from 'utils/function';

const isBrowser = typeof window !== `undefined`;

function useWindowResize(effect: EffectCallback, wait = 150) {
  const [initialized, setInitialized] = useState(false);
  const handleResize = throttle(effect, wait);

  useLayoutEffect(() => {
    if (!isBrowser) {
      return;
    }

    if (!initialized) {
      effect();
      setInitialized(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [effect, handleResize, initialized]);
}

export default useWindowResize;
