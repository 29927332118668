import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { observer, inject } from 'mobx-react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { CredentialsModel } from 'models';
import { Button, TextField } from 'components';
import { getErrors, isAuthError } from 'utils/request';
import { Yup } from 'utils/yup';

import Logo from '../../assets/icons/rebo-logo.png';

const LOGO_HEIGHT = 100;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      [theme.breakpoints.up('sm')]: {
        marginTop: -(theme.spacing(3) + LOGO_HEIGHT),
      },
    },
    logo: {
      height: LOGO_HEIGHT,
    },
    formContainer: {
      padding: theme.spacing(4),
      margin: theme.spacing(1),
    },
    errorContainer: {
      textAlign: 'center',
      marginTop: 0,
    },
  });

interface LoginPageProps extends WithStyles<typeof styles> {
  login: (credentials: CredentialsModel) => Promise<void>;
}

interface LoginPageState {
  error: boolean;
  showPassword: boolean;
}

class LoginPage extends Component<LoginPageProps, LoginPageState> {
  readonly state: LoginPageState = {
    error: false,
    showPassword: false,
  };

  private static readonly validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string().required(),
  });

  login = async (credentials: CredentialsModel, { setSubmitting }: FormikHelpers<CredentialsModel>) => {
    this.setState({ error: false });
    setSubmitting(true);

    try {
      await this.props.login(credentials);
    } catch (e) {
      const errors = getErrors(e);

      if (errors.find(isAuthError)) {
        this.setState({ error: true });
      }

      setSubmitting(false);
    }
  };

  toggleShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { classes } = this.props;
    const { error, showPassword } = this.state;

    return (
      <div className={classes.root}>
        <Grid container alignItems="center" justify="center" className={classes.container}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Box display="flex" justifyContent="center" mb={3}>
              <img width={LOGO_HEIGHT} height={LOGO_HEIGHT} src={Logo} alt="REBO" />
            </Box>
            <Paper className={classes.formContainer}>
              <Box mb={2}>
                <Typography variant="h6">
                  <Trans i18nKey="login" />
                </Typography>
              </Box>
              <Formik
                initialValues={{ email: '', password: '' } as CredentialsModel}
                validationSchema={LoginPage.validationSchema}
                validateOnChange={false}
                onSubmit={this.login}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Box mb={1}>
                      <Field
                        component={TextField}
                        name="email"
                        label={<Trans i18nKey="email" />}
                        margin="normal"
                        fullWidth
                      />
                    </Box>
                    <Field
                      component={TextField}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      label={<Trans i18nKey="password" />}
                      margin="normal"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={this.toggleShowPassword}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box mb={1}>
                      <FormHelperText error className={classes.errorContainer}>
                        {error ? <Trans i18nKey="errors_login_error" /> : null}
                      </FormHelperText>
                    </Box>
                    <Button type="submit" fullWidth loading={isSubmitting}>
                      <Trans i18nKey="login" />
                    </Button>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(inject(({ auth }) => ({ login: auth.login }))(observer(LoginPage)));
