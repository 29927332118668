export enum EventType {
  CONSUME = 0,
  FILL = 1,
  POUR = 2,
}

export const eventType = (type: EventType) => {
  return `event_type_${type}`;
};

export interface EventModel {
  id: number;
  isManual: boolean;
  didSyncAppleHealth: boolean;
  didSyncGoogleFit: boolean;
  locationLat: number;
  locationLon: number;
  quantity: number;
  type: number;
  createdAt: number;
  updatedAt: number;
  timestamp: number;
  userId: number;
  userName: string;
  userEmail: string;
}
export type CreateEventModel = PartialBy<EventModel, 'id'>;

export interface CreateEventDto extends Omit<CreateEventModel, 'isActive'> {
  isActive: number;
}
