import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import {
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  Button,
} from 'components';
import { NewsStore } from 'store';

import NewsTableRow from './news-table-row.component';
import NewsDialog from './news-dialog.component';

interface NewsListProps extends WithTableQueryParamsProps {
  newsStore: NewsStore;
}

interface NewsListState {
  createDialogOpen: boolean;
}

class NewsList extends Component<NewsListProps, NewsListState> {
  readonly state: NewsListState = {
    createDialogOpen: false,
  };

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps: Readonly<NewsListProps>) {
    if (prevProps.params !== this.props.params) {
      this.loadList();
    }
  }

  loadList = () => {
    return this.props.newsStore.getNews(this.props.params);
  };

  openCreateDialog = () => {
    this.setState({ createDialogOpen: true });
  };

  closeCreateDialog = () => {
    this.setState({ createDialogOpen: false });
  };

  render() {
    const { newsStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage } = this.props;
    const { createDialogOpen } = this.state;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3}>
          <Box display="flex" alignItems="center">
            <Box mr={2} flex={1}>
              <SearchBar
                name="filter_name"
                onSearch={onSearch}
                initialValue={params.filter_name}
                fullWidth
                label={<Trans i18nKey="news_list_search_label" />}
              />
            </Box>
            <Box ml="auto">
              <Button variant="outlined" icon={AddIcon} onClick={this.openCreateDialog}>
                <Trans i18nKey="add" />
              </Button>
              <NewsDialog open={createDialogOpen} onClose={this.closeCreateDialog} onSubmit={this.loadList} />
            </Box>
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={newsStore.newsLoading}
          emptyMessage={<Trans i18nKey="news_list_empty_state_message" />}
          count={newsStore.newsCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <SortableTableCell style={{ width: 50 }} field="id" {...headerParams}>
                  <Trans i18nKey="news_id" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="name" {...headerParams}>
                  <Trans i18nKey="news_name" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} field="imageUrl" {...headerParams}>
                  <Trans i18nKey="news_image" />
                </SortableTableCell>
                <SortableTableCell field="description" {...headerParams}>
                  <Trans i18nKey="news_description" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="timestamp" {...headerParams}>
                  <Trans i18nKey="event_timestamp" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} />
                <SortableTableCell style={{ width: 40 }} />
                <SortableTableCell style={{ width: 40 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {newsStore.news?.map((news) => (
                <NewsTableRow key={news.id} news={news} loadList={this.loadList} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ news }: StoreModel) => {
  return { newsStore: news };
};

export default withTableQueryParams({ sortBy: 'name' })(inject(mapStoreToProps)(observer(NewsList)));
