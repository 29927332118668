export function removeFalsy<P extends object>(obj: P) {
  const newObj = {} as P;
  const keys = (Object.keys(obj) as unknown) as Array<keyof P>;

  keys.forEach((prop) => {
    if (obj[prop] || (obj[prop] as unknown) === 0) {
      newObj[prop] = obj[prop];
    }
  });

  return newObj;
}

export function isEmpty<P extends object>(obj: P) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function removeProp<P extends object>(obj: P, prop: keyof P | string) {
  return Object.keys(obj).reduce((object, key) => {
    if (key !== prop) {
      object[key as keyof P] = obj[key as keyof P];
    }
    return object;
  }, {} as P) as P;
}

export function get(obj: any, path: string) {
  const paths = path.split('.');

  for (let i = 0; i < paths.length; i++) {
    obj = obj ? obj[paths[i]] : null;
  }

  return obj;
}
