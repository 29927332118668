import React, { Component, Fragment } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import {
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  Button,
} from 'components';
import { WorkoutEventStore } from 'store';

import { compose } from '../../utils/function';
import WorkoutEventTableRow from './workout-event-table-row.component';
import { DownloadIcon } from '../../assets/icons';
import { Tooltip } from '@material-ui/core';

interface WorkoutEventListProps extends WithTableQueryParamsProps, WithTranslation {
  workoutEventStore: WorkoutEventStore;
}

interface WorkoutEventListState {
  createDialogOpen: boolean;
}

class WorkoutEventList extends Component<WorkoutEventListProps, WorkoutEventListState> {
  readonly state: WorkoutEventListState = {
    createDialogOpen: false,
  };

  async componentDidMount() {
    await this.loadList();
  }

  async componentDidUpdate(prevProps: Readonly<WorkoutEventListProps>) {
    if (prevProps.params !== this.props.params) {
      await this.loadList();
    }
  }
  getExportUrl = () => {
    return this.props.workoutEventStore.getExportUrl(this.props.params);
  };

  loadList = async () => {
    return await this.props.workoutEventStore.getWorkoutEvents(this.props.params);
  };

  render() {
    const { workoutEventStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage } = this.props;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3} display="flex" width="100%">
          <Box flex={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <SearchBar
                  name="filter_nameOrEmail"
                  onSearch={onSearch}
                  initialValue={params.filter_nameOrEmail}
                  fullWidth
                  label={<Trans i18nKey="workout-event_list_search_label" />}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <SearchBar
                  name="filter_activity"
                  onSearch={onSearch}
                  initialValue={params.filter_activity}
                  fullWidth
                  label={<Trans i18nKey="workout-event_list_activity_search_label" />}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box display="flex" mt={1} mb={2}>
                  <Box ml="auto">
                    <Tooltip title={<Trans i18nKey="event_export" />}>
                      <Button href={this.getExportUrl()} variant="outlined" icon={DownloadIcon}>
                        <Trans i18nKey="export" />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={workoutEventStore.workoutEventLoading}
          emptyMessage={<Trans i18nKey="workout-event_list_empty_state_message" />}
          count={workoutEventStore.workoutEventCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <SortableTableCell style={{ width: 50 }} field="id" {...headerParams}>
                  <Trans i18nKey="workout-event_id" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 250 }} field="user_email" {...headerParams}>
                  <Trans i18nKey="workout-event_user_name_email" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 200 }} field="activity_name" {...headerParams}>
                  <Trans i18nKey="workout-event_activity-name" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 200 }} field="start_timestamp" {...headerParams}>
                  <Trans i18nKey="workout-event_start-timestamp" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 200 }} field="end_timestamp" {...headerParams}>
                  <Trans i18nKey="workout-event_end-timestamp" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="energy_burned_in_kcal" {...headerParams}>
                  <Trans i18nKey="workout-event_energy-burned-in-kcal" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="distance_covered_in_meters" {...headerParams}>
                  <Trans i18nKey="workout-event_distance-covered-in-meters" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="flights_climbed" {...headerParams}>
                  <Trans i18nKey="workout-event_flights-climbed" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="swimming_strokes" {...headerParams}>
                  <Trans i18nKey="workout-event_swimming-strokes" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="step_count_delta" {...headerParams}>
                  <Trans i18nKey="workout-event_step-count-delta" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="activity_duration_in_ms" {...headerParams}>
                  <Trans i18nKey="workout-event_activity-duration-in-ms" />
                </SortableTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workoutEventStore.workoutEvents?.map((workoutEvent) => (
                <WorkoutEventTableRow key={workoutEvent.id} workoutEvent={workoutEvent} loadList={this.loadList} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ workoutEvents }: StoreModel) => {
  return { workoutEventStore: workoutEvents };
};

export default compose(
  withTableQueryParams({ sortBy: 'activity_name' }),
  withTranslation(),
  inject(mapStoreToProps),
  observer
)(WorkoutEventList);
