import request, { generateUrl, Methods } from 'utils/request';
import { AddTeamUserModel, ListResponseModel, QueryParamModel, TeamUserModel } from 'models';

export class TeamUserService {
  private teamId = 0;

  get resource() {
    return `team-users/${this.teamId}`;
  }

  setTeamId(id: number) {
    this.teamId = id;
  }

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<TeamUserModel>>({ resource: this.resource, params });
  }

  create(data: AddTeamUserModel) {
    return request<TeamUserModel>({ method: Methods.POST, resource: this.resource, data });
  }

  update(id: number, data: AddTeamUserModel) {
    return request<TeamUserModel>({ method: Methods.PUT, resource: `${this.resource}/${id}`, data });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `team-users/${id}` });
  }

  getExportUrl(token: string | null, params?: QueryParamModel) {
    return generateUrl({ resource: `${this.resource}/export`, params: { ...params, token } });
  }
}
