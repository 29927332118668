import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import {
  TableCell,
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  TableContainer,
  Button,
} from 'components';
import { TeamUserStore } from 'store';
import TeamTableRow from './team-user-table-row.component';
import TeamDialog from './team-user-dialog.component';
import { TeamUserShowModel } from '../../../models';

interface TeamUserListProps extends WithTableQueryParamsProps {
  teamUserStore: TeamUserStore;
  id: number;
}

interface TeamUserListState {
  createDialogOpen: boolean;
}

class TeamUserList extends Component<TeamUserListProps, TeamUserListState> {
  readonly state: TeamUserListState = {
    createDialogOpen: false,
  };

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps: Readonly<TeamUserListProps>) {
    if (prevProps.params !== this.props.params) {
      this.loadList();
    }
  }

  loadList = () => {
    return this.props.teamUserStore.setTeamId(this.props.id).getTeam(this.props.params);
  };

  openCreateDialog = () => {
    this.setState({ createDialogOpen: true });
  };

  closeCreateDialog = () => {
    this.setState({ createDialogOpen: false });
  };

  render() {
    const { teamUserStore, params, onSort, onChangeRowsPerPage, onChangePage } = this.props;
    const { createDialogOpen } = this.state;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3}>
          <Box display="flex" alignItems="center">
            <Box mr={2} flex={1} />
            <Box ml="auto">
              <Button variant="outlined" icon={AddIcon} onClick={this.openCreateDialog}>
                <Trans i18nKey="add" />
              </Button>
              <TeamDialog open={createDialogOpen} onClose={this.closeCreateDialog} onSubmit={this.loadList} />
            </Box>
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={teamUserStore.teamLoading}
          emptyMessage={<Trans i18nKey="team_list_empty_state_message" />}
          count={teamUserStore.teamCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <SortableTableCell style={{ width: 50 }} field="id" {...headerParams}>
                  <Trans i18nKey="team_id" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} field="user" {...headerParams}>
                  <Trans i18nKey="team_user_email" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 50 }} field="subTeam" {...headerParams}>
                  <Trans i18nKey="team_name" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 50 }} field="status" {...headerParams}>
                  <Trans i18nKey="status" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 50 }} field="createdAt" {...headerParams}>
                  <Trans i18nKey="team_added_date" />
                </SortableTableCell>
                <TableCell style={{ width: 40 }} />
                <TableCell style={{ width: 40 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {teamUserStore.teams
                ?.map((user) => {
                  const code = `${user.subTeam?.team?.code}${user.subTeam?.code}`;
                  const subTeam = `${user.subTeam?.name} ([${code}])`;
                  return {
                    id: user.id,
                    status: user.status,
                    user: user.user?.email,
                    subTeam,
                    code,
                    createdAt: user.createdAt,
                  } as TeamUserShowModel;
                })
                .map((teamUser) => (
                  <TeamTableRow key={teamUser.id} teamUser={teamUser} loadList={this.loadList} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ teamUser }: StoreModel) => {
  return { teamUserStore: teamUser };
};

export default withTableQueryParams({ sortBy: 'createdAt' })(inject(mapStoreToProps)(observer(TeamUserList)));
