import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { ApiError, ConfirmDialog } from 'components';
import { ApiErrorModel, NotificationType, FirmwareModel } from 'models';
import { WithNotification } from 'store';

interface FirmwareDeleteProps extends WithNotification {
  onSubmit: () => void;
  firmware: FirmwareModel;
  deleteFirmware: (id: number) => Promise<void>;
}

const FirmwareDelete: FC<FirmwareDeleteProps> = ({ onSubmit, firmware, showNotification, deleteFirmware }) => {
  const [error, setError] = useState<ApiErrorModel | null>(null);

  const handleSubmit = async () => {
    try {
      setError(null);

      if (firmware) {
        await deleteFirmware(firmware.id);
      }

      showNotification(<Trans i18nKey="delete_successful" />, NotificationType.SUCCESS);
      onSubmit();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <ConfirmDialog title={<Trans i18nKey="firmware_delete" />} onSubmit={handleSubmit}>
      <Trans i18nKey="firmware_delete_message" values={{ name: firmware.version }} />
      <ApiError error={error} />
    </ConfirmDialog>
  );
};

export default inject(({ firmware, common }) => {
  return {
    showNotification: common.showNotification,
    deleteFirmware: firmware.deleteFirmware,
  };
})(observer(FirmwareDelete));
