import React, { ChangeEventHandler, FC, useCallback } from 'react';
import classNames from 'classnames';
import { FieldProps } from 'formik';

import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

import config from 'config';
import { get } from 'utils/objects';

import ErrorMessage from './error-message.component';

interface TextFieldProps extends Partial<FieldProps> {
  showError?: boolean;
  readOnly?: boolean;
  maxLength?: number;
}

const useStyles = makeStyles(() => {
  return {
    error: {
      marginTop: 0,
    },
    fullWidth: {
      width: '100%',
    },
    maxLength: {
      fontSize: '80%',
      textAlign: 'right',
      paddingRight: '3px',
      marginTop: '-25px',
    },
  };
});

const TextField: FC<TextFieldProps & MuiTextFieldProps> = ({
  field,
  form,
  fullWidth,
  showError = true,
  readOnly,
  variant = config.DEFAULT_INPUT_VARIANT as any,
  type,
  maxLength,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  // Workaround for non-Chrome browsers to handle input type fields as they should
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const changeHandler = onChange || field?.onChange;

      if (changeHandler) {
        const { value } = e.target;

        if (type === 'number') {
          const parsedValue = Number(value);

          if (!isNaN(parsedValue)) {
            changeHandler(e);
          }
        } else {
          changeHandler(e);
        }
      }
    },
    [onChange, field, type]
  );

  return (
    <div className={classNames({ [classes.fullWidth]: fullWidth })}>
      <MuiTextField
        {...(field || {})}
        {...props}
        onChange={handleChange}
        variant={variant}
        type={type === 'number' ? 'text' : type}
        inputProps={{
          ...(props.inputProps || {}),
          readOnly: (props.InputProps || {}).readOnly || readOnly || false,
          maxLength,
        }}
        value={field ? field.value ?? '' : props.value ?? ''}
        fullWidth={fullWidth}
        error={Boolean(form && field && get(form.errors, field.name) && get(form.touched, field.name))}
      />
      {maxLength ? (
        <div className={classes.maxLength}>
          {field && field.value ? field.value.length : 0} / {maxLength}
        </div>
      ) : null}
      {showError && form && field ? (
        <ErrorMessage field={field} form={form} variant={variant} className={classes.error} />
      ) : null}
    </div>
  );
};

export default TextField;
