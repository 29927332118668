import React, { Component, HTMLAttributes, createRef } from 'react';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FieldProps } from 'formik';

import ReactSelect, { ActionMeta, components, ValueType, Props as SelectProps, OptionTypeBase } from 'react-select';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { InputProps } from 'react-select/src/components/Input';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps, MenuListComponentProps } from 'react-select/src/components/Menu';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { IndicatorProps } from 'react-select/src/components/indicators';

import Typography, { TypographyProps } from '@material-ui/core/Typography';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, emphasize, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Close';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { ErrorMessage, ConditionalWrapper } from 'components';
import config from 'config';

export interface OptionType extends OptionTypeBase {
  [key: string]: string;
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<OptionType>, 'innerProps'>>;

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

interface OnChangeMeta extends ActionMeta {
  removedValue?: OptionType;
}

const styles = (theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
      alignItems: 'center',
      height: 'auto',
      padding: `0 ${theme.spacing(1)}px 0 0`,
    },
    outlinedInput: {
      padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
      minHeight: 56,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    valueContainerMulti: {
      marginTop: theme.spacing(1),
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: 26,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08
      ),
    },
    message: {
      padding: theme.spacing(1, 2),
    },
    paper: {
      position: 'absolute',
      zIndex: 10,
      left: 0,
      right: 0,
    },
    selectedOption: {
      fontWeight: 'bold',
    },
  });

function BaseMessage(props: TypographyProps) {
  return <Typography color="textSecondary" variant="body2" align="center" {...props} />;
}

function Message(props: NoticeProps<OptionType>) {
  return (
    <BaseMessage {...props.innerProps} className={props.selectProps.classes.message}>
      {props.children}
    </BaseMessage>
  );
}

function InputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    isFocused,
    hasValue,
    selectProps: { classes, TextFieldProps, name, inputValue },
  } = props;

  const { InputLabelProps, ...textFieldProps } = TextFieldProps;

  return (
    <TextField
      fullWidth
      name={name}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: classNames(classes.input, {
            [classes.outlinedInput]: textFieldProps.variant && textFieldProps.variant !== 'standard',
          }),
          ref: innerRef,
          children,
          ...innerProps,
          value: inputValue,
        },
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: Boolean(InputLabelProps.shrink || inputValue || isFocused || hasValue),
      }}
      {...textFieldProps}
    />
  );
}

function Option(props: OptionProps<OptionType>) {
  const isFormattingDefined = !!props.selectProps.formatOptionLabel;

  return (
    <MenuItem ref={props.innerRef} selected={props.isFocused} component="div" {...props.innerProps}>
      <ConditionalWrapper
        condition={!isFormattingDefined}
        wrapper={(children) => (
          <Tooltip title={props.children}>
            <Typography noWrap className={classNames({ [props.selectProps.classes.selectedOption]: props.isSelected })}>
              {children}
            </Typography>
          </Tooltip>
        )}
      >
        {props.children}
      </ConditionalWrapper>
    </MenuItem>
  );
}

function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps = {}, children } = props;

  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  const isFormattingDefined = !!props.selectProps.formatOptionLabel;

  return (
    <ConditionalWrapper
      condition={!isFormattingDefined}
      wrapper={(children) => (
        <Tooltip title={props.children} placement="bottom-start">
          <Typography noWrap className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {children}
          </Typography>
        </Tooltip>
      )}
    >
      {props.children}
    </ConditionalWrapper>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return (
    <div
      className={classNames(props.selectProps.classes.valueContainer, {
        [props.selectProps.classes.valueContainerMulti]: props.selectProps.isMulti,
      })}
    >
      {props.children}
    </div>
  );
}

function Input(props: InputProps) {
  const input = <components.Input {...props} />;

  if ((props as any).selectProps.isMulti) {
    return <Box mb={1}>{input}</Box>;
  }

  return <Box position="absolute">{input}</Box>;
}

function MultiValue(props: MultiValueProps<OptionType>) {
  const { selectProps, data } = props;

  return (
    <Chip
      color="primary"
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={!data[selectProps.fixedKey] ? props.removeProps.onClick : undefined}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper
      elevation={2}
      className={classNames(props.selectProps.classes.paper)}
      style={props.getStyles('menu', props)}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function MenuList(props: MenuListComponentProps<OptionType>) {
  return (
    <components.MenuList {...props}>
      {props.children}
      {props.selectProps.isPaging ? (
        <BaseMessage className={props.selectProps.classes.message}>
          {props.selectProps.loadingMessage
            ? props.selectProps.loadingMessage({ inputValue: props.selectProps.inputValue || '' })
            : ''}
        </BaseMessage>
      ) : null}
    </components.MenuList>
  );
}

function ClearIndicator(props: IndicatorProps<OptionType>) {
  return (
    <IconButton {...props.innerProps}>
      <CancelIcon fontSize="small" />
    </IconButton>
  );
}

function DropdownIndicator(props: IndicatorProps<OptionType>) {
  return (
    <IconButton {...props.innerProps}>
      <ArrowDownIcon fontSize="small" />
    </IconButton>
  );
}

function LoadingIndicator() {
  return (
    <Box ml={1} mr={1} display="flex" alignItems="center">
      <CircularProgress size={20} />
    </Box>
  );
}

export interface AutocompleteProps<ValueType extends OptionType = OptionType>
  extends WithStyles<typeof styles, true>,
    Partial<FieldProps>,
    Omit<SelectProps<ValueType>, 'theme'>,
    WithTranslation {
  valueKey?: keyof ValueType;
  displayKey?: keyof ValueType;
  fixedKey?: 'string';
  label?: React.ReactNode;
  placeholder?: string;
  error?: boolean;
  name?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  margin?: 'none' | 'dense' | 'normal';
  helperText?: React.ReactNode;
  isPaging?: boolean;
}

interface AutocompleteState {
  menuPlacement: 'auto' | 'bottom' | 'top' | undefined;
}

const overriddenComponents = {
  Control,
  Menu,
  MenuList,
  MultiValue,
  NoOptionsMessage: Message,
  LoadingMessage: Message,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  Input,
  LoadingIndicator,
  ClearIndicator,
  DropdownIndicator,
};

class Autocomplete extends Component<AutocompleteProps> {
  readonly state: AutocompleteState = {
    menuPlacement: this.props.menuPlacement,
  };

  rootRef = createRef<any>();

  componentDidMount() {
    this.setMenuPlacement();
  }

  componentDidUpdate() {
    this.setMenuPlacement();
  }

  setMenuPlacement = () => {
    let menuPlacement = this.state.menuPlacement;

    if (
      this.rootRef.current &&
      menuPlacement === 'auto' &&
      window.innerWidth >= this.props.theme.breakpoints.values.sm
    ) {
      const { select } = this.rootRef.current;

      if (window.innerHeight < select.controlRef.getBoundingClientRect().bottom + select.props.maxMenuHeight) {
        menuPlacement = 'top';
      } else {
        menuPlacement = this.props.menuPlacement;
      }
    }

    if (menuPlacement !== this.state.menuPlacement) {
      this.setState({ menuPlacement });
    }
  };

  onBlur = (e: React.FocusEvent<HTMLElement>) => {
    const { form, field, onBlur } = this.props;

    if (form && field) {
      form.setFieldTouched(field.name, true, form.validateOnBlur);
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  onChange = (value: ValueType<OptionType>, { action, removedValue }: OnChangeMeta) => {
    const { form, field, onChange } = this.props;

    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue && removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = this.props.options.filter((option: OptionType) => this.props.fixedKey && option[this.props.fixedKey]);
        break;
    }

    if (form && field) {
      form.setFieldValue(field.name, value);
    }

    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const {
      field,
      form,
      classes,
      label,
      id,
      error,
      helperText,
      variant = config.DEFAULT_INPUT_VARIANT,
      name,
      placeholder,
      options,
      value,
      margin,
      isLoading,
      isPaging,
      menuPlacement,
      theme,
      ...props
    } = this.props;

    return (
      <div>
        <ReactSelect
          ref={this.rootRef}
          classes={classes}
          inputId={id}
          name={value || (field && field.name)}
          value={value || (field && field.value)}
          isLoading={isLoading}
          options={isLoading && !isPaging ? [] : options}
          placeholder={placeholder || ''}
          onChange={this.onChange}
          menuPlacement={this.state.menuPlacement}
          onBlur={this.onBlur}
          noOptionsMessage={() => this.props.t('select_field_no_options')}
          loadingMessage={() => this.props.t('select_field_loading')}
          TextFieldProps={{
            label,
            id,
            name,
            margin,
            error: Boolean(error || (form && field && form.errors[field.name] && form.touched[field.name])),
            helperText,
            variant,
            InputLabelProps: {
              htmlFor: id,
            },
          }}
          components={overriddenComponents}
          getOptionLabel={(option) => option[this.props.displayKey || 'label']}
          getOptionValue={(option) => option[this.props.valueKey || 'value']}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 1350 }) }}
          isPaging={isPaging}
          {...props}
        />
        {form && field ? <ErrorMessage form={form} field={field} variant={variant} /> : null}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withTranslation()(Autocomplete));
