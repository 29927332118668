import React, { FC, MouseEvent, SyntheticEvent } from 'react';
import classNames from 'classnames';
import MuiDialog, { DialogProps as MuiDialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => {
  return {
    hidden: {
      visibility: 'hidden',
    },
    title: {
      zIndex: 100,
      background: theme.palette.background.paper,
    },
    titleWithClose: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1, 2, 1, 3),
    },
    content: {
      padding: theme.spacing(0, 3, 2, 3),
      display: 'flex',
      flexDirection: 'column',
    },
    disableContentPadding: {
      padding: 0,
    },
  };
});

export interface BaseDialogProps {
  title?: React.ReactNode;
  open: boolean;
  loading?: boolean;
  onClose?: (event?: SyntheticEvent<any>, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

export interface DialogProps extends Omit<MuiDialogProps, 'title' | 'onClose'>, BaseDialogProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
  showClose?: boolean;
  disableContentPadding?: boolean;
}

export const Dialog: FC<DialogProps> = ({
  title,
  maxWidth,
  fullWidth,
  children,
  actions,
  loading,
  onClose,
  showClose,
  fullScreen,
  disableContentPadding,
  ...props
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const handleClose = (event: MouseEvent<any>, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      onClose(event, reason);
    }
  };

  return (
    <MuiDialog
      maxWidth={maxWidth || 'sm'}
      fullWidth={fullWidth !== false}
      onClose={handleClose}
      fullScreen={fullScreen || isSmallScreen}
      {...props}
    >
      <LinearProgress className={classNames({ [classes.hidden]: !loading })} />
      <DialogTitle
        disableTypography
        className={classNames(classes.title, { [classes.titleWithClose]: showClose && onClose })}
      >
        <Typography variant="h6">{title}</Typography>
        {showClose && onClose ? (
          <IconButton onClick={handleClose} edge="end">
            <CloseIcon color="action" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent
        className={classNames(classes.content, { [classes.disableContentPadding]: disableContentPadding })}
      >
        {children}
      </DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </MuiDialog>
  );
};
