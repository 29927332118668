import React, {FC, useEffect, useState} from "react";
import { Trans } from 'react-i18next';
import { Page } from "components";
import UserProfileDetails from './user-profile-details.component';
import { useParams } from "react-router-dom";
import { BottleModel, UserExtendedModel   } from "models";
import {UserService, BottleService, EventService} from "../../../services";
import LinearProgress from "@material-ui/core/LinearProgress";

interface RouteParams {
  id: string;
  uuid: string;
}

const initUser = {  country: "",
    createdAt: "",
    lastLoginAt: "",
    bottlesSaved: 0,
    bottlesCollected: 0,
    additionalBottlesCollected: 0,
    id: 0,
    email: "",
    name: "",
    gender: 0,
    uuid: "",
    roles: [],
    dailyStreak: 0,
    dailyCap: 0,
    customHydrationGoal: 0,
}

const initBottle = {
  id: 0,
  version: "",
  model: "",
  manufacturer: "",
  brand: "",
  variant: {
    id: 0,
    sku: "",
    name: "",
    imageUrl: "",
  },
  name: "",
  macAddress: "",
  NFCSerialNumber:"",
  sku: "",
  batteryLevel: 0,
}

const UserProfile: FC = () => {
  const params = useParams<RouteParams>();
  const userService = new UserService()
  const bottleService = new BottleService()
  const eventService = new EventService()
  const [user, setUser] = useState<UserExtendedModel>(initUser);
  const [bottle, setBottle] = useState<BottleModel>(initBottle)
  const [usage, setUsage] = useState<any>({events: {}})
  const [hasEvents, setHasEvents] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {

    userService.getUser(parseInt(params.id)).then((res: any) => {
      setUser(res)
    })

    bottleService.getBottleByOwner(parseInt(params.id)).then((res: any) => {
      setBottle(res)
    });

    eventService.usage(parseInt(params.id)).then((res: any) => {
      setUsage(res)
      if(!(typeof res.events.last === 'undefined')) setHasEvents(true)
      setIsLoading(false)
    });


  }, [params.id])

  if(isLoading){
    return (<Page>
      <LinearProgress />
    </Page>)
  }

  return (
    <Page title={user.name}>
      <UserProfileDetails user={user} bottle={bottle} usage={usage} hasEvents={hasEvents}></UserProfileDetails>
    </Page>
  );
};
export default UserProfile;
