import request, { generateUrl, Methods } from 'utils/request';
import { AddTeamModel, ListResponseModel, QueryParamModel, TeamModel } from 'models';

export class TeamService {
  private readonly resource = 'teams';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<TeamModel>>({ resource: this.resource, params });
  }

  create(data: AddTeamModel) {
    return request<TeamModel>({ method: Methods.POST, resource: this.resource, data });
  }

  update(id: number, data: AddTeamModel) {
    return request<TeamModel>({ method: Methods.PUT, resource: `${this.resource}/${id}`, data });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `${this.resource}/${id}` });
  }

  getExportUrl(token: string | null, params?: QueryParamModel) {
    return generateUrl({ resource: `${this.resource}/export`, params: { ...params, token } });
  }
}
