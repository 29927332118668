import React, { FC, Fragment } from 'react';

import { NotificationType, SubTeamModel } from 'models';
import { TableRow, TableCell, TableRowProps, useDialogState } from 'components';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from 'assets/icons';

import SubTeamDialog from './sub-team-dialog.component';
import SubTeamDelete from './sub-team-delete.component';
import { DTO_DATE_TIME_FORMAT, formatDate } from '../../../utils/dates';
import { Link } from 'react-router-dom';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { copyTextToClipboard } from '../../../utils/clipboard';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { WithNotification } from '../../../store';

interface TeamTableRowProps extends TableRowProps, WithNotification {
  team: SubTeamModel;
  loadList: () => void;
}

const TeamTableRow: FC<TeamTableRowProps> = ({ team, showNotification, loadList, ...props }) => {
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();

  const copyClipboard = () => {
    copyTextToClipboard(`[${team.code}]`)
      .then(() => {
        showNotification(<Trans i18nKey="clipboard_successful" />, NotificationType.SUCCESS);
      })
      .catch(() => {
        showNotification(<Trans i18nKey="clipboard_fail" />, NotificationType.WARNING);
      });
  };

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{team.id}</TableCell>
        <TableCell>
          <Link to={`/team-user/${team.id}`}>{team.name}</Link>
        </TableCell>
        <TableCell>[{team.code}]</TableCell>
        <TableCell>{formatDate(team.createdAt * 1000, DTO_DATE_TIME_FORMAT)}</TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={copyClipboard}>
            <CopyIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={openEditDialog}>
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <SubTeamDelete subTeam={team} onSubmit={loadList} />
        </TableCell>
      </TableRow>

      <SubTeamDialog open={editDialogOpen} onClose={closeEditDialog} subTeam={team} onSubmit={loadList} />
    </Fragment>
  );
};

export default inject(({ common }) => {
  return {
    showNotification: common.showNotification,
  };
})(observer(TeamTableRow));
