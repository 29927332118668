import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Dialog } from 'components';
import { CreateEventDto, EventModel, eventType } from 'models';
import { WithNotification } from 'store';
import { ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_TIME_FORMAT, formatDate } from '../../utils/dates';

interface EventDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  event: EventModel;
  createEvent: (data: CreateEventDto) => Promise<void>;
  updateEvent: (id: number, data: CreateEventDto) => Promise<void>;
  eventSaving: boolean;
}

const styles = makeStyles((theme) => ({
  label: {
    width: '50%',
  },
  value: {
    width: '50%',
    testAlign: 'left',
  },
}));

const EventDialog: FC<EventDialogProps> = ({ open, onClose, event }) => {
  const classes = styles();

  return (
    <Dialog open={open} onClose={onClose} title={<Trans i18nKey="event_info" />} showClose>
      <List>
        <ListItem key={'event_id'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="event_id" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{event.id}</ListItemText>
        </ListItem>
        <ListItem key={'event_type'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="event_type" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{<Trans i18nKey={eventType(event.type)} />}</ListItemText>
        </ListItem>
        <ListItem key={'event_is_manual'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="event_is_manual" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{event.isManual ? 'yes' : 'no'}</ListItemText>
        </ListItem>
        <ListItem key={'event_timestamp'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="event_timestamp" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>
            {event.timestamp ? formatDate(Number(event.timestamp) * 1000, DATE_TIME_FORMAT) : ''}
          </ListItemText>
        </ListItem>
        <ListItem key={'event_quantity'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="event_quantity" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{event.quantity} ml</ListItemText>
        </ListItem>
        <ListItem key={'event_user_email'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="event_user_email" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{event.userEmail}</ListItemText>
        </ListItem>
        <ListItem key={'event_gps_coordinate'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="event_gps_coordinate" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>
            {event.locationLat}, {event.locationLon}
          </ListItemText>
        </ListItem>
      </List>
    </Dialog>
  );
};

export default inject(({ event, common }) => {
  return {
    showNotification: common.showNotification,
    createEvent: event.createEvent,
    updateEvent: event.updateEvent,
    eventSaving: event.eventSaving,
  };
})(observer(EventDialog));
