import { ComponentType, FC } from 'react';

import { CommonStore } from './common.store';
import { AuthStore } from './auth.store';
import { UsersStore } from './users.store';
import { SettingsStore } from './settings.store';
import { NewsStore } from './news.store';
import { EventStore } from './event.store';
import { HealthStore } from './health.store';
import { NotificationsStore } from './notifications.store';
import { WorkoutEventStore } from './workout-event.store';
import { FirmwareStore } from './firmware.store';
import { BottleModelStore } from './bottle-model.store';
import { TeamStore } from './team.store';
import { SubTeamStore } from './sub-team.store';
import { TeamUserStore } from './team-user.store';
import { GoogleDiskStore } from './google-disk.store';
import { SystemStore } from './system.store';
import { LogsStore } from './logs.store';

declare global {
  type StoreModel = typeof Store;
}

declare module 'mobx-react' {
  export function inject<D extends object>(
    mapStoreToProps: (store: StoreModel) => D
  ): <A extends D>(component: ComponentType<A> | FC<A>) => FC<Omit<A, keyof D> & Partial<D>>;
}

const common = new CommonStore();
const auth = new AuthStore();
const users = new UsersStore(auth);
const news = new NewsStore();
const event = new EventStore(auth);
const health = new HealthStore(auth);
const settings = new SettingsStore();
const notifications = new NotificationsStore();
const workoutEvents = new WorkoutEventStore(auth);
const firmware = new FirmwareStore();
const bottleModel = new BottleModelStore();
const team = new TeamStore();
const subTeam = new SubTeamStore();
const teamUser = new TeamUserStore();
const googleDisk = new GoogleDiskStore();
const system = new SystemStore();
const logs = new LogsStore();

export const Store = {
  common,
  auth,
  users,
  news,
  event,
  health,
  settings,
  notifications,
  workoutEvents,
  firmware,
  bottleModel,
  team,
  subTeam,
  teamUser,
  googleDisk,
  system,
  logs,
};

export * from './common.store';
export * from './auth.store';
export * from './users.store';
export * from './news.store';
export * from './event.store';
export * from './health.store';
export * from './settings.store';
export * from './notifications.store';
export * from './workout-event.store';
export * from './firmware.store';
export * from './bottle-model.store';
export * from './team.store';
export * from './sub-team.store';
export * from './team-user.store';
export * from './logs.store';
