import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import makeStyles from '@material-ui/core/styles/makeStyles';

import config from 'config';
import { get } from 'utils/objects';
import { DATE_FORMAT } from 'utils/dates';

import ErrorMessage from './error-message.component';

const useStyles = makeStyles(() => {
  return {
    fullWidth: {
      width: '100%',
    },
  };
});

interface DatepickerComponent extends Partial<FieldProps>, Partial<Omit<DatePickerProps, 'onChange'>> {
  onChange: (value: string) => void;
}

const DatepickerComponent: FC<DatepickerComponent> = ({
  field,
  form,
  name,
  value,
  fullWidth,
  variant,
  format,
  onChange,
  InputLabelProps,
  inputVariant = config.DEFAULT_INPUT_VARIANT,
  ...props
}) => {
  const classes = useStyles();
  const val = (field && field.value) || value || null;

  const handleChange = async (value: MaterialUiPickersDate) => {
    if (onChange) {
      onChange(value ? value.toISOString() : '');
    }

    if (form && field) {
      await form.setFieldValue(field.name, value ? value.toISOString() : '');
      form.setFieldTouched(field.name, true);
    }
  };

  const onClose = () => {
    if (form && field) {
      form.setFieldTouched(field.name, true);
    }
  };

  return (
    <div className={classNames({ [classes.fullWidth]: fullWidth })}>
      <DatePicker
        value={val}
        name={(field && field.name) || name}
        variant={variant}
        inputVariant={inputVariant}
        fullWidth={fullWidth}
        format={format || DATE_FORMAT}
        onClose={onClose}
        onChange={handleChange}
        error={Boolean(form && field && get(form.errors, field.name) && get(form.touched, field.name))}
        cancelLabel={<Trans i18nKey="cancel" />}
        okLabel={<Trans i18nKey="save" />}
        InputLabelProps={{
          ...(InputLabelProps || {}),
          shrink: Boolean(val),
        }}
        {...props}
      />
      {form && field ? <ErrorMessage field={field} form={form} variant={inputVariant} /> : null}
    </div>
  );
};

export default DatepickerComponent;
