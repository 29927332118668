import { action, observable } from 'mobx';

import { HealthService } from 'services';
import { CreateHealthDto, QueryParamModel, HealthModel } from 'models';
import { AuthStore } from './auth.store';

export class HealthStore {
  private readonly healthService = new HealthService();
  private readonly authStore: AuthStore;

  @observable healths: HealthModel[] = [];
  @observable healthCount = 0;

  @observable healthLoading = false;
  @observable healthSaving = false;
  @observable healthDeleting = false;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action.bound
  async getHealths(params?: QueryParamModel) {
    this.healthLoading = true;

    try {
      const response = await this.healthService.getList(params);

      this.healthCount = response.count;
      this.healths = response.data;

      return response;
    } finally {
      this.healthLoading = false;
    }
  }

  @action.bound
  async updateHealth(id: number, data: CreateHealthDto) {
    this.healthSaving = true;

    try {
      await this.healthService.update(id, data);
    } finally {
      this.healthSaving = false;
    }
  }

  @action.bound
  async createHealth(data: CreateHealthDto) {
    this.healthSaving = true;

    try {
      await this.healthService.create(data);
    } finally {
      this.healthSaving = false;
    }
  }

  @action.bound
  async deleteHealth(id: number) {
    this.healthDeleting = true;

    try {
      await this.healthService.delete(id);
    } finally {
      this.healthDeleting = false;
    }
  }

  @action.bound
  getExportUrl(params?: QueryParamModel) {
    return this.healthService.getExportUrl(this.authStore.token, params);
  }
}
