import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles';

import {
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  Button,
} from 'components';
import { UserModel } from 'models';
import { UsersStore } from 'store';
import { compose } from 'utils/function';

// import UserComplexFilter from './user-complex-filter.component';
import UserTableRow from './user-table-row.component';
import { Tooltip } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { DownloadIcon } from '../../../assets/icons';

const styles = (theme: Theme) =>
  createStyles({
    searchContainer: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(2),
        flex: 1,
      },
    },
    actionsContainer: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      },
    },
  });

interface UserListProps extends WithTableQueryParamsProps, WithStyles<typeof styles> {
  usersStore: UsersStore;
}

interface UserListState {
  selectedUsers: UserModel[];
}

class UserList extends Component<UserListProps, UserListState> {
  readonly state: UserListState = {
    selectedUsers: [],
  };

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps: Readonly<UserListProps>) {
    if (prevProps.params !== this.props.params) {
      this.loadList();
    }
  }

  loadList = () => {
    return this.props.usersStore.getUsers(this.props.params);
  };

  selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      this.setState({ selectedUsers: [...this.props.usersStore.userList] });
    } else {
      this.setState({ selectedUsers: [] });
    }
  };

  selectRow = (user: UserModel) => {
    const { selectedUsers } = this.state;
    if (selectedUsers.find((u) => u.id === user.id)) {
      this.setState({ selectedUsers: selectedUsers.filter((u) => u.id !== user.id) });
    } else {
      this.setState({ selectedUsers: [...selectedUsers, user] });
    }
  };

  isSelected = (user: UserModel) => {
    return Boolean(this.state.selectedUsers.find((u) => u.id === user.id));
  };

  getExportUrl = () => {
    return this.props.usersStore.getExportUrl(this.state.selectedUsers);
  };

  addAdditionalBottles = (user: UserModel, count: number) => {
    this.props.usersStore.addAdditionalBottles(user.id, count).then(() => {
      this.loadList();
    });
  };

  render() {
    const { selectedUsers } = this.state;
    const { usersStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage, classes } = this.props;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3}>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box display="flex" alignItems="center" className={classes.searchContainer}>
              <SearchBar
                name="filter_nameOrEmail"
                onSearch={onSearch}
                initialValue={params.filter_nameOrEmail}
                fullWidth
                label={<Trans i18nKey="user_list_search_label" />}
              />
              {/*<Box ml={2}>*/}
              {/*  <UserComplexFilter params={params} onFilter={onFilter} />*/}
              {/*</Box>*/}
            </Box>
            <Box display="flex" mt={1} mb={2}>
              <Box ml="auto">
                <Tooltip title={<Trans i18nKey="event_export" />}>
                  <Link href={this.getExportUrl()}>
                    <Button variant="outlined" icon={DownloadIcon}>
                      <Trans i18nKey="export" />
                    </Button>
                  </Link>
                </Tooltip>
              </Box>
            </Box>
            {/*<Box display="flex" alignItems="center" className={classes.actionsContainer}>*/}
            {/*  <UserListActions selectedUsers={selectedUsers} refreshList={this.loadList} />*/}
            {/*  <Tooltip title={<Trans i18nKey="info_user_export_as_csv" />}>*/}
            {/*    <Link href={this.getExportUrl()}>*/}
            {/*      <Button variant="outlined" icon={DownloadIcon}>*/}
            {/*        <Trans i18nKey="export" />*/}
            {/*      </Button>*/}
            {/*    </Link>*/}
            {/*  </Tooltip>*/}
            {/*</Box>*/}
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={usersStore.loading}
          emptyMessage={<Trans i18nKey="user_list_empty_state_message" />}
          count={usersStore.count}
          selectedCount={selectedUsers.length}
          formatSelectedCount={(selectedCount) => (
            <Trans i18nKey="user_list_selected_count" values={{ selectedCount }} />
          )}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 50 }} padding="checkbox">
                  <Checkbox
                    indeterminate={Boolean(selectedUsers.length && selectedUsers.length < usersStore.userList.length)}
                    checked={Boolean(selectedUsers.length && selectedUsers.length === usersStore.userList.length)}
                    onChange={this.selectAll}
                  />
                </TableCell>
                <SortableTableCell style={{ width: 80, textAlign: "center" }} field="id" {...headerParams}>
                  <Trans i18nKey="id" />
                </SortableTableCell>
                <TableCell style={{ width: 50, textAlign: "center" }}>
                </TableCell>
                <SortableTableCell field="name" {...headerParams}>
                  <Trans i18nKey="name" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150, textAlign: "center" }} field="createdAt" {...headerParams}>
                  <Trans i18nKey="registration_date" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150, textAlign: "center" }} field="lastLoginAt" {...headerParams}>
                  <Trans i18nKey="last_login" />
                </SortableTableCell>
                {/*<TableCell style={{ width: 150 }} {...headerParams}>
                  <Trans i18nKey="bottles_saved" />
                </TableCell>
                <TableCell style={{ width: 150 }} {...headerParams}>
                  <Trans i18nKey="bottles_collected" />
                </TableCell>*/}
                {/*<SortableTableCell field="country" {...headerParams}>*/}
                {/*  <Trans i18nKey="country" />*/}
                {/*</SortableTableCell>*/}
                <SortableTableCell style={{ width: 60 }} padding="checkbox" />
                {/*
                <SortableTableCell style={{ width: 40 }} padding="checkbox" />*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {usersStore.userList?.map((user) => (
                <UserTableRow
                  key={user.id}
                  user={user}
                  isSelected={this.isSelected(user)}
                  selectRow={this.selectRow}
                  refreshList={this.loadList}
                  addAdditionalBottles={this.addAdditionalBottles}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ users }: StoreModel) => {
  return { usersStore: users };
};

export default compose(withTableQueryParams(), withStyles(styles), inject(mapStoreToProps), observer)(UserList);
