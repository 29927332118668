import React, { Component, Fragment } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import {
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  Button,
  SelectSearch,
} from 'components';
import { HealthStore } from 'store';

import HealthTableRow from './health-table-row.component';
import { DownloadIcon } from '../../assets/icons';
import { MenuItem, Tooltip } from '@material-ui/core';
import { compose } from '../../utils/function';

interface HealthListProps extends WithTableQueryParamsProps, WithTranslation {
  healthStore: HealthStore;
}

interface HealthListState {
  createDialogOpen: boolean;
}

class HealthList extends Component<HealthListProps, HealthListState> {
  readonly state: HealthListState = {
    createDialogOpen: false,
  };

  async componentDidMount() {
    await this.loadList();
  }

  async componentDidUpdate(prevProps: Readonly<HealthListProps>) {
    if (prevProps.params !== this.props.params) {
      await this.loadList();
    }
  }

  loadList = async () => {
    return await this.props.healthStore.getHealths(this.props.params);
  };

  getExportUrl = () => {
    return this.props.healthStore.getExportUrl(this.props.params);
  };

  render() {
    const { healthStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage, t } = this.props;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3} display="flex" width="100%">
          <Box flex={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <SearchBar
                  name="filter_nameOrEmail"
                  onSearch={onSearch}
                  initialValue={params.filter_name}
                  fullWidth
                  label={<Trans i18nKey="health_list_search_label" />}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <SelectSearch
                  fullWidth
                  name="filter_type"
                  value={params.filter_type}
                  onSearch={onSearch}
                  label={<Trans i18nKey="health_store_type" />}
                  showAllOption={true}
                >
                  {Object.entries(['0', '1']).map(([type, label]) => (
                    <MenuItem value={type} key={type}>
                      {t(`health_store_type_${label}`)}
                    </MenuItem>
                  ))}
                </SelectSearch>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" mt={1} mb={2}>
            <Box ml="auto">
              <Tooltip title={<Trans i18nKey="health_export" />}>
                <Link href={this.getExportUrl()}>
                  <Button variant="outlined" icon={DownloadIcon}>
                    <Trans i18nKey="export" />
                  </Button>
                </Link>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={healthStore.healthLoading}
          emptyMessage={<Trans i18nKey="health_list_empty_state_message" />}
          count={healthStore.healthCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <SortableTableCell style={{ width: 50 }} field="id" {...headerParams}>
                  <Trans i18nKey="health_id" />
                </SortableTableCell>
                <SortableTableCell field="userEmail" {...headerParams}>
                  <Trans i18nKey="health_user_name_email" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="health_store" {...headerParams}>
                  <Trans i18nKey="health_store_type" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} field="calorie" {...headerParams}>
                  <Trans i18nKey="health_calorie" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="step_count" {...headerParams}>
                  Step count<Trans i18nKey="health_steps" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="timestamp" {...headerParams}>
                  <Trans i18nKey="health_timestamp" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 50 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {healthStore.healths?.map((health) => (
                <HealthTableRow key={health.id} health={health} loadList={this.loadList} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ health }: StoreModel) => {
  return { healthStore: health };
};

export default compose(
  withTableQueryParams({ sortBy: 'name' }),
  withTranslation(),
  inject(mapStoreToProps),
  observer
)(HealthList);
