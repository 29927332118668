import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as CupSvg } from './cup.svg';

export function CupIcon() {
  return (
    <SvgIcon color={'secondary'}>
      <CupSvg />
    </SvgIcon>
  );
}
