import { extract } from 'i18n';

export enum RegistrationRequestStatus {
  IN_PROGRESS = 2,
  OPERATOR_REJECTED = 3,
  OPERATOR_IDENTIFIED = 4,
  ACTIVATED = 5,
  CANCELLED = 6,
}

export enum RegistrationRequestAction {
  APPROVE = 'approve',
  REJECT = 'reject',
}

export interface RegistrationRequestModel {
  id: number;
  name: string;
  email: string;
  virtaId: string;
  createdAt: string;
  status: RegistrationRequestStatus;
}

export enum RegistrationRequestImageType {
  ID_FRONT = 0,
  ID_BACK = 1,
  SELFIE = 2,
}

export interface RegistrationRequestImage {
  id: number;
  name: string;
  image: string;
  type: RegistrationRequestImageType;
}

export interface RegistrationRequestHistoryModel {
  id: number;
  status: RegistrationRequestStatus;
  reasonForRejection: string | null;
  operatorName: string;
  operatorEmail: string;
  changedAt: string;
}

export interface RegistrationRequestImage {
  id: number;
  name: string;
  image: string;
  type: RegistrationRequestImageType;
}

export interface RegistrationRequestDetailModel {
  id: number;
  name: string;
  status: RegistrationRequestStatus;
  mothersName: string;
  birthDate: string;
  birthPlace: string;
  createdAt: string;
  images: RegistrationRequestImage[];
  histories: RegistrationRequestHistoryModel[];
}

export interface UpdateRegistrationRequestDataDto {
  name: string;
  mothersName: string;
  birthPlace: string;
  birthDate: string;
}

export interface UpdateRegistrationRequestDto {
  status: RegistrationRequestStatus;
  reasonForRejection?: string;
}

export const RegistrationRequestLabelMap: Record<RegistrationRequestStatus, string> = {
  [RegistrationRequestStatus.IN_PROGRESS]: extract('registration_request_in_progress'),
  [RegistrationRequestStatus.OPERATOR_REJECTED]: extract('registration_request_rejected'),
  [RegistrationRequestStatus.OPERATOR_IDENTIFIED]: extract('registration_request_identification'),
  [RegistrationRequestStatus.ACTIVATED]: extract('registration_request_activated'),
  [RegistrationRequestStatus.CANCELLED]: extract('registration_request_cancelled'),
};

export const RegistrationRequestActionStatusMap = {
  [RegistrationRequestAction.APPROVE]: RegistrationRequestStatus.OPERATOR_IDENTIFIED,
  [RegistrationRequestAction.REJECT]: RegistrationRequestStatus.OPERATOR_REJECTED,
};
