import { action, observable } from 'mobx';

import { TeamService } from 'services';
import { AddTeamModel, QueryParamModel, TeamModel } from 'models';

export class TeamStore {
  private readonly teamService = new TeamService();

  @observable teams: TeamModel[] = [];
  @observable teamCount = 0;
  @observable firmwareCount = 0;

  @observable teamLoading = false;
  @observable teamSaving = false;
  @observable teamDeleting = false;

  @action.bound
  async getTeam(params?: QueryParamModel) {
    this.teamLoading = true;

    try {
      const resTeams = await this.teamService.getList(params);
      this.teamCount = resTeams.count;
      this.teams = resTeams.data;
      return resTeams;
    } finally {
      this.teamLoading = false;
    }
  }

  @action.bound
  async updateTeam(id: number, data: AddTeamModel) {
    this.teamSaving = true;

    try {
      return await this.teamService.update(id, data);
    } finally {
      this.teamSaving = false;
    }
  }

  @action.bound
  async addTeam(data: AddTeamModel) {
    this.teamSaving = true;

    try {
      return await this.teamService.create(data);
    } finally {
      this.teamSaving = false;
    }
  }

  @action.bound
  async deleteTeam(id: number) {
    this.teamDeleting = true;

    try {
      await this.teamService.delete(id);
    } finally {
      this.teamDeleting = false;
    }
  }
}
