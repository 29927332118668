import { BaseUserModel } from './user.model';
import { BaseSubTeamModel } from './sub-team.model';

export enum TeamUserStatus {
  NOT_ACCEPTED,
  ACCEPTED,
}

export interface BaseTeamUserModel {
  email: string;
}

export interface TeamUserModel extends BaseTeamUserModel {
  id: number;
  createdAt: number;
  user?: BaseUserModel;
  subTeam?: BaseSubTeamModel;
  status: TeamUserStatus;
}

export interface TeamUserShowModel extends BaseTeamUserModel {
  id: number;
  createdAt: number;
  user?: string;
  subTeam?: string;
  status: TeamUserStatus;
  code?: string;
}

export interface AddTeamUserModel extends BaseTeamUserModel {}
