import { action, observable } from 'mobx';

import { BottleModelService, FirmwareService } from 'services';
import { AddBottleModel, QueryParamModel, BottleModelModel, FirmwareModel } from 'models';

export class BottleModelStore {
  private readonly bottleModelService = new BottleModelService();
  private readonly firmwareService = new FirmwareService();

  @observable bottleModels: BottleModelModel[] = [];
  @observable firmwares: FirmwareModel[] = [];
  @observable bottleModelCount = 0;
  @observable firmwareCount = 0;

  @observable bottleModelLoading = false;
  @observable bottleModelSaving = false;
  @observable bottleModelDeleting = false;

  @action.bound
  async getBottleModel(params?: QueryParamModel) {
    this.bottleModelLoading = true;

    try {
      const resBottleModels = await this.bottleModelService.getList(params);
      const resFirmwares = await this.firmwareService.getList();

      this.bottleModelCount = resBottleModels.count;
      this.bottleModels = resBottleModels.data;
      this.firmwares = resFirmwares.data;
      this.firmwareCount = resFirmwares.count;

      return resBottleModels;
    } finally {
      this.bottleModelLoading = false;
    }
  }

  @action.bound
  async updateBottleModel(id: number, data: AddBottleModel) {
    this.bottleModelSaving = true;

    try {
      return await this.bottleModelService.update(id, data);
    } finally {
      this.bottleModelSaving = false;
    }
  }

  @action.bound
  async addBottleModel(data: AddBottleModel) {
    this.bottleModelSaving = true;

    try {
      return await this.bottleModelService.create(data);
    } finally {
      this.bottleModelSaving = false;
    }
  }

  @action.bound
  async deleteBottleModel(id: number) {
    this.bottleModelDeleting = true;

    try {
      await this.bottleModelService.delete(id);
    } finally {
      this.bottleModelDeleting = false;
    }
  }
}
