import request, { Methods } from 'utils/request';
import { CreateNewsDto, ListResponseModel, QueryParamModel, NewsModel } from 'models';

export class NewsService {
  private readonly resource = 'news';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<NewsModel>>({ resource: this.resource, params });
  }

  create(data: CreateNewsDto) {
    return request<NewsModel>({ method: Methods.POST, resource: this.resource, data });
  }

  update(id: number, data: CreateNewsDto) {
    return request<NewsModel>({ method: Methods.PUT, resource: `${this.resource}/${id}`, data });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `${this.resource}/${id}` });
  }

  async uploadImage(id: number, file: File) {
    const formData = new FormData();

    formData.append('image', file);

    return request<File | void>({
      method: Methods.POST,
      resource: `${this.resource}/${id}/image/upload`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      transformResponse: (data, headers) => {
        return data;
      },
    });
  }
}
