import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import Box from '@material-ui/core/Box';

import { Autocomplete, Button, Dialog, TextField } from 'components';
import { NotificationType, AddBottleModel, BottleModelModel, FirmwareModel } from 'models';
import { WithNotification } from 'store';
import { Yup } from 'utils/yup';
import { getErrors } from 'utils/request';

interface BottleModelDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  bottleModel?: BottleModelModel;
  addBottleModel: (data: AddBottleModel) => Promise<any>;
  updateBottleModel: (id: number, data: AddBottleModel) => Promise<any>;
  bottleModelSaving: boolean;
  firmwareList: FirmwareModel[];
  bottleModelLoading: boolean;
}

const validationSchema = Yup.object().shape({
  version: Yup.string()
    .max(20)
    .required(),
  description: Yup.string()
    .min(0)
    .max(255),
});

const BottleModelDialog: FC<BottleModelDialogProps> = ({
  open,
  onClose,
  bottleModel,
  showNotification,
  onSubmit,
  bottleModelSaving,
  addBottleModel,
  updateBottleModel,
  firmwareList,
  bottleModelLoading,
}) => {
  const getInitialValues = (bottleModel?: AddBottleModel) => {
    let initialValues;
    if (bottleModel) {
      initialValues = { ...bottleModel };
      initialValues.firmware = firmwareList.find((v) => bottleModel.firmware_id === v.id);
    } else {
      initialValues = { version: '', description: '' } as AddBottleModel;
    }
    return initialValues;
  };

  const handleSubmit = async (values: AddBottleModel, { setFieldError }: FormikHelpers<AddBottleModel>) => {
    try {
      if (bottleModel) {
        await updateBottleModel(bottleModel.id, values);
      } else {
        await addBottleModel(values);
      }
      showNotification(<Trans i18nKey="save_successful" />, NotificationType.SUCCESS);
      onClose();
      onSubmit();
    } catch (e) {
      getErrors(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={bottleModel ? <Trans i18nKey="bottle_model_edit" /> : <Trans i18nKey="bottle_model_new" />}
      showClose
    >
      <Formik
        initialValues={getInitialValues(bottleModel)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        <Form>
          <Field
            component={TextField}
            name="version"
            maxLength={20}
            fullWidth
            label={<Trans i18nKey="bottle_model_version" />}
            margin="normal"
          />
          <Field
            component={TextField}
            name="description"
            multiline
            rows={6}
            rowsMax={6}
            maxLength={255}
            fullWidth
            label={<Trans i18nKey="bottle_model_description" />}
            margin="normal"
          />
          <Field
            component={Autocomplete}
            name="firmware"
            menuPortalTarget={document.body}
            value={null}
            isLoading={bottleModelLoading}
            valueKey="id"
            displayKey="version"
            label="Firmware"
            fixedKey="isFixed"
            options={firmwareList}
          />
          <Box textAlign="right">
            <Button type="submit" loading={bottleModelSaving}>
              <Trans i18nKey="save" />
            </Button>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default inject(({ bottleModel, common }) => {
  return {
    showNotification: common.showNotification,
    addBottleModel: bottleModel.addBottleModel,
    updateBottleModel: bottleModel.updateBottleModel,
    bottleModelSaving: bottleModel.bottleModelSaving,
    firmwareList: bottleModel.firmwares,
    bottleModelLoading: bottleModel.bottleModelLoading,
  };
})(observer(BottleModelDialog));
