import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Variant } from '@material-ui/core/styles/createTypography';
import { makeStyles } from '@material-ui/core';

import { ArrowRightIcon } from 'assets/icons';

export interface BreadcrumbStep {
  label: React.ReactNode;
  path?: string;
  key: string;
}

interface BreadcrumbProps {
  steps: BreadcrumbStep[];
  variant: Variant | 'inherit';
}

const useStyles = makeStyles(() => {
  return {
    overflowHidden: {
      overflowX: 'hidden',
    },
  };
});

const Breadcrumb: FC<BreadcrumbProps> = ({ steps, variant }) => {
  const classes = useStyles();

  return steps && steps.length ? (
    <Box display="flex" alignItems="center">
      {steps.map((step, index) => {
        const label = (
          <Typography variant={variant} noWrap>
            {step.label}
          </Typography>
        );
        const isLastStep = index === steps.length - 1;

        return (
          <Box
            display="flex"
            alignItems="center"
            key={step.key}
            className={classNames({ [classes.overflowHidden]: isLastStep })}
          >
            {step.path && !isLastStep ? (
              <MuiLink color="inherit" component={Link} to={step.path}>
                {label}
              </MuiLink>
            ) : (
              label
            )}
            {!isLastStep ? (
              <Box pl={1} pr={1} display="flex" alignItems="center">
                <ArrowRightIcon fontSize="small" />
              </Box>
            ) : null}
          </Box>
        );
      })}
    </Box>
  ) : null;
};

export default Breadcrumb;
