import { action, observable } from 'mobx';

import { UserService, RoleService } from 'services';
import { QueryParamModel, RoleModel, UserDetailModel, UserModel, ProfileModel } from 'models';
import { AuthStore } from './auth.store';

export class UsersStore {
  private readonly userService = new UserService();
  private readonly roleService = new RoleService();
  private readonly authStore: AuthStore;

  @observable roles: RoleModel[] = [];

  @observable userList: UserModel[] = [];
  @observable count = 0;

  @observable user: UserDetailModel | null = null;
  @observable userProfile: ProfileModel | null = null;

  @observable loading = false;
  @observable userLoading = false;
  @observable rolesLoading = false;
  @observable userRolesSaving = false;
  @observable passwordResetInProgress = false;
  @observable couponAssignInProgress = false;
  @observable userDeleting = false;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action.bound
  async getUsers(params?: QueryParamModel) {
    this.loading = true;

    try {
      const { data, count } = await this.userService.getUsers(params);
      this.count = count;
      this.userList = data;

      return data;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async getUser(id: number) {
    this.userLoading = true;

    try {
      const user = await this.userService.getUser(id);
      this.user = user;
      return user;
    } finally {
      this.userLoading = false;
    }
  }

  @action.bound
  resetUser() {
    this.user = null;
  }

  @action.bound
  async updateUserRoles(id: number, roles: RoleModel[]) {
    this.userRolesSaving = true;

    try {
      const user = await this.userService.updateUserRoles(
        id,
        roles.map(({ id }) => id)
      );

      this.user = user;
      return user;
    } finally {
      this.userRolesSaving = false;
    }
  }

  @action.bound
  async resetPassword(id: number, email: string) {
    this.passwordResetInProgress = true;

    try {
      await this.userService.resetPassword(id, email);
    } finally {
      this.passwordResetInProgress = false;
    }
  }

  @action.bound
  async addAdditionalBottles(id: number, count: number) {
    return await this.userService.addAdditionalBottles(id, count);
  }

  @action.bound
  async getRoles() {
    this.rolesLoading = true;

    try {
      const roles = await this.roleService.getRoles();

      this.roles = roles;
      return roles;
    } finally {
      this.rolesLoading = false;
    }
  }

  @action.bound
  async deleteUser(id: number) {
    this.userDeleting = true;

    try {
      await this.userService.delete(id);
    } finally {
      this.userDeleting = false;
    }
  }

  @action.bound
  getExportUrl(users?: UserModel[]) {
    return this.userService.getExportUrl(this.authStore.token, { ids: users?.map((u) => u.id).join(',') });
  }
}
