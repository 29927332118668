import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Page } from 'components';

import LogsList from './logs-list.component';

const Logs: FC = () => {
  return (
    <Page title={<Trans i18nKey="logs" />}>
      <LogsList />
    </Page>
  );
};

export default Logs;
