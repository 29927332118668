import React, { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { LoadingOverlay } from 'components';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 64,
    minWidth: 64,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
}));

interface ImageProps {
  src: string;
  alt: string;
  height?: number;
  showLoader?: boolean;
}

export const Image: FC<ImageProps> = ({ src, alt, height, showLoader }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setLoaded(true);
    setError(true);
  };

  useEffect(() => {
    setLoaded(false);
    setError(false);
  }, [src]);

  return (
    <div className={classes.root}>
      <LoadingOverlay show={!loaded && showLoader} style={{ maxHeight: height }} />

      <img
        className={classes.image}
        style={{ maxHeight: height }}
        src={src}
        alt={alt}
        onLoad={onLoad}
        onError={onError}
      />
      {error ? (
        <Box display="flex" alignItems="center" justifyContent="center" my={2}>
          <Typography color="error" variant="h6">
            <Trans i18nKey="errors_unsuccessful_image_loading" />
          </Typography>
        </Box>
      ) : null}
    </div>
  );
};
