import React, { FC, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { TablePagination, useWindowResize, LoadingOverlay } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  responsive: {
    overflowX: 'auto',
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 40,
    bottom: 40,
  },
  emptyStateContainer: {
    minHeight: 100,
  },
  paginationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: -1,
    width: '100%',
  },
  paginationHasSelected: {
    justifyContent: 'space-between',
  },
  selectedCount: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingBottom: 0,
      width: '100%',
    },
  },
}));

interface TableContainerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  responsive?: boolean;
  loading?: boolean;
  emptyMessage?: React.ReactNode;
  count?: number;
  selectedCount?: number;
  formatSelectedCount?: (selectedCount: number) => React.ReactNode;
  pagination?: {
    pageSize: number;
    page: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    onChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  };
}

const TableContainer: FC<TableContainerProps> = ({
  children,
  className,
  responsive,
  loading,
  count,
  selectedCount,
  emptyMessage,
  formatSelectedCount,
  pagination,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);

  const stickyHeader = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useWindowResize(() => {
    if (containerRef.current && paginationRef.current) {
      const { top: containerTop } = containerRef.current.getBoundingClientRect();
      const { height: paginationHeight } = paginationRef.current.getBoundingClientRect();
      const bottomSpacing = theme.spacing(theme.defaultSpacing);

      const height = window.innerHeight - containerTop - paginationHeight - bottomSpacing;

      setContainerHeight(height);
    }
  });

  return (
    <Paper square>
      <div className={classes.root}>
        <div
          className={classNames(className, { [classes.responsive]: responsive })}
          ref={containerRef}
          style={stickyHeader ? { height: containerHeight } : undefined}
          {...props}
        >
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child) && child.type === Table) {
              return React.cloneElement(child, { stickyHeader });
            }

            return child;
          })}

          <LoadingOverlay show={loading} />

          {!loading && !count && emptyMessage ? (
            <div className={classes.emptyStateContainer}>
              <div className={classes.emptyState}>
                <Typography variant="h5">{emptyMessage}</Typography>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {pagination ? (
        <div
          className={classNames(classes.paginationContainer, {
            [classes.paginationHasSelected]: Boolean(selectedCount),
          })}
          ref={paginationRef}
        >
          {selectedCount ? (
            <div className={classes.selectedCount}>
              <Typography variant="body2">
                {formatSelectedCount ? (
                  formatSelectedCount(selectedCount)
                ) : (
                  <Trans i18nKey="table_selected_count" values={{ selectedCount }} />
                )}
              </Typography>
            </div>
          ) : null}
          <TablePagination
            count={count || 0}
            rowsPerPage={pagination.pageSize}
            page={pagination.page}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        </div>
      ) : null}
    </Paper>
  );
};

export default TableContainer;
