import React, { Component, ChangeEventHandler } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';

import { debounce } from 'utils/function';
import { SearchConditions, ClearFieldAdornment } from 'components';
import config from 'config';

const styles = (theme: Theme) =>
  createStyles({
    searchBar: {
      background: theme.palette.background.paper,
      maxWidth: 600,
    },
  });

interface SearchBarProps extends WithStyles<typeof styles> {
  name: string;
  onSearch: (searchConditions: SearchConditions) => void;
  initialValue?: string | null;
  delay?: number;
}

interface SearchBarState {
  value: string;
}

class SearchBar extends Component<SearchBarProps & TextFieldProps, SearchBarState> {
  readonly state: SearchBarState = {
    value: this.props.initialValue || '',
  };

  handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    this.setState({ value: e.target.value }, () => {
      this.onSearch();
    });
  };

  handleSearch() {
    this.props.onSearch({ name: this.props.name, value: this.state.value });
  }

  onSearch = debounce(() => {
    this.handleSearch();
  }, this.props.delay || 500);

  onClear = () => {
    this.setState({ value: '' }, () => {
      this.onSearch();
    });
  };

  onIconClick = () => {
    if (this.state.value) {
      this.onClear();
    }
  };

  render() {
    const {
      classes,
      onChange,
      onSearch,
      initialValue,
      delay,
      variant = config.DEFAULT_INPUT_VARIANT as any,
      ...props
    } = this.props;
    const { value } = this.state;

    return (
      <TextField
        className={classes.searchBar}
        onChange={this.handleChange}
        value={value}
        variant={variant}
        InputProps={{
          endAdornment: <ClearFieldAdornment onClick={this.onIconClick} value={value} showSearchIcon />,
        }}
        {...props}
      />
    );
  }
}

export default withStyles(styles)(SearchBar);
