import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Field, Form, Formik } from 'formik';

import Box from '@material-ui/core/Box';

import { Button, Dialog, TextField } from 'components';
import { NotificationType, AddTeamUserModel, TeamUserShowModel } from 'models';
import { WithNotification } from 'store';
import { Yup } from 'utils/yup';
import { getErrors } from 'utils/request';

interface TeamDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  teamUser?: TeamUserShowModel;
  addTeam: (data: AddTeamUserModel) => Promise<any>;
  updateTeam: (id: number, data: AddTeamUserModel) => Promise<any>;
  teamSaving: boolean;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .max(255)
    .required(),
});

const TeamDialog: FC<TeamDialogProps> = ({
  open,
  onClose,
  teamUser,
  showNotification,
  onSubmit,
  teamSaving,
  addTeam,
}) => {
  const getInitialValues = (team?: TeamUserShowModel) => {
    let initialValues;
    if (team) {
      initialValues = { ...team, adminPassword: '' };
    } else {
      initialValues = {
        email: '',
      } as AddTeamUserModel;
    }
    return initialValues;
  };

  const handleSubmit = async (values: AddTeamUserModel) => {
    try {
      const data = { ...values };

      addTeam(data).then(() => {
        showNotification(<Trans i18nKey="save_successful" />, NotificationType.SUCCESS);
        onClose();
        onSubmit();
      });
    } catch (e) {
      getErrors(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} title={<Trans i18nKey="team_user_new" />} showClose>
      <Formik
        initialValues={getInitialValues(teamUser)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        <Form>
          <Field
            component={TextField}
            name="email"
            maxLength={255}
            fullWidth
            label={<Trans i18nKey="team_user_email" />}
            margin="normal"
          />
          <Box textAlign="right">
            <Button type="submit" loading={teamSaving}>
              <Trans i18nKey="save" />
            </Button>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default inject(({ teamUser, common }) => {
  return {
    showNotification: common.showNotification,
    addTeam: teamUser.addTeam,
    updateTeam: teamUser.updateTeam,
    teamSaving: teamUser.teamSaving,
  };
})(observer(TeamDialog));
