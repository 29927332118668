import request, { generateUrl, Methods } from 'utils/request';
import { AddSubTeamModel, ListResponseModel, QueryParamModel, SubTeamModel } from 'models';

export class SubTeamService {
  private readonly resource = 'sub-teams';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<SubTeamModel>>({ resource: this.resource, params });
  }

  create(data: AddSubTeamModel) {
    return request<SubTeamModel>({ method: Methods.POST, resource: this.resource, data });
  }

  update(id: number, data: AddSubTeamModel) {
    return request<SubTeamModel>({ method: Methods.PUT, resource: `${this.resource}/${id}`, data });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `${this.resource}/${id}` });
  }

  getExportUrl(token: string | null, params?: QueryParamModel) {
    return generateUrl({ resource: `${this.resource}/export`, params: { ...params, token } });
  }
}
