import React, { FC } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';

import { Button } from 'components';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons';

interface StepperProps {
  maxSteps: number;
  activeStep: number;
  variant?: 'text' | 'dots' | 'progress';
  handleNext: () => void;
  handleBack: () => void;
}

export const Stepper: FC<StepperProps> = ({ maxSteps, variant, activeStep, handleNext, handleBack }) => {
  return (
    <MobileStepper
      steps={maxSteps}
      position="static"
      variant={variant || 'text'}
      activeStep={activeStep}
      nextButton={
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          <ArrowRightIcon />
        </Button>
      }
      backButton={
        <Button size="small" color="secondary" variant="outlined" onClick={handleBack} disabled={activeStep === 0}>
          <ArrowLeftIcon />
        </Button>
      }
    />
  );
};
