import request from 'utils/request';
import { QueryParamModel, GoogleDiskLogModel } from 'models';
import { GoogleApiAuthUrlResponse } from '../models/google-api-auth-url-response';

export class GoogleDiskService {
  private readonly resource = 'google-api';

  getLog(params?: QueryParamModel) {
    return request<GoogleDiskLogModel[]>({ resource: `${this.resource}/get-log`, params });
  }

  getAuthUrl() {
    return request<GoogleApiAuthUrlResponse>({ resource: `${this.resource}/authenticate` });
  }
}
