import React, { FC, ReactElement } from 'react';
import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@material-ui/core/TableRow';

import TableCell, { TableLinkProps } from './table-cell.component';

export interface TableRowProps extends MuiTableRowProps, TableLinkProps {}

const TableRow: FC<TableRowProps> = ({ children, link, to, replace, ...props }) => {
  return (
    <MuiTableRow {...props}>
      {children
        ? React.Children.map(children, (child) => {
            if (React.isValidElement(child) && child.type === TableCell) {
              return React.cloneElement(child as ReactElement, {
                link: child.props.link === false ? child.props.link : link,
                to,
                replace,
              });
            }

            return child;
          })
        : null}
    </MuiTableRow>
  );
};

export default TableRow;
