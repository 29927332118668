import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Button, Dialog, FileUpload, TextField } from 'components';
import { CreateNewsModel, CreateNewsDto, NotificationType, NewsModel } from 'models';
import { WithNotification } from 'store';
import { Yup } from 'utils/yup';
import { getErrors } from 'utils/request';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface NewsDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  news?: NewsModel;
  createNews: (data: CreateNewsDto) => Promise<any>;
  updateNews: (id: number, data: CreateNewsDto) => Promise<any>;
  newsSaving: boolean;
  uploadImage: (id: number, file: File) => Promise<File | any>;
  uploadInProgress: boolean;
}

const validationSchema = Yup.object().shape({
  locationName: Yup.string().required(),
  description: Yup.string()
    .min(10)
    .max(150)
    .required(),
  isActive: Yup.boolean().required(),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
    img: {
      width: '100%',
    },
    fileUpload: {
      height: '50px',
    },
  })
);

const NewsDialog: FC<NewsDialogProps> = ({
  open,
  onClose,
  news,
  showNotification,
  onSubmit,
  newsSaving,
  createNews,
  updateNews,
  uploadImage,
  uploadInProgress,
}) => {
  const getImageUrl = (img: string) => {
    // return '/api/news/image/download/' + img;
    return img;
  };
  const [imgFileObject, setImageFileObject] = useState<any | null>(null);
  const [imgFile, setImageFile] = useState<string | null>(null);
  const classes = useStyles();

  const getInitialValues = (news?: CreateNewsModel) => {
    let initialValues;
    if (news) {
      initialValues = { ...news, isActive: Boolean(news.isActive), imageUrl: getImageUrl(news.imageUrl) };
      if (!imgFile) {
        setImageFile(getImageUrl(news.imageUrl));
      }
    } else {
      initialValues = { locationName: '', imageUrl: '', description: '', isActive: false } as CreateNewsModel;
    }

    return initialValues;
  };

  const handleSubmit = async (values: CreateNewsModel, { setFieldError }: FormikHelpers<CreateNewsModel>) => {
    try {
      const data = { ...values, isActive: Number(values.isActive) };

      let response: any;
      if (news) {
        response = await updateNews(news.id, data);
      } else {
        response = await createNews(data);
      }

      if (response && imgFileObject) {
        await uploadImage(response.id, imgFileObject);
      }

      showNotification(<Trans i18nKey="save_successful" />, NotificationType.SUCCESS);
      onClose();
      onSubmit();
    } catch (e) {
      getErrors(e);
    }
  };

  const onAccepted = async (files: File[]) => {
    setImageFileObject(files[0]);
    setImageFile(URL.createObjectURL(files[0]));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={news ? <Trans i18nKey="edit_news" /> : <Trans i18nKey="new_news" />}
      showClose
    >
      <Formik
        initialValues={getInitialValues(news)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        <Form>
          <Field
            component={TextField}
            name="locationName"
            fullWidth
            label={<Trans i18nKey="news_name" />}
            margin="normal"
          />
          <Field
            component={TextField}
            name="description"
            multiline
            rows={6}
            rowsMax={6}
            maxLength={150}
            fullWidth
            label={<Trans i18nKey="news_description" />}
            margin="normal"
          />
          <FileUpload
            accept="image/jpeg, image/jpg, image/png"
            onDropAccepted={onAccepted}
            loading={uploadInProgress}
          />
          <div>{imgFile ? <img className={classes.img} src={imgFile} alt="img" /> : null}</div>

          <Field name="isActive">
            {({ field }: FieldProps) => (
              <FormControlLabel
                control={<Switch {...field} checked={Boolean(field.value)} />}
                label={<Trans i18nKey="news_is_active" />}
              />
            )}
          </Field>
          <Box textAlign="right">
            <Button type="submit" loading={newsSaving}>
              <Trans i18nKey="save" />
            </Button>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default inject(({ news, common }) => {
  return {
    showNotification: common.showNotification,
    createNews: news.createNews,
    updateNews: news.updateNews,
    newsSaving: news.newsSaving,
    uploadImage: news.uploadImage,
    uploadInProgress: news.uploadInProgress,
  };
})(observer(NewsDialog));
