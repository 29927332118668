import React, { FC } from 'react';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    zIndex: 1,
    transition: theme.transitions.create('box-shadow'),
  },
  scrolled: {
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14)',
  },
}));

interface StickyHeaderProps {
  className?: string;
  top?: number;
}

const StickyHeader: FC<StickyHeaderProps> = ({ className, children, top }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isScrolled = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

  return (
    <div
      className={classNames(className, classes.root, { [classes.scrolled]: isScrolled })}
      style={{ top: top || theme.mixins.toolbar.minHeight }}
    >
      {children}
    </div>
  );
};

export default StickyHeader;
