import React, { FC } from 'react';
import { SortDirection } from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';

import TableCell, { TableCellProps } from './table-cell.component';

interface SortableTableHeadProps extends TableCellProps {
  field?: string;
  sortBy?: string;
  order?: SortDirection;
  onSort?: (event: React.MouseEvent<unknown>, sortBy: string, order: SortDirection) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    color: `${theme.palette.action.active} !important`,
  },
}));

const SortableTableCell: FC<SortableTableHeadProps> = ({
  field,
  sortBy,
  order,
  onSort,
  children,
  noWrap,
  ...props
}) => {
  const classes = useStyles();
  const active = sortBy === field;

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (onSort) {
      const nextOrder = !active ? 'asc' : order === 'asc' ? 'desc' : 'asc';

      onSort(event, property, nextOrder);
    }
  };

  return (
    <TableCell sortDirection={sortBy === field ? order : false} noWrap={noWrap !== false} {...props}>
      {field ? (
        <TableSortLabel
          active={active}
          classes={{
            active: classes.active,
          }}
          direction={active ? order || undefined : 'asc'}
          onClick={createSortHandler(field)}
        >
          {children}
        </TableSortLabel>
      ) : (
        children
      )}
    </TableCell>
  );
};

export default SortableTableCell;
