import { action, observable } from 'mobx';

import { GoogleDiskService } from 'services';
import { QueryParamModel, GoogleDiskLogModel } from 'models';

export class GoogleDiskStore {
  private readonly googleDiskService = new GoogleDiskService();

  @observable logModels: GoogleDiskLogModel[] = [];
  @observable authUrl = '';

  @observable logLoading = false;
  @observable authUrlLoading = false;

  @action.bound
  async getAuthUrl() {
    if (this.authUrl.length || this.authUrlLoading) return this.authUrl;
    try {
      this.authUrlLoading = true;
      const resGoogleDiskLog = await this.googleDiskService.getAuthUrl();
      this.authUrl = resGoogleDiskLog.url;
      return this.authUrl;
    } finally {
      this.authUrlLoading = false;
    }
  }

  @action.bound
  async getLog(params?: QueryParamModel) {
    this.logLoading = true;

    try {
      const resGoogleDiskLog = await this.googleDiskService.getLog(params);

      this.logModels = resGoogleDiskLog;

      return resGoogleDiskLog;
    } finally {
      this.logLoading = false;
    }
  }
}
