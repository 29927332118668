export function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
  let timeoutID: number | undefined = undefined;

  return function(this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

export function throttle<F extends (...params: any[]) => void>(fn: F, wait: number) {
  let isCalled = false;

  return function(this: any, ...args: any[]) {
    if (!isCalled) {
      fn(...args);
      isCalled = true;
      setTimeout(function() {
        isCalled = false;
      }, wait);
    }
  };
}

export function isFunction(functionToCheck: any) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function compose(...funcs: Function[]) {
  if (funcs.length === 0) {
    return <T>(arg: T) => arg;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args: any) => a(b(...args)));
}
