import React from 'react';

export type NotificationContent = React.ReactNode | string;

export enum NotificationType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface NotificationModel {
  content: NotificationContent;
  type: NotificationType;
}
