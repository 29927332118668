export enum NotificationsType {
  'REMINDER' = 'REMINDER',
  'INTAKE' = 'INTAKE',
  'DRINK_MORE' = 'DRINK_MORE',
}

export interface NotificationsModel {
  id: number;
  title: string;
  message: string;
  type: string;
}

export type CreateNotificationsModel = PartialBy<NotificationsModel, 'id'>;

export interface CreateNotificationsDto extends Omit<CreateNotificationsModel, 'type'> {
  type: string;
}
