import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Page } from 'components';
import FirmwareList from './firmware-list.component';

const Firmware: FC = () => {
  return (
    <Page title={<Trans i18nKey="firmware" />}>
      <FirmwareList />
    </Page>
  );
};

export default Firmware;
