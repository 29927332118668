import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Page } from 'components';

import NotificationsList from './notifications-list.component';

const Notifications: FC = () => {
  return (
    <Page title={<Trans i18nKey="notifications" />}>
      <NotificationsList />
    </Page>
  );
};

export default Notifications;
