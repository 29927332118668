import React, { FC } from 'react';
import classNames from 'classnames';

import Paper, { PaperProps } from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(theme.defaultSpacing) - theme.shape.activeBorderWidth,
    paddingRight: theme.spacing(theme.defaultSpacing),
    borderLeft: `${theme.shape.activeBorderWidth}px solid transparent`,
    transition: theme.transitions.create('border-left-color'),
  },
  active: {
    borderLeftColor: theme.palette.primary.main,
  },
}));

interface EntityContainerProps extends PaperProps {
  isActive?: boolean;
}

const EntityContainer: FC<EntityContainerProps> = ({ elevation, children, isActive, className, square, ...rest }) => {
  const classes = useStyles();

  return (
    <Paper
      square={square !== false}
      elevation={elevation || 2}
      className={classNames(classes.root, className, { [classes.active]: isActive })}
      {...rest}
    >
      {children}
    </Paper>
  );
};

export default EntityContainer;
