import React, { FC, PropsWithChildren } from 'react';
import { observer, inject } from 'mobx-react';
import { RouteConfigurationModel, Role } from 'models';

type AllowedFor = Role[] | Role;

export interface AccessRestriction {
  allowedFor?: AllowedFor;
}

interface AccessControllerProps extends AccessRestriction {
  roles: Role[];
  noAccessChildren?: React.ReactNode;
}

const hasPermission = (roles: Role[], allowedFor?: AllowedFor) => {
  if (!allowedFor?.length || !roles.length) {
    return false;
  }

  if (Array.isArray(allowedFor)) {
    return Boolean(allowedFor.filter((role) => roles.includes(role)).length);
  }

  return roles.includes(allowedFor);
};

export const filterRoutes = (routes: RouteConfigurationModel[], roles: Role[]) => {
  return routes.filter((route) => hasPermission(roles, route.roles));
};

const AccessController: FC<PropsWithChildren<AccessControllerProps>> = ({
  allowedFor,
  roles,
  children,
  noAccessChildren,
}) => {
  if (!hasPermission(roles, allowedFor)) {
    return <>{noAccessChildren || null}</>;
  }

  return <>{children}</>;
};

export default inject(({ auth }) => ({ roles: auth.roles }))(observer(AccessController));
