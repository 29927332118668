import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Page } from 'components';
import TeamList from './sub-team-list.component';

const SubTeams: FC = () => {
  return (
    <Page title={<Trans i18nKey="sub teams" />}>
      <TeamList />
    </Page>
  );
};

export default SubTeams;
