import React, { FC } from 'react';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
  active: number;
  index: number;
  className?: string;
}

const TabPanel: FC<TabPanelProps> = ({ children, active, index, className }) => {
  return active === index ? (
    <Box py={2} px={3} className={className}>
      {children}
    </Box>
  ) : null;
};

export default TabPanel;
