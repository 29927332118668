import { UserModel } from './user.model';

export enum LogsType {
  CONSUME = 0,
  FILL = 1,
  POUR = 2,
}

export const logsType = (type: LogsType) => {
  return `logs_type_${type}`;
};

export interface LogsModel {
  date: string;
  fileName: string;
  filePath: string;
  userId: number;
  user: UserModel;
}
export type CreateLogsModel = PartialBy<LogsModel, 'user'>;

export interface CreateLogsDto extends Omit<CreateLogsModel, 'isActive'> {
  isActive: number;
}
