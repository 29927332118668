import { action, observable } from 'mobx';

import { QueryParamModel, LogCompareModel } from 'models';
import { SystemService } from '../services/system.service';

export class SystemStore {
  private readonly systemService = new SystemService();

  @observable logCompareInfo: LogCompareModel[] = [];

  @observable loading = 0;

  @action.bound
  async getLogCompareInfo(params?: QueryParamModel) {
    this.loading++;

    try {
      const response = await this.systemService.logCompareInfo(params);
      console.log(response);
      this.logCompareInfo = response;

      return response;
    } finally {
      this.loading--;
    }
  }
}
