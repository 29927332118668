import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import {
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  Button,
} from 'components';
import { BottleModelStore } from '../../store/bottle-model.store';
import BottleModelTableRow from './bottle-model.table-row.component';
import BottleModelDialog from './bottle-model-dialog.component';

interface BottleModelListProps extends WithTableQueryParamsProps {
  bottleModelStore: BottleModelStore;
}

interface BottleModelListState {
  createDialogOpen: boolean;
}

class BottleModelList extends Component<BottleModelListProps, BottleModelListState> {
  readonly state: BottleModelListState = {
    createDialogOpen: false,
  };

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps: Readonly<BottleModelListProps>) {
    if (prevProps.params !== this.props.params) {
      this.loadList();
    }
  }

  loadList = () => {
    return this.props.bottleModelStore.getBottleModel(this.props.params);
  };

  openCreateDialog = () => {
    this.setState({ createDialogOpen: true });
  };

  closeCreateDialog = () => {
    this.setState({ createDialogOpen: false });
  };

  render() {
    const { bottleModelStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage } = this.props;
    const { createDialogOpen } = this.state;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3}>
          <Box display="flex" alignItems="center">
            <Box mr={2} flex={1}>
              <SearchBar
                name="filter_version"
                onSearch={onSearch}
                initialValue={params.filter_version}
                fullWidth
                label={<Trans i18nKey="bottle_model_list_search_label" />}
              />
            </Box>
            <Box ml="auto">
              <Button variant="outlined" icon={AddIcon} onClick={this.openCreateDialog}>
                <Trans i18nKey="add" />
              </Button>
              <BottleModelDialog
                open={createDialogOpen}
                onClose={this.closeCreateDialog}
                firmwareList={this.props.bottleModelStore.firmwares}
                onSubmit={this.loadList}
              />
            </Box>
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={bottleModelStore.bottleModelLoading}
          emptyMessage={<Trans i18nKey="bottle_model_list_empty_state_message" />}
          count={bottleModelStore.bottleModelCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <SortableTableCell style={{ width: 50 }} field="id" {...headerParams}>
                  <Trans i18nKey="bottle_model_id" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="version" {...headerParams}>
                  <Trans i18nKey="bottle_model_version" />
                </SortableTableCell>
                <SortableTableCell field="description" style={{ width: 250 }} {...headerParams}>
                  <Trans i18nKey="bottle_model_description" />
                </SortableTableCell>
                <SortableTableCell field="firmware" style={{ width: 250 }} {...headerParams}>
                  <Trans i18nKey="bottle_model_firmware" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 40 }} />
                <SortableTableCell style={{ width: 40 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {bottleModelStore.bottleModels?.map((bottleModel) => (
                <BottleModelTableRow
                  key={bottleModel.id}
                  bottleModel={bottleModel}
                  loadList={this.loadList}
                  firmwareList={this.props.bottleModelStore.firmwares}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ bottleModel }: StoreModel) => {
  return { bottleModelStore: bottleModel };
};

export default withTableQueryParams({ sortBy: 'version' })(inject(mapStoreToProps)(observer(BottleModelList)));
