import React from 'react';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Subtract } from 'utility-types';

import config from 'config';
import { QueryParamModel } from 'models';
import { removeFalsy, isEmpty, removeProp } from 'utils/objects';
import { SortDirection } from '@material-ui/core/TableCell';

export interface SearchConditions {
  name: string;
  value: string | string[];
}

export interface WithTableQueryParamsProps {
  params: QueryParamModel;
  setCurrentParams: (params: Partial<QueryParamModel>) => QueryParamModel;
  onSort: (event: React.MouseEvent<unknown>, sortBy: string, order: SortDirection) => QueryParamModel;
  onChangePage: (event: unknown, page: number) => QueryParamModel;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => QueryParamModel;
  onSearch: (searchConditions: SearchConditions) => QueryParamModel;
  onFilter: (params?: Partial<QueryParamModel>) => QueryParamModel;
}

interface TableQueryParamsProps extends RouteComponentProps {
  filterParams: QueryParamModel;
  setFilterParams: (params: QueryParamModel) => void;
}

interface TableQueryParamsState {
  params: QueryParamModel;
}

const initialParams: Partial<QueryParamModel> = { order: 'asc', sortBy: 'name' };

export const withTableQueryParams = (overrideParams: Partial<QueryParamModel> = initialParams) => <
  P extends WithTableQueryParamsProps
>(
  WrappedComponent: React.ComponentType<P>
) => {
  const defaultParams = { ...initialParams, ...overrideParams };

  class TableQueryParams extends React.Component<
    Subtract<P, WithTableQueryParamsProps> & TableQueryParamsProps,
    TableQueryParamsState
  > {
    readonly state: TableQueryParamsState = {
      params: this.getCurrentParams(),
    };

    constructor(props: Subtract<P, WithTableQueryParamsProps> & TableQueryParamsProps) {
      super(props);

      this.getCurrentParams = this.getCurrentParams.bind(this);
      this.getParams = this.getParams.bind(this);
      this.addQueryToHistory = this.addQueryToHistory.bind(this);
    }

    getCurrentParams() {
      const { filterParams: savedQueryParams, location } = this.props;

      const queryParams = { ...qs.parse(location.search, config.QUERY_FORMAT) };

      const hasQueryParams = !isEmpty(queryParams);
      const hasSavedQueryParams = !isEmpty(savedQueryParams) && savedQueryParams.from === location.pathname;

      const params = hasQueryParams ? queryParams : hasSavedQueryParams ? savedQueryParams : defaultParams;

      return this.getParams(params);
    }

    getParams({ page, sortBy, order, pageSize, ...rest }: any = {}): QueryParamModel {
      return removeFalsy({
        page: typeof page === 'string' ? parseInt(page) : page || 1,
        sortBy: (sortBy as string) || defaultParams.sortBy,
        order: typeof order === 'undefined' ? defaultParams.order : (order as SortDirection),
        pageSize:
          typeof pageSize === 'string'
            ? parseInt(pageSize, 10)
            : pageSize || defaultParams.pageSize || config.ROWS_PER_PAGE_OPTIONS[0],
        ...rest,
      });
    }

    addQueryToHistory(params: Partial<QueryParamModel> = this.state.params) {
      this.props.history.replace(`${this.props.match.url}?${qs.stringify(params, config.QUERY_FORMAT)}`);
    }

    setCurrentParams = (queryParams?: Partial<QueryParamModel>) => {
      const params = this.getParams(queryParams);

      this.setState({ params });
      this.props.setFilterParams({ ...params, from: this.props.location.pathname });
      this.addQueryToHistory(params);

      return params;
    };

    onSort = (event: React.MouseEvent<unknown>, sortBy: string, order: SortDirection) => {
      return this.setCurrentParams({ ...this.state.params, sortBy, order });
    };

    onChangePage = (event: unknown, page: number) => {
      return this.setCurrentParams({ ...this.state.params, page: page + 1 });
    };

    onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      return this.setCurrentParams({ ...this.state.params, page: 1, pageSize: parseInt(event.target.value, 10) });
    };

    onSearch = ({ name, value }: SearchConditions) => {
      const params = value ? { ...this.state.params, [name]: value || null } : removeProp(this.state.params, name);

      const urlParams = new URLSearchParams(`${name}=${value}`);
      Object.fromEntries(urlParams);

      return this.setCurrentParams({ ...params, page: 1 });
    };

    onFilter = (params?: Partial<QueryParamModel>) => {
      return this.setCurrentParams({ ...this.state.params, ...(params || {}), page: 1 });
    };

    render() {
      return (
        <WrappedComponent
          params={this.state.params}
          setCurrentParams={this.setCurrentParams}
          onSort={this.onSort}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onSearch={this.onSearch}
          onFilter={this.onFilter}
          {...(this.props as P & TableQueryParamsProps)}
        />
      );
    }
  }

  return withRouter(
    inject(({ common }) => ({ setFilterParams: common.setFilterParams, filterParams: common.filterParams }))(
      observer(TableQueryParams)
    )
  );
};
