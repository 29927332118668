import { action, observable } from 'mobx';

import { NotificationsService } from 'services';
import { CreateNotificationsDto, QueryParamModel, NotificationsModel } from 'models';

export class NotificationsStore {
  private readonly notificationsService = new NotificationsService();

  @observable notifications: NotificationsModel[] = [];
  @observable notificationsCount = 0;

  @observable notificationsLoading = false;
  @observable notificationsSaving = false;
  @observable notificationsDeleting = false;
  @observable uploadInProgress = false;

  @action.bound
  async getNotifications(params?: QueryParamModel) {
    this.notificationsLoading = true;

    try {
      const response = await this.notificationsService.getList(params);

      this.notificationsCount = response.count;
      this.notifications = response.data;

      return response;
    } finally {
      this.notificationsLoading = false;
    }
  }

  @action.bound
  async updateNotifications(id: number, data: CreateNotificationsDto) {
    this.notificationsSaving = true;

    try {
      return await this.notificationsService.update(id, data);
    } finally {
      this.notificationsSaving = false;
    }
  }

  @action.bound
  async createNotifications(data: CreateNotificationsDto) {
    this.notificationsSaving = true;

    try {
      return await this.notificationsService.create(data);
    } finally {
      this.notificationsSaving = false;
    }
  }

  @action.bound
  async deleteNotifications(id: number) {
    this.notificationsDeleting = true;

    try {
      await this.notificationsService.delete(id);
    } finally {
      this.notificationsDeleting = false;
    }
  }
}
