import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { ApiError, ConfirmDialog } from 'components';
import { ApiErrorModel, NotificationType, NewsModel } from 'models';
import { WithNotification } from 'store';

interface NewsDeleteProps extends WithNotification {
  onSubmit: () => void;
  news: NewsModel;
  deleteNews: (id: number) => Promise<void>;
}

const NewsDelete: FC<NewsDeleteProps> = ({ onSubmit, news, showNotification, deleteNews }) => {
  const [error, setError] = useState<ApiErrorModel | null>(null);

  const handleSubmit = async () => {
    try {
      setError(null);

      if (news) {
        await deleteNews(news.id);
      }

      showNotification(<Trans i18nKey="delete_successful" />, NotificationType.SUCCESS);
      onSubmit();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <ConfirmDialog title={<Trans i18nKey="delete_news" />} onSubmit={handleSubmit}>
      <Trans i18nKey="news_delete_message" values={{ name: news.locationName }} />
      <ApiError error={error} />
    </ConfirmDialog>
  );
};

export default inject(({ news, common }) => {
  return {
    showNotification: common.showNotification,
    deleteNews: news.deleteNews,
  };
})(observer(NewsDelete));
