import React, {Component, Fragment, useEffect, useState} from "react";
import { Trans } from 'react-i18next';
import { BottleModel, UserExtendedModel } from "models";
import moment, { Moment } from 'moment';
import { locale } from 'i18n';
import 'moment/locale/en-gb';
import { formatDate } from 'utils/dates';


export const DTO_DATE_FORMAT = 'YYYY-MM-DD';
export const DTO_TIME_FORMAT = 'hh:mm:ss';
export const DTO_DATE_TIME_FORMAT = `${DTO_DATE_FORMAT} ${DTO_TIME_FORMAT}`;

interface UserProfileProps {
  user: UserExtendedModel;
  bottle: BottleModel;
  usage: any;
  hasEvents: boolean;
}

moment.locale(locale);

class UserProfileDetails extends Component<UserProfileProps>{

  // Int to string representation (en_EN)
  private gender = <Trans i18nKey={`gender_${this.props.user.gender}`} />;

  stringToMAC(str: string): string {
    let result = '';
    for (let i = 0; i < str.length; i += 2) {
      result += str.slice(i, i + 2) + ':';
    }
    return result.slice(0, -1); // Remove the last colon
  }

    render(): JSX.Element {
      return (
        <Fragment>
          <div>
            {/*TODO: Add this to translation file */}
            <h3>User information</h3>
            <p>
              <b>Account created: </b>{moment(this.props.user.createdAt).format('MMMM d, YYYY HH:ss')}<br />
              <b>Last login: </b>{moment(this.props.user.lastLoginAt).format('MMMM d, YYYY HH:ss')}<br />
              <b>e-mail: </b>{this.props.user.email}<br />
              <b>Gender: </b> {this.gender} <br/>
              <b>dailyStreak: </b>{this.props.user.dailyStreak}<br/>
              <b>Current Goal: </b>{this.props.user.dailyCap} ml<br/>
            </p>
          </div>
          {console.log('BOTTLE', this.props.bottle)}
          { // As we initialise the data in the bottle prop with id=0, which is falsy,
            // we can use this attribute to check for an assigned bottle.
            // NOTE: A single bottle is retrieved by user, as this is intended for
            // customer support.
            this.props.bottle.id ? (
          <div>
            <h3>Bottle information</h3>
            <p>
              <b>Manufacturer: </b>{this.props.bottle.manufacturer ?? ""}<br/>
              <b>Name: </b> {this.props.bottle.name ?? ""}<br/>
              <b>SKU: </b> { typeof this.props.bottle.variant !== "undefined" ? this.props.bottle.variant.sku : " ---- "}<br/>
              <b>Color: </b> { typeof this.props.bottle.variant !== "undefined" ? this.props.bottle.variant.name : " ---- "}<br/>
              <b>MAC address: </b> {this.stringToMAC(this.props.bottle.macAddress) || ""}<br/>
            </p>
          </div> ) : ( <div></div> )}
          <div>
            <h3>Usage</h3>
            { this.props.hasEvents ?
              (<p>
              <b>Bottle used for the first time: </b>{formatDate(this.props.usage.events.first.timestamp * 1000, DTO_DATE_TIME_FORMAT, { utc: false })}<br/>
                <b>First known battery level: </b> {this.props.usage.events.first.batteryLevel}%<br/>
              <b>Bottle used for the last time: </b>{formatDate(this.props.usage.events.last.timestamp * 1000, DTO_DATE_TIME_FORMAT, { utc: false })}<br/>
                <b>Last known battery level: </b> {this.props.usage.events.last.batteryLevel}%<br/>
                <b>Free fall times to date: {this.props.usage.events.last.freeFallTimes}</b><br/><br/>
              <code style={{backgroundColor: "#fdfd96", border: "1px solid #996666", padding: "5px", maxWidth: "200px"}}>
                <b>NOTE: </b> This information is extracted from events received over bluetooth
              </code>
            </p>) : (<p> This user hasn&apos;t registered any water events yet. </p>)}
          </div>
        </Fragment>
      );
   }
}

export default UserProfileDetails;

