import { StringifyOptions } from 'query-string';

export type InputVariant = 'outlined' | 'filled' | 'standard';

interface Config extends NodeJS.ProcessEnv {
  [key: string]: any;
  API_TOKEN_KEY: string;
  REFRESH_TOKEN_KEY: string;
  QUERY_FORMAT: StringifyOptions;
  ROWS_PER_PAGE_OPTIONS: number[];
  DEFAULT_INPUT_VARIANT: InputVariant;
}

const config: Config = {
  API_TOKEN_KEY: 'API_TOKEN',
  REFRESH_TOKEN_KEY: 'REFRESH_TOKEN',
  QUERY_FORMAT: { arrayFormat: 'bracket' },
  ROWS_PER_PAGE_OPTIONS: [10, 25, 50],
  DEFAULT_INPUT_VARIANT: 'outlined' as 'outlined',
  ...process.env,
};

// Be able to see the current configuration during development
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log(config);
}

export default config;
