import React, { FC, Fragment, memo } from 'react';
import { Link } from "react-router-dom";

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { EditIcon } from 'assets/icons';
import { InfoRounded } from "@material-ui/icons";
import { UserModel } from 'models';
import { useDialogState, TableRow, TableCell, TableRowProps, FormattedDate } from 'components';
import { DATE_FORMAT } from 'utils/dates';

import UserDetailModal from '../user-detail/user-detail-modal.component';

//import UserDelete from './user-delete.component';
//import UserBottlesCollectedModal from '../user-detail/user-bottles-collected-modal.component';

interface UserTableRowProps extends TableRowProps {
  user: UserModel;
  isSelected: boolean;

  selectRow(user: UserModel): void;
  addAdditionalBottles(user: UserModel, count: number): void;

  refreshList(): Promise<UserModel[]>;
}

const UserTableRow: FC<UserTableRowProps> = memo(
  ({ user, isSelected, selectRow, refreshList, addAdditionalBottles, ...props }) => {
    const [dialogOpen, handleDialogOpen, handleDialogClose] = useDialogState();
    /*const [
      bottlesCollectedDialogOpen,
      bottlesCollectedHandleDialogOpen,
      bottlesCollectedHandleDialogClose,
    ] = useDialogState();*/

    const onSelect = () => {
      selectRow(user);
    };

    return (
      <Fragment>
        <TableRow {...props}>
          <TableCell padding="checkbox">
            <Checkbox checked={isSelected} onClick={onSelect} />
          </TableCell>
          <TableCell>
            <Typography>{user.id}</Typography>
          </TableCell>
          <TableCell style={{width: 50, textAlign: "center"}}>
            <Link to={{pathname: `/user-profile/${user.id}/${user.uuid}`, state: user.id}}>
              <InfoRounded style={{color: "gray"}}></InfoRounded>
            </Link>
          </TableCell>
          <TableCell>
            <Typography>{user.name != null ? user.name:''}</Typography>
            <Typography variant="body2" color="textSecondary">
                  {user.email}
            </Typography>
          </TableCell>
          <TableCell style={{textAlign: "center"}}>
            <FormattedDate date={user.createdAt} format={DATE_FORMAT} />
          </TableCell>
          <TableCell style={{textAlign: "center"}}>
            <FormattedDate date={user.lastLoginAt} format={DATE_FORMAT} />
          </TableCell>
          {/*<TableCell>
            <Typography>{user.bottlesSaved}</Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {user.bottlesCollected + user.additionalBottlesCollected}
              <span />
              <IconButton size="small" onClick={bottlesCollectedHandleDialogOpen}>
                +
              </IconButton>
            </Typography>
          </TableCell>*/}
          {/*<TableCell>*/}
          {/*  <Typography>{user.country ? user.country.name : ''}</Typography>*/}
          {/*</TableCell>*/}
          <TableCell padding="checkbox">
            <IconButton onClick={handleDialogOpen}>
              <EditIcon fontSize="small" />
            </IconButton>
          </TableCell>
          {/*<TableCell padding="checkbox">
            <UserDelete user={user} onSubmit={refreshList} />
          </TableCell>*/}
        </TableRow>

        <UserDetailModal
          open={dialogOpen}
          onClose={handleDialogClose}
          title={user.name}
          userId={user.id}
          refreshList={refreshList}
        />
        {/*<UserBottlesCollectedModal
          open={bottlesCollectedDialogOpen}
          onClose={bottlesCollectedHandleDialogClose}
          title={user.name}
          userId={user.id}
          addAdditionalBottles={addAdditionalBottles}
          userBottlesCollected={user.bottlesCollected}
          userAdditionalBottlesCollected={user.additionalBottlesCollected}
        />*/}
      </Fragment>
    );
  }
);

export default UserTableRow;
