import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import request from '../../../utils/request';
import Chart from 'react-google-charts';
import Grid from '@material-ui/core/Grid';
import { List, ListItem, ListItemText, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    flex: 0.5,
    fontWeight: 'bold',
  },
  value: {
    flex: 0.5,
    textAlign: 'right',
  },
}));

interface UserStatisticResponse {
  code: string;
  goal: number;
  result: number;
  my: number;
}

document.body.style.backgroundColor = '#eaf2fd';

export default function UserStat() {
  const classes = useStyles();

  const params = useLocation();
  const [, , teamCode, userId] = params.pathname.split('/');

  const [statistic, setStatistic] = useState<UserStatisticResponse>({
    code: '',
    goal: 0,
    result: 0,
    my: 0,
  });

  useEffect(() => {
    request<UserStatisticResponse>({
      resource: `leaderboard/user/${userId}/${teamCode}`,
    }).then((result) => {
      setStatistic(result);
    });
  }, []);

  const chartData = useMemo(() => {
    return [
      ['Saved', 'Left'],
      [statistic.code, statistic.goal >= statistic.result ? statistic.result : statistic.goal],
      ['Left', statistic.goal > statistic.result ? statistic.goal - statistic.result : 0],
    ];
  }, [statistic]);

  const teamData = useMemo(() => {
    return [
      ['Saved', 'Left'],
      ['Your contribution', statistic.my],
      ['Total team contribution', statistic.goal >= statistic.result ? statistic.result : statistic.goal],
    ];
  }, [statistic]);
  const size = window.innerWidth >= 900 ? '50vh' : window.innerWidth >= 768 ? '30vh' : '25vh';
  const wSize = window.innerWidth >= 900 ? '50vh' : '50vw';
  return (
    <Container fixed style={{ color: '#2b6895' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1 style={{ textAlign: 'center' }}>Statistic for team {teamCode}</h1>
        </Grid>
        <Grid container justify="center">
          <Grid item md={4} xs={10}>
            <List>
              <ListItem divider alignItems="flex-start">
                <ListItemText className={classes.root} primary="Goal" />
                <ListItemText className={classes.value} primary={statistic.goal} />
              </ListItem>
              <ListItem divider alignItems="flex-start">
                <ListItemText className={classes.root} primary="Total team contribution" />
                <ListItemText className={classes.value} primary={statistic.result} />
              </ListItem>
              <ListItem divider alignItems="flex-start">
                <ListItemText className={classes.root} primary="Your contribution" />
                <ListItemText className={classes.value} primary={statistic.my} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item xs={6} />
        <Grid container>
          <Grid item xs={12} md={12}>
            <div style={{ paddingTop: '10px', display: 'flex', justifyContent: 'center', position: 'relative' }}>
              <Chart
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={chartData}
                height={size}
                width={wSize}
                options={{
                  // title: 'Result',
                  legend: {
                    position: 'bottom',
                    textStyle: { color: '#2b6895' },
                  },
                  backgroundColor: '#eaf2fd',
                  slices: {
                    0: { color: '#105aa5' },
                    1: { color: '#ffffff' },
                  },
                }}
                legendToggle
              />
              <Chart
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={teamData}
                height={size}
                width={wSize}
                options={{
                  // title: 'Result',
                  legend: {
                    position: 'bottom',
                    textStyle: { color: '#2b6895' },
                  },
                  backgroundColor: '#eaf2fd',
                  slices: {
                    0: { color: '#105aa5' },
                    1: { color: '#ffffff' },
                  },
                }}
                legendToggle
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
