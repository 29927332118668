import React, { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';

import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

interface ClearFieldAdornmentProps {
  value: string;
  onClick: MouseEventHandler;
  showSearchIcon?: boolean;
}

const useStyles = makeStyles(() => ({
  icon: {
    cursor: 'pointer',
  },
  focus: {
    pointerEvents: 'none',
  },
}));

const ClearFieldAdornment: FC<ClearFieldAdornmentProps> = ({ value, onClick, showSearchIcon }) => {
  const classes = useStyles();

  return (
    <InputAdornment position="end" onClick={onClick} className={classNames({ [classes.focus]: !value })}>
      {value ? <ClearIcon color="primary" className={classes.icon} /> : null}
      {!value && showSearchIcon ? <SearchIcon color="primary" className={classes.icon} /> : null}
    </InputAdornment>
  );
};

export default ClearFieldAdornment;
