import request, { generateUrl } from 'utils/request';
import { ListResponseModel, QueryParamModel, LogsModel } from 'models';

export class LogsService {
  private readonly resource = 'google-firebase';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<LogsModel>>({ resource: `${this.resource}/all`, params });
  }

  getExportUrl(token: string | null, params?: QueryParamModel) {
    return generateUrl({ resource: `${this.resource}/export`, params: { ...params, token } });
  }
}
