import { action, observable } from 'mobx';

import { WorkoutEventService } from 'services';
import { QueryParamModel, WorkoutEventModel } from 'models';
import { AuthStore } from './auth.store';

export class WorkoutEventStore {
  private readonly workoutEventService = new WorkoutEventService();
  private readonly authStore: AuthStore;

  @observable workoutEvents: WorkoutEventModel[] = [];
  @observable workoutEventCount = 0;

  @observable workoutEventLoading = false;
  @observable workoutEventSaving = false;
  @observable workoutEventDeleting = false;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action.bound
  async getWorkoutEvents(params?: QueryParamModel) {
    this.workoutEventLoading = true;

    try {
      const response = await this.workoutEventService.getList(params);

      this.workoutEventCount = response.count;
      this.workoutEvents = response.data;

      return response;
    } finally {
      this.workoutEventLoading = false;
    }
  }
  @action.bound
  getExportUrl(params?: QueryParamModel) {
    return this.workoutEventService.getExportUrl(this.authStore.token, params);
  }
}
