import { CredentialsModel, ProfileModel } from 'models';
import request, { Methods } from 'utils/request';

export type AccessToken = string;

export interface LoginResponse {
  accessToken: AccessToken;
  refreshToken: AccessToken;
  expiresIn: number;
  refreshExpireIn: number;
  tokenType: string;
  user: ProfileModel;
}

export class AuthService {
  login(credentials: CredentialsModel) {
    return request<LoginResponse>({ method: Methods.POST, resource: 'auth/login', data: credentials });
  }

  fetchRefreshToken(refreshToken: AccessToken) {
    return request<LoginResponse>({ method: Methods.GET, resource: 'auth/refresh-token' });
  }

  logout() {
    return request<void>({ method: Methods.GET, resource: 'auth/logout' });
  }

  getProfile() {
    return request<ProfileModel>({ resource: 'users/me' });
  }
}
