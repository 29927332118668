import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Field, Form, Formik } from 'formik';

import Box from '@material-ui/core/Box';

import { Button, Dialog, TextField } from 'components';
import { NotificationType, TeamModel, AddTeamModel } from 'models';
import { WithNotification } from 'store';
import { Yup } from 'utils/yup';
import { getErrors } from 'utils/request';

interface TeamDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  team?: TeamModel;
  addTeam: (data: AddTeamModel) => Promise<any>;
  updateTeam: (id: number, data: AddTeamModel) => Promise<any>;
  teamSaving: boolean;
}

const validationSchemaNew = Yup.object().shape({
  name: Yup.string()
    .max(20)
    .required(),
  code: Yup.string()
    .max(2)
    .required(),
  goal: Yup.number()
    .min(1)
    .max(1000000)
    .required(),
  adminEmail: Yup.string()
    .email()
    .max(255)
    .required(),
  adminPassword: Yup.string()
    .max(30)
    .required(),
});

const validationSchemaEdit = Yup.object().shape({
  name: Yup.string()
    .max(20)
    .required(),
  code: Yup.string()
    .max(2)
    .required(),
  goal: Yup.number()
    .min(1)
    .max(10000)
    .required(),
  adminEmail: Yup.string()
    .email()
    .max(255)
    .required(),
});

const TeamDialog: FC<TeamDialogProps> = ({
  open,
  onClose,
  team,
  showNotification,
  onSubmit,
  teamSaving,
  addTeam,
  updateTeam,
}) => {
  const getInitialValues = (team?: TeamModel) => {
    let initialValues;
    if (team) {
      initialValues = { ...team, adminPassword: '' };
    } else {
      initialValues = {
        name: '',
        code: '',
        adminEmail: '',
        goal: 100,
        duration: 2,
        adminPassword: Math.random()
          .toString(36)
          .substr(2, 8),
      } as AddTeamModel;
    }
    return initialValues;
  };

  const handleSubmit = async (values: AddTeamModel) => {
    try {
      const data = { ...values };
      if (team) {
        await updateTeam(team.id, data);
      } else {
        await addTeam(data);
      }
      showNotification(<Trans i18nKey="save_successful" />, NotificationType.SUCCESS);
      onClose();
      onSubmit();
    } catch (e) {
      getErrors(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={team ? <Trans i18nKey="team_edit" /> : <Trans i18nKey="team_new" />}
      showClose
    >
      <Formik
        initialValues={getInitialValues(team)}
        validationSchema={team ? validationSchemaEdit : validationSchemaNew}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        <Form>
          <Field
            component={TextField}
            name="name"
            maxLength={20}
            fullWidth
            label={<Trans i18nKey="team_name" />}
            margin="normal"
          />
          <Field
            component={TextField}
            name="code"
            maxLength={2}
            fullWidth
            label={<Trans i18nKey="team_code" />}
            margin="normal"
          />
          <Field component={TextField} name="goal" fullWidth label={<Trans i18nKey="goal" />} margin="normal" />
          <Field
            component={TextField}
            name="adminEmail"
            maxLength={255}
            fullWidth
            label={<Trans i18nKey="team_admin_email" />}
            margin="normal"
          />
          {!team && (
            <Field
              component={TextField}
              name="adminPassword"
              maxLength={30}
              fullWidth
              label={<Trans i18nKey="team_admin_password" />}
              margin="normal"
            />
          )}
          <Box textAlign="right">
            <Button type="submit" loading={teamSaving}>
              <Trans i18nKey="save" />
            </Button>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default inject(({ team, common }) => {
  return {
    showNotification: common.showNotification,
    addTeam: team.addTeam,
    updateTeam: team.updateTeam,
    teamSaving: team.teamSaving,
  };
})(observer(TeamDialog));
