import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import {
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  Button,
  SelectSearch,
} from 'components';
import { NotificationsStore } from 'store';

import NotificationsTableRow from './notifications-table-row.component';
import NotificationsDialog from './notifications-dialog.component';
import { MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

interface NotificationsListProps extends WithTableQueryParamsProps {
  notificationsStore: NotificationsStore;
}

interface NotificationsListState {
  createDialogOpen: boolean;
}

class NotificationsList extends Component<NotificationsListProps, NotificationsListState> {
  readonly state: NotificationsListState = {
    createDialogOpen: false,
  };

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps: Readonly<NotificationsListProps>) {
    if (prevProps.params !== this.props.params) {
      this.loadList();
    }
  }

  loadList = () => {
    return this.props.notificationsStore.getNotifications(this.props.params);
  };

  openCreateDialog = () => {
    this.setState({ createDialogOpen: true });
  };

  closeCreateDialog = () => {
    this.setState({ createDialogOpen: false });
  };

  render() {
    const { notificationsStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage } = this.props;
    const { createDialogOpen } = this.state;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3} display="flex" width="100%">
          <Box flex={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <SearchBar
                  name="filter_titleOrMessage"
                  onSearch={onSearch}
                  initialValue={params.filter_titleOrMessage}
                  fullWidth
                  label={<Trans i18nKey="notifications_list_search_label" />}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <SelectSearch
                  fullWidth
                  name="filter_type"
                  value={params.filter_type}
                  onSearch={onSearch}
                  label={<Trans i18nKey="notifications_type" />}
                  showAllOption={true}
                >
                  {['REMINDER', 'INTAKE', 'DRINK_MORE'].map((type) => (
                    <MenuItem value={type} key={type}>
                      <Trans i18nKey={'notifications_type_' + type} />
                    </MenuItem>
                  ))}
                </SelectSearch>
              </Grid>
            </Grid>
          </Box>
          <Box ml="auto">
            <Button variant="outlined" icon={AddIcon} onClick={this.openCreateDialog}>
              <Trans i18nKey="add" />
            </Button>
            <NotificationsDialog open={createDialogOpen} onClose={this.closeCreateDialog} onSubmit={this.loadList} />
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={notificationsStore.notificationsLoading}
          emptyMessage={<Trans i18nKey="notifications_list_empty_state_message" />}
          count={notificationsStore.notificationsCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <SortableTableCell field="id" {...headerParams}>
                  <Trans i18nKey="notifications_id" />
                </SortableTableCell>
                <SortableTableCell field="title" {...headerParams}>
                  <Trans i18nKey="notifications_title" />
                </SortableTableCell>
                <SortableTableCell field="message" {...headerParams}>
                  <Trans i18nKey="notifications_message" />
                </SortableTableCell>
                <SortableTableCell field="type" {...headerParams}>
                  <Trans i18nKey="notifications_type" />
                </SortableTableCell>
                <SortableTableCell />
                <SortableTableCell />
                <SortableTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {notificationsStore.notifications?.map((notifications) => (
                <NotificationsTableRow key={notifications.id} notifications={notifications} loadList={this.loadList} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ notifications }: StoreModel) => {
  return { notificationsStore: notifications };
};

export default withTableQueryParams({ sortBy: 'name' })(inject(mapStoreToProps)(observer(NotificationsList)));
