import axios, { AxiosRequestConfig, AxiosTransformer } from 'axios';
import { stringify } from 'query-string';

import config from 'config';
import { Store } from 'store';
import { ApiErrorEnum, ApiErrorModel, NotificationType } from 'models';

export enum Methods {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum Status {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}

export interface RequestConfig extends AxiosRequestConfig {
  resource: string;
  method?: Methods;
}

export const getErrors = (error: ApiErrorModel | null) => {
  if (error && error.response && error.response.data) {
    return [error.response.data.errorCode];
  }

  return [];
};

export const isActiveApiError = (error: ApiErrorModel | null) => {
  return getErrors(error);
};

export const isAuthError = (errorCode: number) => {
  return errorCode >= 100 && errorCode < 120;
};
const axiosInstance = axios.create({});
const refreshTokenBlacklist = ['/api/auth/refresh-token'];

let refreshPromise: Promise<void> | null = null;

const shouldCallRefreshToken = (url?: string) => {
  return url ? !refreshTokenBlacklist.map((path) => url.includes(path)).includes(true) : false;
};

const isTokenError = (errors: number[]) => {
  const tokenErrors: number[] = [
    ApiErrorEnum.EXPIRED_TOKEN,
    ApiErrorEnum.EXPIRED_REFRESH_TOKEN,
    ApiErrorEnum.INVALID_TOKEN,
    ApiErrorEnum.MISSING_TOKEN,
  ];

  return errors.some((error) => tokenErrors.includes(error));
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Store.auth.token;
    const refreshToken = Store.auth.refreshToken;

    if (!shouldCallRefreshToken(config.url)) {
      config.headers.Authorization = refreshToken ? `bearer ${refreshToken}` : refreshToken;
    } else {
      config.headers.Authorization = token ? `bearer ${token}` : token;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  async (response) => response,
  async (error: ApiErrorModel) => {
    Store.common.hideLoader();

    if (error.response && error.config) {
      const { statusCode, errorMessage } = error.response.data;
      const errors = getErrors(error);

      if (!config.API_TOKEN_KEY || !config.REFRESH_TOKEN_KEY) {
        await Store.auth.logout();
      }

      if (!errors.find(isAuthError)) {
        Store.common.showNotification(`${statusCode} - ${errorMessage}`, NotificationType.ERROR);
      }

      if (isTokenError(errors) && shouldCallRefreshToken(error.config.url)) {
        try {
          if (!refreshPromise) {
            refreshPromise = Store.auth.fetchRefreshToken();
          }

          await refreshPromise;

          refreshPromise = null;

          return await axiosInstance.request(error.config);
        } catch (e) {
          const errors = getErrors(e);

          if (errors.find(isAuthError) || (e && e.response && e.response.status === Status.UNAUTHORIZED)) {
            await Store.auth.clientLogout();
            // await Store.auth.logout();
          }

          throw e;
        }
      }
    }

    return Promise.reject(error);
  }
);

interface GenerateUrlSettings {
  baseURL?: string;
  resource?: string;
  params?: Record<string, any>;
}

export function appendParamsToUrl(url: string, params?: Record<string, any>) {
  const query = params ? `?${stringify(params, config.QUERY_FORMAT)}` : '';

  return `${url}${query}`;
}

export function generateUrl({ baseURL = config.REACT_APP_API_URL, resource = '', params }: GenerateUrlSettings = {}) {
  let url = `${baseURL || ''}/api/${resource}`;
  if (config.REACT_APP_API_VERSION) {
    url = `${baseURL || ''}/api/${config.REACT_APP_API_VERSION}/${resource}`;
  }

  return appendParamsToUrl(url, params);
}

async function request<T = void>({
  resource,
  method = Methods.GET,
  headers = {},
  transformResponse,
  ...config
}: RequestConfig) {
  const url = generateUrl({ baseURL: config.baseURL, resource });

  const requestConfig = {
    method,
    url,
    headers: {
      ...(headers || {}),
      platform: 'web',
    },
    transformResponse: [
      ...(Array.isArray(axiosInstance.defaults.transformResponse) ? axiosInstance.defaults.transformResponse : []),
      transformResponse,
    ].filter((x) => x) as AxiosTransformer[],
    ...config,
  };

  const { data: response } = await axiosInstance.request<T>(requestConfig);

  return response;
}

export default request;
