import React, { Component, Fragment } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import {
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  TableCell,
  SortableTableCell,
} from 'components';
import { LogsStore } from 'store';

import LogsTableRow from './logs-table-row.component';
import { compose } from '../../utils/function';

interface LogsListProps extends WithTableQueryParamsProps, WithTranslation {
  logsStore: LogsStore;
}

interface LogsListState {
  createDialogOpen: boolean;
}

class LogsList extends Component<LogsListProps, LogsListState> {
  readonly state: LogsListState = {
    createDialogOpen: false,
  };

  async componentDidMount() {
    await this.loadList();
  }

  async componentDidUpdate(prevProps: Readonly<LogsListProps>) {
    if (prevProps.params !== this.props.params) {
      await this.loadList();
    }
  }

  loadList = async () => {
    return await this.props.logsStore.getLogs(this.props.params);
  };

  getExportUrl = () => {
    return this.props.logsStore.getExportUrl(this.props.params);
  };

  render() {
    const { logsStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage } = this.props;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3} display="flex" width="100%">
          <Box flex={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <SearchBar
                  name="filter_dateFrom"
                  onSearch={onSearch}
                  initialValue={params.filter_dateFrom}
                  fullWidth
                  label={<Trans i18nKey="event_list_search_date_from" />}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <SearchBar
                  name="filter_dateTo"
                  onSearch={onSearch}
                  initialValue={params.filter_dateTo}
                  fullWidth
                  label={<Trans i18nKey="event_list_search_date_to" />}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <SearchBar
                  name="filter_nameOrEmail"
                  onSearch={onSearch}
                  initialValue={params.filter_nameOrEmail}
                  fullWidth
                  label={<Trans i18nKey="event_list_search_label" />}
                />
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" mt={1} mb={2}>
            <Box ml="auto">
              {/*<Tooltip title={<Trans i18nKey="logs_export" />}>*/}
              {/*  <Link href={this.getExportUrl()}>*/}
              {/*    <Button variant="outlined" icon={DownloadIcon}>*/}
              {/*      <Trans i18nKey="export" />*/}
              {/*    </Button>*/}
              {/*  </Link>*/}
              {/*</Tooltip>*/}
            </Box>
          </Box>
        </Box>

        <TableContainer
          responsive
          loading={logsStore.logsLoading}
          emptyMessage={<Trans i18nKey="logs_list_empty_state_message" />}
          count={logsStore.logsCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell {...headerParams}>
                  <Trans i18nKey="event_user_name_email" />
                </TableCell>
                <SortableTableCell field={'date'} style={{ width: 200, textAlign: 'center' }} {...headerParams}>
                  <Trans i18nKey="date" />
                </SortableTableCell>
                <TableCell style={{ width: 300, textAlign: 'center' }} {...headerParams}>
                  <Trans i18nKey="logs_filename" />
                </TableCell>
                <TableCell style={{ width: 80 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {logsStore.logs?.map((log, i) => (
                <LogsTableRow key={i} log={log} loadList={this.loadList} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ logs }: StoreModel) => {
  return { logsStore: logs };
};

export default compose(
  withTableQueryParams({ sortBy: 'name' }),
  withTranslation(),
  inject(mapStoreToProps),
  observer
)(LogsList);
