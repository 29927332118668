import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { filterRoutes, Loader, Notification, ProtectedRoute, Sidebar } from 'components';
import { ProfileModel, RouteConfigurationModel, Role } from 'models';
import redirectLink from 'utils/redirectLink';
import { compose } from 'utils/function';

import Login from 'pages/login/login.component';
import NotFound from 'pages/error/not-found.component';
import NotAuthorized from 'pages/error/not-authorized.component';
import Users from 'pages/users/users.component';

import UsersIcon from '@material-ui/icons/People';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import StorageIcon from '@material-ui/icons/Storage';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import SmsIcon from '@material-ui/icons/Sms';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import MemoryIcon from '@material-ui/icons/Memory';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import SaveIcon from '@material-ui/icons/Save';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ListIcon from '@material-ui/icons/List';

import News from '../news/news.component';
import Event from '../events/event.component';
import Health from '../healths/health.component';
import Notifications from '../notifications/notifications.component';
import WorkoutEvent from '../workout-events/workout-event.component';
import Firmware from '../firmware/firmware.component';
import BottleModel from '../bottle-model/bottle-model.component';
import Team from '../leaderboard/teams/team.component';
import SubTeam from '../leaderboard/sub-teams/sub-team.component';
import TeamUser from '../leaderboard/user/team-user.component';
import Stat from '../leaderboard/stat/stat';
import UserStat from '../leaderboard/stat/user-stat';
import { CupIcon } from './icons/CupIcon';
import UserProfile from "../users/profile/user-profile.component";
import GoogleDisk from '../google-disk/google-disk.component';
import CompareLogs from '../compare-logs/compare-logs.component';
import Logs from '../logs/logs.component';
import RuntasticPage from 'pages/runtastic/runtastic.component';
import UserProfileComponent from "../users/profile/user-profile.component";

interface AppProps extends WithTranslation, RouteComponentProps, WithStyles<typeof styles> {
  hideLoader: () => void;
  getProfile: () => Promise<ProfileModel>;
  initializeToken: () => Promise<void>;
  roles: Role[];
  drawerOpen: boolean;
  token: string | null;
  isLoggedIn: boolean;
}

interface AppState {
  loading: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(theme.defaultSpacing),
      paddingBottom: theme.spacing(theme.defaultSpacing),
      [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
        marginLeft: theme.mixins.closedDrawer.width,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
    contentShift: {
      [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
        marginLeft: theme.mixins.drawer.width,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  });

class App extends Component<AppProps, AppState> {
  readonly state: AppState = {
    loading: true,
  };

  private readonly routes: RouteConfigurationModel[] = [
    {
      component: Users,
      link: '/users',
      path: '/users',
      title: this.props.t('users'),
      icon: UsersIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: UserProfile,
      link: '/user-profile',
      path: '/user-profile/:id/:uuid',
      title: () => "", //this.props.t('users'), //this.props.user.name || this.props.user.uuid,
      icon: UsersIcon,
      roles: [Role.ADMIN],
      show: false,
    },
    {
      component: News,
      link: '/news',
      path: '/news',
      title: this.props.t('news'),
      icon: AnnouncementIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: Event,
      link: '/events',
      path: '/events',
      title: this.props.t('events'),
      icon: StorageIcon,  //import WaterDropIcon from '@mui/icons-material/WaterDrop';
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: Health,
      link: '/healths',
      path: '/healths',
      title: this.props.t('healths'),
      icon: FitnessCenterIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: Notifications,
      link: '/notifications',
      path: '/notifications',
      title: this.props.t('push_notifications'),
      icon: SmsIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: WorkoutEvent,
      link: '/workout-events',
      path: '/workout-events',
      title: this.props.t('workout-events'),
      icon: DirectionsRunIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: Firmware,
      link: '/firmware',
      path: '/firmware',
      title: this.props.t('firmware'),
      icon: MemoryIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: BottleModel,
      link: '/bottle-model',
      path: '/bottle-model',
      title: this.props.t('bottle_model'),
      icon: LocalDrinkIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: Team,
      link: '/teams',
      path: '/teams',
      title: this.props.t('teams'),
      icon: CupIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: SubTeam,
      link: '/subteam',
      path: '/subteam',
      title: this.props.t('subteam'),
      icon: PeopleOutlineIcon,
      roles: [Role.CUSTOMER_ADMIN],
      show: true,
    },
    {
      component: TeamUser,
      link: '/team-user/:id',
      path: '/team-user/:id',
      title: this.props.t('team user'),
      icon: PeopleOutlineIcon,
      roles: [Role.CUSTOMER_ADMIN],
      show: false,
    },
    /*{
      component: GoogleDisk,
      link: '/google-disk',
      path: '/google-disk',
      title: this.props.t('google_disk'),
      icon: SaveIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    {
      component: CompareLogs,
      link: '/compare-logs',
      path: '/compare-logs',
      title: this.props.t('compare_logs'),
      icon: ListIcon,
      roles: [Role.ADMIN],
      show: true,
    },*/
    {
      component: Logs,
      link: '/logs',
      path: '/logs',
      title: this.props.t('logs'),
      icon: ListIcon,
      roles: [Role.ADMIN],
      show: true,
    },
    /*{
       component: RuntasticPage,
       link: '/runtastic',
       path: '/runtastic',
       title: this.props.t('runtastic'),
       roles: [Role.ADMIN],
       icon: ListIcon,
       show: true,
     },*/
  ];

  async componentDidMount() {
    await this.props.initializeToken();

    if (this.props.isLoggedIn) {
      try {
        await this.props.getProfile();
      } finally {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { location, classes, token, drawerOpen, roles } = this.props;
    const { loading } = this.state;

    const redirectUrl = redirectLink(location);
    const deepLink = location.pathname + location.search;
    const authenticatedRoutes = filterRoutes(this.routes, roles);
    const isiOS = window.navigator.userAgent.indexOf('iPhone') != -1
    const isAndroid = window.navigator.userAgent.indexOf('Android') != -1

    if(isiOS){
      window.location.href="https://apps.apple.com/en/app/rebo-smart-bottle/id1537539256"
      return null;
    }else if(isAndroid){
      window.location.href="https://play.google.com/store/apps/details?id=com.rebo.app"
      return null;
    }else{

    return (
      <Box>
        <Loader show={loading} fullWidth={loading} />
        <Notification />

        {!loading ? (
          <Switch>
            <Route path="/stat" render={() => <Stat />} />
            <Route path="/user-stat" render={() => <UserStat />} />

            <Route
              path="/login"
              render={() => (token ? <Redirect to={redirectUrl ? redirectUrl : '/'} /> : <Login />)}
            />
            <Route path="/404" component={NotFound} />
            <Route
              path="/"
              render={() =>
                token ? (
                  <Fragment>
                    <Sidebar routes={authenticatedRoutes.filter((route) => route.show)} />
                    <main
                      className={classNames(classes.content, {
                        [classes.contentShift]: drawerOpen,
                      })}
                    >
                      <Toolbar />
                      <Switch>
                        {authenticatedRoutes.map((route) => (
                          <ProtectedRoute
                            key={route.path}
                            component={route.component}
                            path={route.path}
                            allowedFor={route.roles}
                          />
                        ))}

                        <Route
                          path="/"
                          render={() =>
                            authenticatedRoutes.length ? (
                              <Redirect to={authenticatedRoutes[0].path} />
                            ) : (
                              <NotAuthorized />
                            )
                          }
                        />
                      </Switch>
                    </main>
                  </Fragment>
                ) : (
                  <Redirect to={`/login${deepLink !== '/' ? `?redirect=${window.btoa(deepLink)}` : ''}`} />
                )
              }
            />
            <Redirect to="/404" />
          </Switch>
        ) : null}
      </Box>
    );}
  }
}

export default compose(
  withTranslation(),
  withRouter,
  withStyles(styles),
  inject(({ common, auth, users }) => ({
    hideLoader: common.hideLoader,
    drawerOpen: common.drawerOpen,
    token: auth.token,
    getProfile: auth.getProfile,
    roles: auth.roles,
    initializeToken: auth.initializeToken,
    isLoggedIn: auth.isLoggedIn,
  })),
  observer
)(App);
