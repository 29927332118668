import request, { Methods } from 'utils/request';
import { AddBottleModel, ListResponseModel, QueryParamModel, BottleModelModel } from 'models';

export class BottleModelService {
  private readonly resource = 'bottle-model';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<BottleModelModel>>({ resource: this.resource, params });
  }

  create(data: AddBottleModel) {
    return request<BottleModelModel>({ method: Methods.POST, resource: this.resource, data });
  }

  update(id: number, data: AddBottleModel) {
    return request<BottleModelModel>({ method: Methods.PUT, resource: `${this.resource}/${id}`, data });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `${this.resource}/${id}` });
  }
}
