import request, { Methods } from 'utils/request';
import { CalendarModel } from 'models';

export class SettingsService {
  private readonly resource = 'admin';

  getCalendarSettings(year: number) {
    return request<CalendarModel>({ resource: `${this.resource}/calendar/${year}` });
  }

  saveCalendarSettings(year: number, data: CalendarModel) {
    return request<CalendarModel>({
      method: Methods.POST,
      resource: `${this.resource}/calendar/${year}`,
      data: { year, ...data },
    });
  }
}
