import i18n from 'i18n';

interface FormatOptions {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  currency?: string;
  postfix?: string;
}

function isIntlSupported() {
  // Check if browser supports Intl API
  return typeof Intl == 'object' && Intl && typeof Intl.NumberFormat == 'function';
}

export function formatNumber(
  num?: number | string | null,
  { minimumFractionDigits = 0, maximumFractionDigits = 0, currency, postfix }: FormatOptions = {}
) {
  const value = typeof num === 'string' ? parseInt(num, 10) : num || 0;

  // Use Browser's Intl for nicer formatting
  if (isIntlSupported()) {
    if (currency) {
      return value.toLocaleString(i18n.language, {
        currency,
        style: 'currency',
        maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits,
      });
    }

    const formatted = value.toLocaleString(i18n.language, {
      style: 'decimal',
      maximumFractionDigits,
      minimumFractionDigits,
    });

    return postfix ? `${formatted} ${postfix}` : formatted;
  }

  const formatted = value
    .toFixed(maximumFractionDigits)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

  if (currency) {
    return `${formatted} ${currency}`;
  }

  if (postfix) {
    return `${formatted} ${postfix}`;
  }

  return formatted;
}

export const parseStringAsInt = <T extends string>(value: T | T[]) => {
  if (Array.isArray(value)) {
    return value.map((val) => parseInt(val, 10));
  }

  return parseInt(value, 10);
};

export function floorTo(value: number, to: number) {
  return to * Math.floor(value / to);
}

export function ceilTo(value: number, to: number) {
  return to * Math.ceil(value / to);
}

export function toInteger(value: any) {
  const parsed = parseInt(value);

  return isNaN(parsed) ? 0 : parsed;
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
