import { createSvgIcon } from 'components/svg-icon/svg-icon.component';

import { ReactComponent as ArrowDownSvg } from './arrow-down.svg';
import { ReactComponent as ArrowUpSvg } from './arrow-up.svg';
import { ReactComponent as ArrowLeftSvg } from './arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from './arrow-right.svg';
import { ReactComponent as StationSvg } from './station.svg';
import { ReactComponent as DeleteSvg } from './delete.svg';
import { ReactComponent as EditSvg } from './edit.svg';
import { ReactComponent as DownloadSvg } from './download.svg';

import { ReactComponent as CCSConnectorSvg } from './ccs.svg';
import { ReactComponent as CEEConnectorSvg } from './cee.svg';
import { ReactComponent as CHAdeMOConnectorSvg } from './chademo.svg';
import { ReactComponent as SchukoConnectorSvg } from './schuko.svg';
import { ReactComponent as TeslaConnectorSvg } from './tesla.svg';
import { ReactComponent as Type2ConnectorSvg } from './type2.svg';

export const ArrowDownIcon = createSvgIcon(ArrowDownSvg);
export const ArrowUpIcon = createSvgIcon(ArrowUpSvg);
export const ArrowLeftIcon = createSvgIcon(ArrowLeftSvg);
export const ArrowRightIcon = createSvgIcon(ArrowRightSvg);
export const StationIcon = createSvgIcon(StationSvg);
export const DeleteIcon = createSvgIcon(DeleteSvg);
export const EditIcon = createSvgIcon(EditSvg);
export const DownloadIcon = createSvgIcon(DownloadSvg);

export const CCSConnectorIcon = createSvgIcon(CCSConnectorSvg);
export const CEEConnectorIcon = createSvgIcon(CEEConnectorSvg);
export const CHAdeMOConnectorIcon = createSvgIcon(CHAdeMOConnectorSvg);
export const SchukoConnectorIcon = createSvgIcon(SchukoConnectorSvg);
export const TeslaConnectorIcon = createSvgIcon(TeslaConnectorSvg);
export const Type2ConnectorIcon = createSvgIcon(Type2ConnectorSvg);
