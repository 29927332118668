import React, { FC, forwardRef, Ref } from 'react';
import classNames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 'calc(50% - 12px)',
    right: 'calc(50% - 12px)',
  },
  fullWidth: {
    width: '100%',
  },
  button: {
    height: '100%',
  },
}));

interface ButtonProps extends MuiButtonProps {
  buttonClassName?: string;
  className?: string;
  loading?: boolean;
  icon?: React.ComponentType<SvgIconProps>;
  iconPosition?: 'start' | 'end';
}

const Button: FC<ButtonProps> = forwardRef(
  (
    {
      loading,
      disabled,
      className,
      fullWidth,
      buttonClassName,
      variant,
      color,
      icon: Icon,
      iconPosition = 'start',
      children,
      ...props
    },
    ref: Ref<any>
  ) => {
    const classes = useStyles();
    return (
      <div ref={ref} className={classNames(classes.root, className, { [classes.fullWidth]: fullWidth })}>
        <MuiButton
          disabled={loading || disabled}
          className={classNames(classes.button, buttonClassName)}
          fullWidth={fullWidth}
          variant={variant}
          color={color}
          {...props}
        >
          {Icon && iconPosition === 'start' ? (
            <Box mr={1} display="flex" alignItems="center">
              <Icon fontSize="small" />
            </Box>
          ) : null}

          {children}

          {Icon && iconPosition === 'end' ? (
            <Box ml={1} display="flex" alignItems="center">
              <Icon fontSize="small" />
            </Box>
          ) : null}
        </MuiButton>

        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  }
);

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
};

export default Button;
