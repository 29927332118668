import React, { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';

import Chip from '@material-ui/core/Chip';

import { NewsModel } from 'models';
import { TableRow, TableCell, TableRowProps, useDialogState } from 'components';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from 'assets/icons';

import NewsDialog from './news-dialog.component';
import NewsDelete from './news-delete.component';
import { DATE_FORMAT, formatDate } from '../../utils/dates';
import { Avatar } from '@material-ui/core';

interface NewsTableRowProps extends TableRowProps {
  news: NewsModel;
  loadList: () => void;
}

const NewsTableRow: FC<NewsTableRowProps> = ({ news, loadList, ...props }) => {
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{news.id}</TableCell>
        <TableCell>{news.locationName}</TableCell>
        <TableCell>
          <Avatar variant="square" alt="Agnes Walker" src={news.imageUrl} />
        </TableCell>
        <TableCell>{news.description}</TableCell>
        <TableCell>{formatDate(news.timestamp * 1000, DATE_FORMAT)}</TableCell>
        <TableCell>
          {news.isActive ? <Chip color="primary" size="small" label={<Trans i18nKey="active" />} /> : null}
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={openEditDialog}>
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <NewsDelete news={news} onSubmit={loadList} />
        </TableCell>
      </TableRow>

      <NewsDialog open={editDialogOpen} onClose={closeEditDialog} news={news} onSubmit={loadList} />
    </Fragment>
  );
};

export default NewsTableRow;
