import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Page } from 'components';

import WorkoutEventList from './workout-event-list.component';

const Workouts: FC = () => {
  return (
    <Page title={<Trans i18nKey="workout-events" />}>
      <WorkoutEventList />
    </Page>
  );
};

export default Workouts;
