import { action, observable } from 'mobx';

import { FirmwareService } from 'services';
import { AddFirmwareDto, QueryParamModel, FirmwareModel } from 'models';

export class FirmwareStore {
  private readonly firmwareService = new FirmwareService();

  @observable firmwares: FirmwareModel[] = [];
  @observable firmwareCount = 0;

  @observable firmwareLoading = false;
  @observable firmwareSaving = false;
  @observable firmwareDeleting = false;
  @observable uploadInProgress = false;

  @action.bound
  async getFirmware(params?: QueryParamModel) {
    this.firmwareLoading = true;

    try {
      const response = await this.firmwareService.getList(params);

      this.firmwareCount = response.count;
      this.firmwares = response.data;

      return response;
    } finally {
      this.firmwareLoading = false;
    }
  }

  @action.bound
  async updateFirmware(id: number, data: AddFirmwareDto) {
    this.firmwareSaving = true;

    try {
      return await this.firmwareService.update(id, data);
    } finally {
      this.firmwareSaving = false;
    }
  }

  @action.bound
  async addFirmware(data: AddFirmwareDto) {
    this.firmwareSaving = true;

    try {
      return await this.firmwareService.create(data);
    } finally {
      this.firmwareSaving = false;
    }
  }

  @action.bound
  async deleteFirmware(id: number) {
    this.firmwareDeleting = true;

    try {
      await this.firmwareService.delete(id);
    } finally {
      this.firmwareDeleting = false;
    }
  }

  @action.bound
  async uploadFile(id: number, file: File) {
    this.uploadInProgress = true;

    try {
      return await this.firmwareService.uploadFirmware(id, file);
    } finally {
      this.uploadInProgress = false;
    }
  }
}
