export enum HealthStoreType {
  APPLE_HEALTH,
  GOOGLE_FIT,
}

export const healthStoreType = (type: HealthStoreType) => {
  return `health_store_type_${type}`;
};

export interface HealthModel {
  id: number;
  calorie: number;
  type: HealthStoreType;
  createdAt: number;
  updatedAt: number;
  timestamp: number;
  userId: number;
  userName: string;
  userEmail: string;
  stepCount: number;
}
export type CreateHealthModel = PartialBy<HealthModel, 'id'>;

export interface CreateHealthDto extends Omit<CreateHealthModel, 'isActive'> {
  isActive: number;
}
