import { action, observable } from 'mobx';

import { NewsService } from 'services';
import { CreateNewsDto, QueryParamModel, NewsModel } from 'models';

export class NewsStore {
  private readonly newsService = new NewsService();

  @observable news: NewsModel[] = [];
  @observable newsCount = 0;

  @observable newsLoading = false;
  @observable newsSaving = false;
  @observable newsDeleting = false;
  @observable uploadInProgress = false;

  @action.bound
  async getNews(params?: QueryParamModel) {
    this.newsLoading = true;

    try {
      const response = await this.newsService.getList(params);

      this.newsCount = response.count;
      this.news = response.data;

      return response;
    } finally {
      this.newsLoading = false;
    }
  }

  @action.bound
  async updateNews(id: number, data: CreateNewsDto) {
    this.newsSaving = true;

    try {
      return await this.newsService.update(id, data);
    } finally {
      this.newsSaving = false;
    }
  }

  @action.bound
  async createNews(data: CreateNewsDto) {
    this.newsSaving = true;

    try {
      return await this.newsService.create(data);
    } finally {
      this.newsSaving = false;
    }
  }

  @action.bound
  async deleteNews(id: number) {
    this.newsDeleting = true;

    try {
      await this.newsService.delete(id);
    } finally {
      this.newsDeleting = false;
    }
  }

  @action.bound
  async uploadImage(id: number, file: File) {
    this.uploadInProgress = true;

    try {
      return await this.newsService.uploadImage(id, file);
    } finally {
      this.uploadInProgress = false;
    }
  }
}
