import React, { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';

import { EventModel, eventType } from 'models';
import { TableRow, TableCell, TableRowProps, useDialogState } from 'components';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import EventDialog from './event-dialog.component';
import { DATE_TIME_FORMAT, formatDate } from '../../utils/dates';
import { Typography } from '@material-ui/core';

interface EventTableRowProps extends TableRowProps {
  event: EventModel;
  loadList: () => void;
}

const EventTableRow: FC<EventTableRowProps> = ({ event, loadList, ...props }) => {
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{event.id}</TableCell>
        <TableCell>
          <Typography>{event.userName}</Typography>
          <Typography variant="body2" color="textSecondary">
            {event.userEmail}
          </Typography>
        </TableCell>
        <TableCell>{<Trans i18nKey={eventType(event.type)} />}</TableCell>
        <TableCell>{event.quantity}</TableCell>
        <TableCell>{event.isManual ? 'yes' : 'no'}</TableCell>
        <TableCell>{formatDate(event.timestamp * 1000, DATE_TIME_FORMAT)}</TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={openEditDialog}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <EventDialog open={editDialogOpen} onClose={closeEditDialog} event={event} onSubmit={loadList} />
    </Fragment>
  );
};

export default EventTableRow;
