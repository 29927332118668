import React, { memo } from 'react';

export interface DayWrapperProps {
  value: any;
  children: React.ReactNode;
  dayInCurrentMonth?: boolean;
  disabled?: boolean;
  onSelect: (e: React.SyntheticEvent, value: any) => void;
}

const DayWrapper: React.FC<DayWrapperProps> = ({ value, disabled, onSelect, dayInCurrentMonth, ...other }) => {
  const handleClick = React.useCallback((e: React.SyntheticEvent) => onSelect(e, value), [onSelect, value]);

  return (
    <div
      onClick={dayInCurrentMonth && !disabled ? handleClick : undefined}
      onKeyPress={dayInCurrentMonth && !disabled ? handleClick : undefined}
      {...other}
    />
  );
};

export default memo(DayWrapper);
