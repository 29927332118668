import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import { DetailRowTitle, ConditionalWrapper } from 'components';

interface DetailRowProps {
  label: React.ReactNode;
  value: React.ReactNode;
  noWrap?: boolean;
  showEmpty?: boolean;
  inline?: boolean;
  maxLine?: number;
  tooltip?: React.ReactNode;
  tooltipProps?: TooltipProps;
}

const DetailRow: FC<DetailRowProps> = ({ label, value, tooltip, tooltipProps, noWrap, showEmpty, inline = true }) => {
  return value || showEmpty ? (
    <Box
      display={inline ? 'flex' : undefined}
      alignItems={inline ? 'center' : undefined}
      flexWrap={inline ? 'wrap' : undefined}
    >
      <DetailRowTitle>
        {label}
        {': '}
      </DetailRowTitle>
      <Typography component="div" noWrap={noWrap}>
        <ConditionalWrapper
          condition={Boolean(tooltip)}
          wrapper={(children) => (
            <Tooltip title={tooltip} {...(tooltipProps || {})}>
              <div>{children}</div>
            </Tooltip>
          )}
        >
          {value}
        </ConditionalWrapper>
      </Typography>
    </Box>
  ) : null;
};

export default DetailRow;
