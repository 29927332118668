import React, { FC, Fragment, useMemo } from 'react';

import { NotificationType, TeamUserShowModel, TeamUserStatus } from 'models';
import { TableCell, TableRow, TableRowProps, useDialogState } from 'components';

import TeamDialog from './team-user-dialog.component';
import TeamDelete from './team-user-delete.component';
import { DTO_DATE_TIME_FORMAT, formatDate } from '../../../utils/dates';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import { copyTextToClipboard } from '../../../utils/clipboard';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { WithNotification } from '../../../store';
import Chip from '@material-ui/core/Chip';

interface TeamTableRowProps extends TableRowProps, WithNotification {
  teamUser: TeamUserShowModel;
  loadList: () => void;
}

const TeamTableRow: FC<TeamTableRowProps> = ({ teamUser, showNotification, loadList, ...props }) => {
  const [editDialogOpen, closeEditDialog] = useDialogState();

  const copyClipboard = () => {
    copyTextToClipboard(`link to share for user ${teamUser.user} with code ${teamUser.code}`)
      .then(() => {
        showNotification(<Trans i18nKey="clipboard_successful" />, NotificationType.SUCCESS);
      })
      .catch(() => {
        showNotification(<Trans i18nKey="clipboard_fail" />, NotificationType.WARNING);
      });
  };

  const status = useMemo(() => {
    switch (teamUser.status) {
      case TeamUserStatus.NOT_ACCEPTED:
        return <Chip label="Not accepted" color="default" />;
      case TeamUserStatus.ACCEPTED:
        return <Chip label="Accepted" color="primary" />;
    }
  }, [teamUser]);

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{teamUser.id}</TableCell>
        <TableCell>{teamUser.user}</TableCell>
        <TableCell>{teamUser.subTeam}</TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>{formatDate(teamUser.createdAt * 1000, DTO_DATE_TIME_FORMAT)}</TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={copyClipboard}>
            <CopyIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <TeamDelete teamUser={teamUser} onSubmit={loadList} />
        </TableCell>
      </TableRow>

      <TeamDialog open={editDialogOpen} onClose={closeEditDialog} teamUser={teamUser} onSubmit={loadList} />
    </Fragment>
  );
};

export default inject(({ common }) => {
  return {
    showNotification: common.showNotification,
  };
})(observer(TeamTableRow));
