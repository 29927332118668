import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    width: '80%',
    height: '100vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const NotFound: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        <Trans i18nKey="not_found_title" />
      </Typography>
      <Typography>
        <Trans i18nKey="not_found_description" />
      </Typography>
    </div>
  );
};

export default NotFound;
