import { action, observable } from 'mobx';

import { TeamUserService } from 'services';
import { AddTeamUserModel, QueryParamModel, TeamUserModel } from 'models';

export class TeamUserStore {
  private readonly teamUserService = new TeamUserService();

  @observable teams: TeamUserModel[] = [];
  @observable teamCount = 0;
  @observable firmwareCount = 0;

  @observable teamLoading = false;
  @observable teamSaving = false;
  @observable teamDeleting = false;

  setTeamId(id: number) {
    this.teamUserService.setTeamId(id);
    return this;
  }

  @action.bound
  async getTeam(params?: QueryParamModel) {
    this.teamLoading = true;

    try {
      const resTeams = await this.teamUserService.getList(params);
      this.teamCount = resTeams.count;
      this.teams = resTeams.data;
      return resTeams;
    } finally {
      this.teamLoading = false;
    }
  }

  @action.bound
  async updateTeam(id: number, data: AddTeamUserModel) {
    this.teamSaving = true;

    try {
      return await this.teamUserService.update(id, data);
    } finally {
      this.teamSaving = false;
    }
  }

  @action.bound
  async addTeam(data: AddTeamUserModel) {
    this.teamSaving = true;

    try {
      return await this.teamUserService.create(data);
    } finally {
      this.teamSaving = false;
    }
  }

  @action.bound
  async deleteTeam(id: number) {
    this.teamDeleting = true;

    try {
      await this.teamUserService.delete(id);
    } finally {
      this.teamDeleting = false;
    }
  }
}
