import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { ApiError, ConfirmDialog } from 'components';
import { ApiErrorModel, NotificationType, TeamModel } from 'models';
import { WithNotification } from 'store';

interface TeamDeleteProps extends WithNotification {
  onSubmit: () => void;
  team: TeamModel;
  deleteTeam: (id: number) => Promise<void>;
}

const TeamDelete: FC<TeamDeleteProps> = ({ onSubmit, team, showNotification, deleteTeam }) => {
  const [error, setError] = useState<ApiErrorModel | null>(null);

  const handleSubmit = async () => {
    try {
      setError(null);

      if (team) {
        await deleteTeam(team.id);
      }

      showNotification(<Trans i18nKey="delete_successful" />, NotificationType.SUCCESS);
      onSubmit();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <ConfirmDialog title={<Trans i18nKey="team_delete" />} onSubmit={handleSubmit}>
      <Trans i18nKey="team_delete_message" values={{ name: team.name }} />
      <ApiError error={error} />
    </ConfirmDialog>
  );
};

export default inject(({ team, common }) => {
  return {
    showNotification: common.showNotification,
    deleteTeam: team.deleteTeam,
  };
})(observer(TeamDelete));
