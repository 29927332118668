import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Dropzone, { DropEvent, DropzoneProps } from 'react-dropzone';

import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fade, makeStyles, Theme } from '@material-ui/core/styles';
import UploadIcon from '@material-ui/icons/CloudUpload';

import { extract } from 'i18n';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px dashed ${fade(theme.palette.secondary.main, 0.2)}`,
      transition: theme.transitions.create(['border', 'background-color'], {
        duration: theme.transitions.duration.complex,
      }),
      height: '50px', //theme.spacing(16),
      width: '100%',
      cursor: 'pointer',
      textAlign: 'center',
      '&:focus': {
        outline: 'none',
      },
      '&:hover, &.active, &.loading, &.loading': {
        borderColor: theme.palette.secondary.main,

        '& $uploadIcon': {
          animationName: '$float',
          animationDuration: '1s',
          animationTimingFunction: 'ease-out',
          animationFillMode: 'forwards',
          animationIterationCount: 'infinite',
        },
      },
      '&.active.accept': {
        backgroundColor: 'red',
        '& .drop': {
          display: 'block',
        },
      },
      '&.active.reject': {
        borderColor: theme.palette.error.main,
        '& .reject': {
          display: 'block',
        },
      },
    },
    uploadIcon: {},
    '@keyframes float': {
      '0%': {
        transform: 'translateY(0px)',
      },
      '50%': {
        transform: 'translateY(-4px)',
      },
      '100%': {
        transform: 'translateY(0px)',
      },
    },
  };
});

enum FileUploadError {
  UNSUPPORTED_FILE_TYPE = 'UNSUPPORTED_FILE_TYPE',
}

export const FileErrorLabelMap: Record<FileUploadError, string> = {
  [FileUploadError.UNSUPPORTED_FILE_TYPE]: extract('file_error_unsupported_file_type'),
};

export interface FileUploadProps extends DropzoneProps {
  loading?: boolean;
}

export const FileUpload: FC<FileUploadProps> = ({
  multiple = false,
  onDropRejected,
  onDropAccepted,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<FileUploadError | null>(null);

  const onRejected = (files: File[], event: DropEvent) => {
    setError(FileUploadError.UNSUPPORTED_FILE_TYPE);

    if (onDropRejected) {
      onDropRejected(files, event);
    }
  };

  const onAccepted = (files: File[], event: DropEvent) => {
    setError(null);

    if (onDropAccepted) {
      onDropAccepted(files, event);
    }
  };

  return (
    <div>
      <Dropzone multiple={multiple} onDropRejected={onRejected} onDropAccepted={onAccepted} {...props}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={classNames(classes.root, {
                active: isDragActive,
                loading: loading,
              })}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <div>
                  <input {...getInputProps()} />
                  <Typography>
                    <Trans i18nKey="file_uploader_placeholder" />
                  </Typography>
                  <UploadIcon fontSize="large" color="secondary" className={classes.uploadIcon} />
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
      <FormHelperText error>{error ? t(FileErrorLabelMap[error]) : null}</FormHelperText>
    </div>
  );
};
