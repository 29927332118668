import React, { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';

import { HealthModel, healthStoreType } from 'models';
import { TableRow, TableCell, TableRowProps, useDialogState } from 'components';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import HealthDialog from './health-dialog.component';
import { DATE_FORMAT, formatDate } from '../../utils/dates';
import { Typography } from '@material-ui/core';

interface HealthTableRowProps extends TableRowProps {
  health: HealthModel;
  loadList: () => void;
}

const HealthTableRow: FC<HealthTableRowProps> = ({ health, loadList, ...props }) => {
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{health.id}</TableCell>
        <TableCell>
          <Typography>{health.userName}</Typography>
          <Typography variant="body2" color="textSecondary">
            {health.userEmail}
          </Typography>
        </TableCell>
        <TableCell>{<Trans i18nKey={healthStoreType(health.type)} />}</TableCell>
        <TableCell>{health.calorie}</TableCell>
        <TableCell>{health.stepCount}</TableCell>
        <TableCell>{formatDate(health.timestamp * 1000, DATE_FORMAT)}</TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={openEditDialog}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <HealthDialog open={editDialogOpen} onClose={closeEditDialog} health={health} onSubmit={loadList} />
    </Fragment>
  );
};

export default HealthTableRow;
