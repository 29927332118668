import React, { Fragment, FC } from 'react';
import { inject, observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import logo from 'assets/images/rebo-logo-180.png';
import config from 'config';
import { RouteConfigurationModel } from 'models';
import { CommonStore } from 'store';

import NavigationItem from './navigation-item.component';

const useStyles = makeStyles((theme: Theme) => {
  return {
    drawer: {
      width: theme.mixins.drawer.width,
      maxWidth: '100%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: theme.mixins.drawer.width,
      maxWidth: '100%',
      overflowX: 'hidden',
    },
    drawerHeader: {
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 2),
    },
    mobileDrawer: {
      [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
        display: 'none',
      },
    },
    desktopDrawer: {
      display: 'none',
      [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
        display: 'block',
      },
    },
    drawerOpen: {
      width: theme.mixins.drawer.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      width: theme.mixins.closedDrawer.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuContainer: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    logo: {
      maxHeight: 48,
    },
  };
});

interface SideBarProps {
  commonStore: CommonStore;
  routes: RouteConfigurationModel[];
}

const SideBar: FC<SideBarProps> = ({ commonStore, routes }) => {
  const classes = useStyles();
  const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up(theme.breakpoints.mobileBreakpoint));

  const content = (
    <Fragment>
      <div className={classes.drawerHeader}>
        <img src={logo} alt="app-logo" className={classes.logo} />
        <div>
          <IconButton onClick={largeScreen ? commonStore.toggleDrawer : commonStore.toggleMobileDrawer}>
            <ClearIcon color="action" />
          </IconButton>
        </div>
      </div>
      <Divider />
      <List className={classes.menuContainer}>
        {routes.map((route) => (
          <NavigationItem
            key={route.path}
            title={route.title}
            link={route.link}
            icon={route.icon}
            allowedFor={route.roles}
          />
        ))}
      </List>
      {commonStore.drawerOpen || !largeScreen ? (
        <Box mt="auto" mb={1} mx={2} display="flex" flexWrap="no-wrap">
          <Box mr={1}>
            <Typography display="inline">
              <Trans i18nKey="version" />
              {':'}
            </Typography>
          </Box>
          <Typography display="inline">{config.REACT_APP_VERSION}</Typography>
        </Box>
      ) : null}
    </Fragment>
  );

  return (
    <Fragment>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, classes.desktopDrawer, {
          [classes.drawerOpen]: commonStore.drawerOpen,
          [classes.drawerClose]: !commonStore.drawerOpen,
        })}
        open={commonStore.drawerOpen}
        onClose={commonStore.toggleDrawer}
        classes={{
          paper: classNames(classes.drawerPaper, {
            [classes.drawerOpen]: commonStore.drawerOpen,
            [classes.drawerClose]: !commonStore.drawerOpen,
          }),
        }}
      >
        {content}
      </Drawer>
      <Drawer
        variant="temporary"
        className={classNames(classes.drawer, classes.mobileDrawer)}
        open={commonStore.mobileDrawerOpen}
        onClose={commonStore.toggleMobileDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {content}
      </Drawer>
    </Fragment>
  );
};

export default inject(({ common }) => ({ commonStore: common }))(observer(SideBar));
