import React, { FC, Fragment, forwardRef } from 'react';
import { Trans } from 'react-i18next';

import MuiTablePagination, {
  LabelDisplayedRowsArgs,
  TablePaginationProps as MuiTablePaginationProps,
} from '@material-ui/core/TablePagination';
import Hidden from '@material-ui/core/Hidden';

import config from 'config';
import { formatNumber } from 'utils/format';

interface TablePaginationProps extends MuiTablePaginationProps<any> {
  innerRef?: React.Ref<any>;
}

function getDisplayedRowsLabel({ from, to, count }: LabelDisplayedRowsArgs) {
  return `${formatNumber(from) || 0} - ${formatNumber(to) || 0} / ${formatNumber(count) || 0}`;
}

const TablePagination: FC<TablePaginationProps> = forwardRef(
  ({ component, labelRowsPerPage, labelDisplayedRows, rowsPerPageOptions, page, count, ...props }, ref) => {
    const adjustedPage = (page || 1) - 1;

    return (
      <MuiTablePagination
        ref={ref}
        rowsPerPageOptions={rowsPerPageOptions || config.ROWS_PER_PAGE_OPTIONS}
        count={count}
        page={!count && adjustedPage > 0 ? 0 : adjustedPage}
        labelRowsPerPage={
          <Hidden xsDown>
            {labelRowsPerPage || (
              <Fragment>
                <Trans i18nKey="rows_per_page" />
                {':'}
              </Fragment>
            )}
          </Hidden>
        }
        labelDisplayedRows={labelDisplayedRows || getDisplayedRowsLabel}
        component={component || 'div'}
        {...props}
      />
    );
  }
);

export default TablePagination;
