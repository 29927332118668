import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(22),
    transition: theme.transitions.create('fill', {
      duration: theme.transitions.duration.shorter,
    }),
    '& *': {
      fill: 'currentColor !important',
    },
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  colorAction: {
    color: theme.palette.action.active,
  },
  colorError: {
    color: theme.palette.error.main,
  },
  colorDisabled: {
    color: theme.palette.action.disabled,
  },
  fontSizeInherit: {
    fontSize: 'inherit',
  },
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(18),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(24),
  },
}));

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

interface IconProps {
  component?: any;
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
  [key: string]: any;
}

const SvgIcon: FC<IconProps> = React.forwardRef(
  ({ component: Component, className, fontSize = 'default', color = 'inherit', ...props }, ref) => {
    const classes = useStyles();

    return (
      <Component
        focusable="false"
        className={classNames(
          classes.root,
          {
            [classes[`color${capitalize(color)}` as keyof ReturnType<typeof useStyles>]]: color !== 'inherit',
            [classes[`fontSize${capitalize(fontSize)}` as keyof ReturnType<typeof useStyles>]]: fontSize !== 'default',
          },
          className
        )}
        {...props}
        ref={ref}
      />
    );
  }
);

export const createSvgIcon: (icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>) => typeof SvgIcon = (
  icon
) => {
  return React.forwardRef((props, ref) => {
    return <SvgIcon component={icon} {...props} ref={ref} />;
  });
};
