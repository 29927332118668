import request, { Methods } from 'utils/request';
import { AddFirmwareDto, ListResponseModel, QueryParamModel, FirmwareModel } from 'models';

export class FirmwareService {
  private readonly resource = 'firmware';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<FirmwareModel>>({ resource: this.resource, params });
  }

  create(data: AddFirmwareDto) {
    return request<FirmwareModel>({ method: Methods.POST, resource: this.resource, data });
  }

  update(id: number, data: AddFirmwareDto) {
    return request<FirmwareModel>({ method: Methods.PUT, resource: `${this.resource}/${id}`, data });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `${this.resource}/${id}` });
  }

  async uploadFirmware(id: number, file: File) {
    const formData = new FormData();
    formData.append('firmwareFile', file);

    return request<File | void>({
      method: Methods.POST,
      resource: `${this.resource}/${id}/upload`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      transformResponse: (data, headers) => {
        return data;
      },
    });
  }
}
