import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'formik';

import FormHelperText from '@material-ui/core/FormHelperText';

import { get } from 'utils/objects';

interface ErrorMessageProps extends Omit<FieldProps, 'meta'> {
  variant?: 'standard' | 'outlined' | 'filled';
  className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ variant, form, field, className }) => {
  const { t } = useTranslation();

  const error = get(form.errors, field.name);
  const touched = get(form.touched, field.name);
  const errorMessage = typeof error === 'string' ? t(error as string) || error || ' ' : ' ';

  return (
    <FormHelperText error variant={variant} className={className}>
      {touched ? errorMessage : ' '}
    </FormHelperText>
  );
};

export default ErrorMessage;
