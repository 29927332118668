import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Button, Dialog, FileUpload, TextField } from 'components';
import { NotificationType, AddFirmwareDto, FirmwareModel, AddFirmwareModel } from 'models';
import { WithNotification } from 'store';
import { Yup } from 'utils/yup';
import { getErrors } from 'utils/request';
import { formatBytes } from 'utils/format';

interface FirmwareDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  firmware?: FirmwareModel;
  addFirmware: (data: AddFirmwareDto) => Promise<any>;
  updateFirmware: (id: number, data: AddFirmwareDto) => Promise<any>;
  firmwareSaving: boolean;
  uploadFile: (id: number, file: File) => Promise<File | any>;
  uploadInProgress: boolean;
}

const validationSchema = Yup.object().shape({
  version: Yup.string()
    .max(20)
    .required(),
  description: Yup.string()
    .min(0)
    .max(255),
  enabled: Yup.boolean().required(),
});

const FirmwareDialog: FC<FirmwareDialogProps> = ({
  open,
  onClose,
  firmware,
  showNotification,
  onSubmit,
  firmwareSaving,
  addFirmware,
  updateFirmware,
  uploadFile,
  uploadInProgress,
}) => {
  const [firmwareFileObject, setFirmwareFileObject] = useState<any | null>(null);

  const getInitialValues = (firmware?: AddFirmwareModel) => {
    let initialValues;
    if (firmware) {
      initialValues = { ...firmware, enabled: Boolean(firmware.enabled), fileUrl: firmware.fileUrl };
    } else {
      initialValues = { version: '', fileUrl: '', description: '', enabled: false } as AddFirmwareModel;
    }
    return initialValues;
  };

  const handleSubmit = async (values: AddFirmwareModel, { setFieldError }: FormikHelpers<AddFirmwareModel>) => {
    try {
      const data = { ...values, enabled: Number(values.enabled) };
      let response: any;
      if (firmware) {
        response = await updateFirmware(firmware.id, data);
      } else {
        response = await addFirmware(data);
      }

      if (response && firmwareFileObject) {
        await uploadFile(response.id, firmwareFileObject);
      }

      showNotification(<Trans i18nKey="save_successful" />, NotificationType.SUCCESS);
      onClose();
      onSubmit();
    } catch (e) {
      getErrors(e);
    }
  };

  const onAccepted = async (files: File[]) => {
    setFirmwareFileObject(files[0]);
  };

  const fileParams = (firmware?: AddFirmwareModel) => {
    if (firmwareFileObject) {
      return (
        <div>
          Uploading file: {firmwareFileObject.name} ({formatBytes(firmwareFileObject.size)})
        </div>
      );
    } else if (firmware?.fileUrl) {
      return (
        <div>
          Uploaded file:{' '}
          <a href={firmware.fileUrl} target="_blank" rel="noopener noreferrer">
            {firmware.fileName}
          </a>
        </div>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={firmware ? <Trans i18nKey="firmware_edit" /> : <Trans i18nKey="firmware_new" />}
      showClose
    >
      <Formik
        initialValues={getInitialValues(firmware)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        <Form>
          <Field
            component={TextField}
            name="version"
            maxLength={20}
            fullWidth
            label={<Trans i18nKey="firmware_version" />}
            margin="normal"
          />
          <Field
            component={TextField}
            name="description"
            multiline
            rows={6}
            rowsMax={6}
            maxLength={255}
            fullWidth
            label={<Trans i18nKey="firmware_description" />}
            margin="normal"
          />
          <FileUpload onDropAccepted={onAccepted} loading={uploadInProgress} />
          {fileParams(firmware)}
          <Field name="enabled">
            {({ field }: FieldProps) => (
              <FormControlLabel
                control={<Switch {...field} checked={Boolean(field.value)} />}
                label={<Trans i18nKey="firmware_is_enabled" />}
              />
            )}
          </Field>
          <Field name="isDefault">
            {({ field }: FieldProps) => (
              <FormControlLabel
                control={<Switch {...field} checked={Boolean(field.value)} />}
                label={<Trans i18nKey="firmware_is_default" />}
              />
            )}
          </Field>
          <Field name="isExperimental">
            {({ field }: FieldProps) => (
              <FormControlLabel
                control={<Switch {...field} checked={Boolean(field.value)} />}
                label={<Trans i18nKey="firmware_is_experimental" />}
              />
            )}
          </Field>
          <Box textAlign="right">
            <Button type="submit" loading={firmwareSaving}>
              <Trans i18nKey="save" />
            </Button>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default inject(({ firmware, common }) => {
  return {
    showNotification: common.showNotification,
    addFirmware: firmware.addFirmware,
    updateFirmware: firmware.updateFirmware,
    firmwareSaving: firmware.firmwareSaving,
    uploadFile: firmware.uploadFile,
    uploadInProgress: firmware.uploadInProgress,
  };
})(observer(FirmwareDialog));
