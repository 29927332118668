import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { ApiError, ConfirmDialog } from 'components';
import { ApiErrorModel, NotificationType, BottleModelModel } from 'models';
import { WithNotification } from 'store';

interface BottleModelDeleteProps extends WithNotification {
  onSubmit: () => void;
  bottleModel: BottleModelModel;
  deleteBottleModel: (id: number) => Promise<void>;
}

const BottleModelDelete: FC<BottleModelDeleteProps> = ({
  onSubmit,
  bottleModel,
  showNotification,
  deleteBottleModel,
}) => {
  const [error, setError] = useState<ApiErrorModel | null>(null);

  const handleSubmit = async () => {
    try {
      setError(null);

      if (bottleModel) {
        await deleteBottleModel(bottleModel.id);
      }

      showNotification(<Trans i18nKey="delete_successful" />, NotificationType.SUCCESS);
      onSubmit();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <ConfirmDialog title={<Trans i18nKey="bottle_model_delete" />} onSubmit={handleSubmit}>
      <Trans i18nKey="bottle_model_delete_message" values={{ name: bottleModel.version }} />
      <ApiError error={error} />
    </ConfirmDialog>
  );
};

export default inject(({ bottleModel, common }) => {
  return {
    showNotification: common.showNotification,
    deleteBottleModel: bottleModel.deleteBottleModel,
  };
})(observer(BottleModelDelete));
