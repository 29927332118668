import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import Tooltip from '@material-ui/core/Tooltip';

import { ConditionalWrapper } from 'components';
import { formatDate, InputDateType } from 'utils/dates';

interface FormattedDateProps {
  date?: InputDateType | null;
  format?: string;
  utc?: boolean;
  utcPostfix?: boolean;
}

const FormattedDate: FC<FormattedDateProps> = ({ date, format, utc = true, utcPostfix }) => {
  return (
    <ConditionalWrapper
      condition={utc}
      wrapper={(children) => (
        <Tooltip
          placement="top"
          title={
            <span>
              <Trans i18nKey="local_time" />
              {': '}
              {formatDate(date, format, { utc: false })}
            </span>
          }
        >
          <span>{children}</span>
        </Tooltip>
      )}
    >
      {formatDate(date, format, { utc })}
      {utc && utcPostfix ? (
        <span>
          {' '}
          (<Trans i18nKey="utc_0" />)
        </span>
      ) : null}
    </ConditionalWrapper>
  );
};

export default FormattedDate;
