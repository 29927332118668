import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Dialog } from 'components';
import { CreateHealthDto, HealthModel, healthStoreType } from 'models';
import { WithNotification } from 'store';
import { ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_TIME_FORMAT, formatDate } from '../../utils/dates';

interface HealthDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  health: HealthModel;
  createHealth: (data: CreateHealthDto) => Promise<void>;
  updateHealth: (id: number, data: CreateHealthDto) => Promise<void>;
  healthSaving: boolean;
}

const styles = makeStyles((theme) => ({
  label: {
    width: '50%',
  },
  value: {
    width: '50%',
    testAlign: 'left',
  },
}));

const HealthDialog: FC<HealthDialogProps> = ({ open, onClose, health }) => {
  const classes = styles();

  return (
    <Dialog open={open} onClose={onClose} title={<Trans i18nKey="health_info" />} showClose>
      <List>
        <ListItem key={'health_id'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="health_id" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{health.id}</ListItemText>
        </ListItem>
        <ListItem key={'health_store_type'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="health_store_type" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{<Trans i18nKey={healthStoreType(health.type)} />}</ListItemText>
        </ListItem>
        <ListItem key={'health_timestamp'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="health_timestamp" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>
            {health.timestamp ? formatDate(Number(health.timestamp) * 1000, DATE_TIME_FORMAT) : ''}
          </ListItemText>
        </ListItem>
        <ListItem key={'health_calorie'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="health_calorie" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{health.calorie} cal</ListItemText>
        </ListItem>
        <ListItem key={'health_user_email'}>
          <ListItemText className={classes.label}>
            <strong>{<Trans i18nKey="health_user_email" />}</strong>
          </ListItemText>
          <ListItemText className={classes.value}>{health.userEmail}</ListItemText>
        </ListItem>
      </List>
    </Dialog>
  );
};

export default inject(({ health, common }) => {
  return {
    showNotification: common.showNotification,
    createHealth: health.createHealth,
    updateHealth: health.updateHealth,
    healthSaving: health.healthSaving,
  };
})(observer(HealthDialog));
