import React, { memo } from 'react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from '@material-ui/pickers/views/Calendar/Day';

export enum DayVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface DayProps {
  children: React.ReactNode;
  current?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  selected?: boolean;
  variant?: DayVariant;
}

const overrideStyles = makeStyles((theme) => ({
  primary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const Day: React.FC<DayProps> = ({ children, disabled, hidden, current, selected, variant, ...other }) => {
  const classes = useStyles();
  const overrideClasses = overrideStyles();

  const className = classNames(classes.day, {
    [classes.hidden]: hidden,
    [classes.current]: current,
    [classes.daySelected]: selected,
    [classes.dayDisabled]: disabled,
    [overrideClasses.primary]: selected && (!variant || variant === DayVariant.PRIMARY),
    [overrideClasses.secondary]: selected && variant === DayVariant.SECONDARY,
  });

  return (
    <IconButton centerRipple className={className} tabIndex={hidden || disabled ? -1 : 0} {...other}>
      {children}
    </IconButton>
  );
};

Day.defaultProps = {
  disabled: false,
  hidden: false,
  current: false,
  selected: false,
};

export default memo(Day);
