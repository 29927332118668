import React, { FC, Ref } from 'react';
import classNames from 'classnames';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MuiSnackbarContent, { SnackbarContentProps as MuiSnackbarContentProps } from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { NotificationType } from 'models';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexWrap: 'nowrap',
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface SnackbarContentProps extends Omit<MuiSnackbarContentProps, 'variant'> {
  message: React.ReactNode;
  onClose: (event: React.SyntheticEvent<any>) => void;
  variant: NotificationType;
}

const SnackbarContent: FC<SnackbarContentProps> = React.forwardRef(
  ({ className, message, onClose, variant, ...props }, ref: Ref<any>) => {
    const classes = useStyles();
    const Icon = variantIcon[variant];

    return (
      <MuiSnackbarContent
        ref={ref}
        className={classNames(classes.root, classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...props}
      />
    );
  }
);

SnackbarContent.defaultProps = {
  variant: NotificationType.INFO,
};

export default SnackbarContent;
