import React, { Component, Fragment } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import {
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  Button,
  SelectSearch,
} from 'components';
import { EventStore } from 'store';

import EventTableRow from './event-table-row.component';
import { DownloadIcon } from '../../assets/icons';
import { MenuItem, Tooltip } from '@material-ui/core';
import { compose } from '../../utils/function';

interface EventListProps extends WithTableQueryParamsProps, WithTranslation {
  eventStore: EventStore;
}

interface EventListState {
  createDialogOpen: boolean;
}

class EventList extends Component<EventListProps, EventListState> {
  readonly state: EventListState = {
    createDialogOpen: false,
  };

  async componentDidMount() {
    await this.loadList();
  }

  async componentDidUpdate(prevProps: Readonly<EventListProps>) {
    if (prevProps.params !== this.props.params) {
      await this.loadList();
    }
  }

  loadList = async () => {
    return await this.props.eventStore.getEvents(this.props.params);
  };

  getExportUrl = () => {
    return this.props.eventStore.getExportUrl(this.props.params);
  };

  render() {
    const { eventStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage, t } = this.props;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3} display="flex" width="100%">
          <Box flex={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <SearchBar
                  name="filter_dateFrom"
                  onSearch={onSearch}
                  initialValue={params.filter_dateFrom}
                  fullWidth
                  label={<Trans i18nKey="event_list_search_date_from" />}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <SearchBar
                  name="filter_dateTo"
                  onSearch={onSearch}
                  initialValue={params.filter_dateTo}
                  fullWidth
                  label={<Trans i18nKey="event_list_search_date_to" />}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" mt={1} mb={2}>
            <Box ml="auto">
              <Tooltip title={<Trans i18nKey="event_export" />}>
                <Link href={this.getExportUrl()}>
                  <Button variant="outlined" icon={DownloadIcon}>
                    <Trans i18nKey="export" />
                  </Button>
                </Link>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box mb={3} display="flex" width="100%">
          <Box flex={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <SearchBar
                  name="filter_nameOrEmail"
                  onSearch={onSearch}
                  initialValue={params.filter_nameOrEmail}
                  fullWidth
                  label={<Trans i18nKey="event_list_search_label" />}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <SelectSearch
                  fullWidth
                  name="filter_type"
                  value={params.filter_type}
                  onSearch={onSearch}
                  label={<Trans i18nKey="event_type" />}
                  showAllOption={true}
                >
                  {Object.entries(['0', '1', '2']).map(([type, label]) => (
                    <MenuItem value={type} key={type}>
                      {t(`event_type_${label}`)}
                    </MenuItem>
                  ))}
                </SelectSearch>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <SearchBar
                  name="filter_minQuantity"
                  onSearch={onSearch}
                  initialValue={params.filter_minQuantity}
                  fullWidth
                  label={<Trans i18nKey="event_min_quantity" />}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <SearchBar
                  name="filter_maxQuantity"
                  onSearch={onSearch}
                  initialValue={params.filter_maxQuantity}
                  fullWidth
                  label={<Trans i18nKey="event_max_quantity" />}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <SelectSearch
                  fullWidth
                  name="filter_manual"
                  value={params.filter_manual}
                  onSearch={onSearch}
                  label={<Trans i18nKey="event_is_manual" />}
                  showAllOption={true}
                >
                  {Object.entries(['0', '1']).map(([type, label]) => (
                    <MenuItem value={type} key={type}>
                      {t(`event_is_manual_${label}`)}
                    </MenuItem>
                  ))}
                </SelectSearch>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={eventStore.eventLoading}
          emptyMessage={<Trans i18nKey="event_list_empty_state_message" />}
          count={eventStore.eventCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <SortableTableCell style={{ width: 50 }} field="id" {...headerParams}>
                  <Trans i18nKey="event_id" />
                </SortableTableCell>
                <SortableTableCell field="userEmail" {...headerParams}>
                  <Trans i18nKey="event_user_name_email" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} field="type" {...headerParams}>
                  <Trans i18nKey="event_type" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} field="quantity" {...headerParams}>
                  <Trans i18nKey="event_quantity" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} field="isManual" {...headerParams}>
                  <Trans i18nKey="event_is_manual" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 150 }} field="timestamp" {...headerParams}>
                  <Trans i18nKey="event_timestamp" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 50 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {eventStore.events?.map((event) => (
                <EventTableRow key={event.id} event={event} loadList={this.loadList} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ event }: StoreModel) => {
  return { eventStore: event };
};

export default compose(
  withTableQueryParams({ sortBy: 'name' }),
  withTranslation(),
  inject(mapStoreToProps),
  observer
)(EventList);
