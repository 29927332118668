import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import {
  TableCell,
  SortableTableCell,
  withTableQueryParams,
  WithTableQueryParamsProps,
  SearchBar,
  TableContainer,
  Button,
} from 'components';
import { SubTeamStore } from 'store';
import SubTeamTableRow from './sub-team-table-row.component';
import SubTeamDialog from './sub-team-dialog.component';
import { SubTeamModel } from '../../../models';

interface SubTeamListProps extends WithTableQueryParamsProps {
  subTeamStore: SubTeamStore;
}

interface SubTeamListState {
  createDialogOpen: boolean;
}

class SubTeamList extends Component<SubTeamListProps, SubTeamListState> {
  readonly state: SubTeamListState = {
    createDialogOpen: false,
  };

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps: Readonly<SubTeamListProps>) {
    if (prevProps.params !== this.props.params) {
      this.loadList();
    }
  }

  loadList = () => {
    return this.props.subTeamStore.getTeam(this.props.params);
  };

  openCreateDialog = () => {
    this.setState({ createDialogOpen: true });
  };

  closeCreateDialog = () => {
    this.setState({ createDialogOpen: false });
  };

  render() {
    const { subTeamStore, params, onSearch, onSort, onChangeRowsPerPage, onChangePage } = this.props;
    const { createDialogOpen } = this.state;

    const headerParams = {
      order: params.order,
      sortBy: params.sortBy,
      onSort: onSort,
    };

    return (
      <Fragment>
        <Box mb={3}>
          <Box display="flex" alignItems="center">
            <Box mr={2} flex={1}>
              <SearchBar
                name="filter_name"
                onSearch={onSearch}
                initialValue={params.filter_name}
                fullWidth
                label={<Trans i18nKey="team_list_search_label" />}
              />
            </Box>
            <Box ml="auto">
              <Button variant="outlined" icon={AddIcon} onClick={this.openCreateDialog}>
                <Trans i18nKey="add" />
              </Button>
              <SubTeamDialog open={createDialogOpen} onClose={this.closeCreateDialog} onSubmit={this.loadList} />
            </Box>
          </Box>
        </Box>
        <TableContainer
          responsive
          loading={subTeamStore.teamLoading}
          emptyMessage={<Trans i18nKey="team_list_empty_state_message" />}
          count={subTeamStore.teamCount}
          pagination={{
            pageSize: params.pageSize,
            page: params.page,
            onChangePage,
            onChangeRowsPerPage,
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <SortableTableCell style={{ width: 50 }} field="id" {...headerParams}>
                  <Trans i18nKey="team_id" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} field="name" {...headerParams}>
                  <Trans i18nKey="team_name" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 100 }} field="code" {...headerParams}>
                  <Trans i18nKey="team_code" />
                </SortableTableCell>
                <SortableTableCell style={{ width: 50 }} field="createdAt" {...headerParams}>
                  <Trans i18nKey="team_added_date" />
                </SortableTableCell>
                <TableCell style={{ width: 40 }} />
                <TableCell style={{ width: 40 }} />
                <TableCell style={{ width: 40 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {subTeamStore.teams
                ?.map((team) => {
                  return {
                    id: team.id,
                    name: team.name,
                    code: `${team.team?.code}${team.code}`,
                    createdAt: team.createdAt,
                  } as SubTeamModel;
                })
                .map((team) => (
                  <SubTeamTableRow key={team.id} team={team} loadList={this.loadList} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
}

const mapStoreToProps = ({ subTeam }: StoreModel) => {
  return { subTeamStore: subTeam };
};

export default withTableQueryParams({ sortBy: 'name' })(inject(mapStoreToProps)(observer(SubTeamList)));
