import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { ApiError, ConfirmDialog } from 'components';
import { ApiErrorModel, NotificationType, NotificationsModel } from 'models';
import { WithNotification } from 'store';

interface NotificationsDeleteProps extends WithNotification {
  onSubmit: () => void;
  notifications: NotificationsModel;
  deleteNotifications: (id: number) => Promise<void>;
}

const NotificationsDelete: FC<NotificationsDeleteProps> = ({
  onSubmit,
  notifications,
  showNotification,
  deleteNotifications,
}) => {
  const [error, setError] = useState<ApiErrorModel | null>(null);

  const handleSubmit = async () => {
    try {
      setError(null);

      if (notifications) {
        await deleteNotifications(notifications.id);
      }

      showNotification(<Trans i18nKey="delete_successful" />, NotificationType.SUCCESS);
      onSubmit();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <ConfirmDialog title={<Trans i18nKey="delete_notifications" />} onSubmit={handleSubmit}>
      <Trans i18nKey="notifications_delete_message" values={{ title: notifications.title }} />
      <ApiError error={error} />
    </ConfirmDialog>
  );
};

export default inject(({ notifications, common }) => {
  return {
    showNotification: common.showNotification,
    deleteNotifications: notifications.deleteNotifications,
  };
})(observer(NotificationsDelete));
