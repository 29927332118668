import React, { FC } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper(children: React.ReactNode): React.ReactNode;
}

const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) => {
  return condition ? <>{wrapper(children)}</> : <>{children}</>;
};

export default ConditionalWrapper;
