import React, { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ProfileIcon from '@material-ui/icons/Person';

import { Menu } from 'components';
import { ArrowDownIcon } from 'assets/icons';
import { ProfileModel } from 'models';

const useStyles = makeStyles((theme: Theme) => {
  return {
    userDetail: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      '&:focus': {
        outline: 'none',
      },
    },
    dividerIcon: {
      fontSize: 10,
      color: theme.palette.text.hint,
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
  };
});

interface UserMenuProps {
  profile: ProfileModel;
  logout: () => Promise<void>;
}

const UserMenu: FC<UserMenuProps> = ({ profile, logout }) => {
  const classes = useStyles();

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Fragment>
      <Menu
        keepMounted
        items={[
          profile ? (
            <Box className={classes.userDetail} key="user_detail">
              <Typography variant="h6" align="center">
                {profile.name}
              </Typography>

              <Box display="flex" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  {profile.email}
                </Typography>
              </Box>
            </Box>
          ) : null,

          profile ? <Divider key="divider" /> : null,

          <MenuItem key="logout" onClick={handleLogout} className={classes.menuItem}>
            <Box color="text.secondary" mr={2} display="flex" alignItems="center">
              <ExitToApp />
            </Box>
            <Trans i18nKey="logout" />
          </MenuItem>,
        ]}
      >
        {({ onClick }) => (
          <Button onClick={onClick} color="inherit">
            {profile ? (
              <Fragment>
                <Box mr={1} display="flex" alignItems="center">
                  <ProfileIcon fontSize="small" />
                </Box>
                <Hidden xsDown>
                  <Box mr={1}>
                    <Typography variant="body2">{profile.name}</Typography>
                  </Box>
                </Hidden>
              </Fragment>
            ) : null}
            <ArrowDownIcon color="inherit" fontSize="small" />
          </Button>
        )}
      </Menu>
    </Fragment>
  );
};

export default UserMenu;
