import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from 'components';
import TeamUserList from './team-user-list.component';

const TeamUser: FC = () => {
  const { id = 0 } = useParams();
  return (
    <Page title={<Trans i18nKey="team users" />}>
      <TeamUserList id={id as number} />
    </Page>
  );
};

export default TeamUser;
