import React, { FC, Fragment } from 'react';

import { NotificationsModel } from 'models';
import { TableRow, TableCell, TableRowProps, useDialogState } from 'components';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from 'assets/icons';

import NotificationsDialog from './notifications-dialog.component';
import NotificationsDelete from './notifications-delete.component';

interface NotificationsTableRowProps extends TableRowProps {
  notifications: NotificationsModel;
  loadList: () => void;
}

const NotificationsTableRow: FC<NotificationsTableRowProps> = ({ notifications, loadList, ...props }) => {
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{notifications.id}</TableCell>
        <TableCell>{notifications.title}</TableCell>
        <TableCell>{notifications.message}</TableCell>
        <TableCell>{notifications.type}</TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={openEditDialog}>
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <NotificationsDelete notifications={notifications} onSubmit={loadList} />
        </TableCell>
      </TableRow>

      <NotificationsDialog
        open={editDialogOpen}
        onClose={closeEditDialog}
        notifications={notifications}
        onSubmit={loadList}
      />
    </Fragment>
  );
};

export default NotificationsTableRow;
