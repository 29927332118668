import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import Box from '@material-ui/core/Box';

import { NotificationType, UserDetailModel } from 'models';
import { WithNotification } from 'store';
import { Button, DetailRow, SectionTitle, FormattedDate } from 'components';
import { DATE_FORMAT } from 'utils/dates';

interface GeneralUserInformationProps extends WithNotification {
  user: UserDetailModel;
  passwordResetInProgress: boolean;
  resetPassword: (id: number, email: string) => Promise<void>;
}

const GeneralUserInformation: FC<GeneralUserInformationProps> = ({
  user,
  passwordResetInProgress,
  resetPassword,
  showNotification,
}) => {
  const onResetPassword = async () => {
    await resetPassword(user.id, user.email);

    showNotification(<Trans i18nKey="user_detail_reset_password_success" />, NotificationType.SUCCESS);
  };
  const gender = <Trans i18nKey={`gender_${user.gender}`} />;

  return (
    <div>
      <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
        <SectionTitle title={<Trans i18nKey="user_detail_general_information" />} />
        <Button loading={passwordResetInProgress} onClick={onResetPassword}>
          <Trans i18nKey="user_detail_reset_password" />
        </Button>
      </Box>
      <DetailRow label={<Trans i18nKey="name" />} value={user.name} />
      <DetailRow label={<Trans i18nKey="email" />} value={user.email} />
      <DetailRow label={<Trans i18nKey="gender" />} value={gender} />
      <DetailRow
        label={<Trans i18nKey="registration_date" />}
        value={<FormattedDate date={user.createdAt} format={DATE_FORMAT} />}
      />
    </div>
  );
};

export default inject(({ users, common }) => ({
  passwordResetInProgress: users.passwordResetInProgress,
  resetPassword: users.resetPassword,
  showNotification: common.showNotification,
}))(observer(GeneralUserInformation));
