import React, { FC, forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { Image, ImageZoomProvider, ImageZoomContent } from 'components';
import NoImagePlaceholderSrc from 'assets/images/no-image-available.jpg';

import { Stepper } from './stepper.component';

const useStyles = makeStyles(() => ({
  imageContainer: {
    width: '100%',
    maxHeight: 'calc(100% - 48px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    maxHeight: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

interface ImageCarouselProps<Image = Record<string, any>> {
  images: Image[];
  urlKey: keyof Image;
  altExtractor?: (image: Image, index: number) => string;
  variant?: 'text' | 'dots' | 'progress';
  zoom?: boolean;
  showLoader?: boolean;
  imageHeight?: number;
}

export const ImageCarousel: FC<ImageCarouselProps & { ref?: Ref<HTMLDivElement> }> = forwardRef(
  ({ images, urlKey, altExtractor, variant, zoom = false, imageHeight, showLoader }, ref: Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);

    const maxSteps = images.length;
    const showStepper = maxSteps > 1;
    const hasImage = Boolean(images && images.length && images[activeStep]);
    const currentSrc = hasImage ? images[activeStep][urlKey] : NoImagePlaceholderSrc;
    const currentAlt = hasImage
      ? altExtractor
        ? altExtractor(images[activeStep], activeStep + 1)
        : ''
      : t('no_image_available');

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return zoom ? (
      <ImageZoomProvider>
        {(props) => (
          <div className={classes.root} ref={ref}>
            <div className={classes.imageContainer}>
              <ImageZoomContent {...props}>
                <Image src={currentSrc} alt={currentAlt} height={imageHeight} showLoader={showLoader} />
              </ImageZoomContent>
            </div>
            {showStepper ? (
              <Stepper
                maxSteps={maxSteps}
                activeStep={activeStep}
                variant={variant}
                handleBack={() => {
                  handleBack();
                  props.setDefaultState();
                }}
                handleNext={() => {
                  handleNext();
                  props.setDefaultState();
                }}
              />
            ) : null}
          </div>
        )}
      </ImageZoomProvider>
    ) : (
      <div className={classes.root} ref={ref}>
        <div className={classes.imageContainer}>
          <Image src={currentSrc} alt={currentAlt} height={imageHeight} showLoader={showLoader} />
        </div>
        {showStepper ? (
          <Stepper
            maxSteps={maxSteps}
            activeStep={activeStep}
            variant={variant}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        ) : null}
      </div>
    );
  }
);
