import React, { FC, Fragment } from 'react';
import { WorkoutEventModel } from 'models';
import { TableRow, TableCell, TableRowProps } from 'components';
import { DATE_FORMAT, formatDate, TIME_FORMAT } from '../../utils/dates';
import { Typography } from '@material-ui/core';

interface WorkoutEventTableRowProps extends TableRowProps {
  workoutEvent: WorkoutEventModel;
  loadList: () => void;
}

const WorkoutEventTableRow: FC<WorkoutEventTableRowProps> = ({ workoutEvent, loadList, ...props }) => {
  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{workoutEvent.id}</TableCell>
        <TableCell>
          <Typography>{workoutEvent.userName}</Typography>
          <Typography variant="body2" color="textSecondary">
            {workoutEvent.userEmail}
          </Typography>
        </TableCell>
        <TableCell>{workoutEvent.activityName}</TableCell>
        <TableCell>
          {formatDate(workoutEvent.startTimestamp * 1000, DATE_FORMAT)}
          <br />
          {formatDate(workoutEvent.startTimestamp * 1000, TIME_FORMAT)}
        </TableCell>
        <TableCell>
          {formatDate(workoutEvent.endTimestamp * 1000, DATE_FORMAT)}
          <br />
          {formatDate(workoutEvent.endTimestamp * 1000, TIME_FORMAT)}
        </TableCell>
        <TableCell>{workoutEvent.energyBurnedInKcal}</TableCell>
        <TableCell>{workoutEvent.distanceCoveredInMeters}</TableCell>
        <TableCell>{workoutEvent.flightsClimbed}</TableCell>
        <TableCell>{workoutEvent.swimmingStrokes}</TableCell>
        <TableCell>{workoutEvent.stepCountDelta}</TableCell>
        <TableCell>{workoutEvent.activityDurationInMs}</TableCell>
      </TableRow>
    </Fragment>
  );
};

export default WorkoutEventTableRow;
