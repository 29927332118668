import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { AccessController, AccessRestriction, ConditionalWrapper } from 'components';

export interface NavigationItemProps extends AccessRestriction {
  icon: React.ComponentType<SvgIconProps>;
  link: string;
  title: React.ReactNode;
  toggleMobileDrawer: () => void;
  drawerOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2) - theme.shape.activeBorderWidth,
      borderLeft: `${theme.shape.activeBorderWidth}px solid transparent`,
      transition: theme.transitions.create('border-left-color'),
      '&.active': {
        borderLeftColor: theme.palette.primary.main,
        backgroundColor: theme.palette.action.hover,
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
  };
});

const NavigationItem: FC<NavigationItemProps> = ({
  icon: Icon,
  link,
  title,
  allowedFor,
  toggleMobileDrawer,
  drawerOpen,
}) => {
  const classes = useStyles();
  const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up(theme.breakpoints.mobileBreakpoint));

  return (
    <AccessController allowedFor={allowedFor}>
      <ConditionalWrapper
        condition={largeScreen && !drawerOpen}
        wrapper={(children) => (
          <Tooltip title={title} placement="right">
            <div>{children}</div>
          </Tooltip>
        )}
      >
        <ListItem
          button
          component={NavLink}
          to={link}
          className={classes.root}
          onClick={!largeScreen ? toggleMobileDrawer : undefined}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={title} primaryTypographyProps={{ noWrap: true }} />
        </ListItem>
      </ConditionalWrapper>
    </AccessController>
  );
};

export default inject(({ common }) => ({
  toggleMobileDrawer: common.toggleMobileDrawer,
  drawerOpen: common.drawerOpen,
}))(observer(NavigationItem));
