import { RoleModel } from 'models';

export enum GenderType {
  NOT_SET = 0,
  MALE = 1,
  FEMALE = 2,
  NON_BINARY = 3,
}

export interface BaseUserModel {
  id: number;
  email: string;
  name: string;
  gender: GenderType;
  uuid: string;
}

export interface ProfileModel extends BaseUserModel {
  notification: boolean;
  termsAndConditions: {
    isAccepted: boolean;
    acceptedAt: Date | null;
  };
  roles: RoleModel[];
}

export interface UserModel extends BaseUserModel {
  country: any;
  createdAt: string;
  lastLoginAt: string;
  bottlesSaved: number;
  bottlesCollected: number;
  additionalBottlesCollected: number;
}

export interface UserDetailModel extends UserModel {
  roles: RoleModel[];
}

export interface UserExtendedModel extends UserDetailModel {
  dailyStreak: number;
  dailyCap: number;
  customHydrationGoal: number;
}
