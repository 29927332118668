import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';
import { fade, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingOverlayProps {
  show?: boolean;
  className?: string;
  style?: CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    zIndex: 1000,
    background: fade(theme.palette.common.white, 0.9),
  },
}));

const LoadingOverlay: FC<LoadingOverlayProps> = ({ show, className, style }) => {
  const classes = useStyles();

  return show ? (
    <div className={classNames(className, classes.root)} style={style}>
      <CircularProgress />
    </div>
  ) : null;
};

export default LoadingOverlay;
