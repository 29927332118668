import React, { FC, Fragment } from 'react';

import { LogsModel } from 'models';
import { TableRow, TableCell, TableRowProps } from 'components';
import IconButton from '@material-ui/core/IconButton';

import { Typography } from '@material-ui/core';
import { generateUrl } from '../../utils/request';
import SaveIcon from '@material-ui/icons/Save';

interface LogTableRowProps extends TableRowProps {
  log: LogsModel;
  loadList: () => void;
}

const LogTableRow: FC<LogTableRowProps> = ({ log, loadList, ...props }) => {
  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>
          {log.user ? (
            <>
              <Typography>{log.user.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {log.user.email}
              </Typography>
            </>
          ) : (
            <Typography>{log.userId}</Typography>
          )}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>{log.date}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{log.fileName}</TableCell>
        <TableCell padding="checkbox">
          <IconButton
            onClick={() => {
              window.open(
                generateUrl({
                  resource: `google-firebase/download/`,
                  params: { path: encodeURIComponent(log.filePath) },
                })
              );
            }}
          >
            <SaveIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default LogTableRow;
