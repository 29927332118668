import React, { FC } from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { SearchConditions } from 'components';
import config from 'config';

interface SelectSearchProps {
  onSearch: (searchConditions: SearchConditions) => void;
  showAllOption?: boolean;
  name: string;
}

export const ALL_OPTION = '__all__';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    maxWidth: 600,
  },
}));

export const SelectSearch: FC<SelectSearchProps & TextFieldProps> = ({
  children,
  value,
  onSearch,
  className,
  variant = config.DEFAULT_INPUT_VARIANT as any,
  showAllOption = true,
  ...props
}) => {
  const classes = useStyles();

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    onSearch({ name, value: value === ALL_OPTION ? '' : value });
  };

  return (
    <TextField
      select
      // value={value === null || value === undefined || value === '' ? (showAllOption ? ALL_OPTION : null) : value}
      value={value === null || value === undefined || value === '' ? (showAllOption ? ALL_OPTION : null) : value}
      onChange={handleChange}
      className={classNames(className, classes.root)}
      variant={variant}
      {...props}
    >
      {showAllOption ? (
        <MenuItem value={ALL_OPTION}>
          <Trans i18nKey="all" />
        </MenuItem>
      ) : null}
      {children}
    </TextField>
  );
};
