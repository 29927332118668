import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import { SnackbarContent } from 'components';
import { NotificationModel } from 'models';
import { inject, observer } from 'mobx-react';

const NOTIFICATION_DURATION = 6000;

interface NotificationProps {
  hideNotification: () => void;
  notification: NotificationModel | null;
  autoHideDuration?: number;
}

interface NotificationState {
  open: boolean;
}

class Notification extends Component<NotificationProps, NotificationState> {
  static readonly defaultProps: Partial<NotificationProps> = {
    autoHideDuration: NOTIFICATION_DURATION,
  };

  readonly state: NotificationState = {
    open: Boolean(this.props.notification),
  };

  timeout: number | null = null;

  componentDidUpdate(prevProps: Readonly<NotificationProps>, prevState: Readonly<NotificationState>) {
    const { notification } = this.props;

    if (!prevProps.notification && notification) {
      this.open();
    }

    if (
      prevProps.notification &&
      notification &&
      !prevProps.notification.content !== notification.content &&
      this.timeout
    ) {
      this.restartTimeout();
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  open = () => {
    this.setState({ open: true });
    this.startTimeout();
  };

  close = () => {
    this.setState({ open: false });
    this.clearTimeout();
  };

  onClose = (event: React.SyntheticEvent<any>, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    this.close();
  };

  startTimeout = () => {
    this.timeout = setTimeout(this.onClose, this.props.autoHideDuration);
  };

  clearTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  };

  restartTimeout = () => {
    this.clearTimeout();
    this.startTimeout();
  };

  onMouseEnter = () => {
    this.clearTimeout();
  };

  onMouseLeave = () => {
    this.startTimeout();
  };

  render() {
    const { notification, hideNotification } = this.props;
    const { open } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onExited={hideNotification}
        onClose={this.onClose}
        TransitionComponent={Slide as any}
      >
        {notification ? (
          <SnackbarContent variant={notification.type} message={notification.content} onClose={this.onClose} />
        ) : null}
      </Snackbar>
    );
  }
}

export default inject(({ common }) => ({
  notification: common.notification,
  hideNotification: common.hideNotification,
}))(observer(Notification));
