import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import Box from '@material-ui/core/Box';

import { Button, Dialog, TextField } from 'components';
import { CreateNotificationsModel, NotificationType, NotificationsModel } from 'models';
import { WithNotification } from 'store';
import { Yup } from 'utils/yup';
import { getErrors } from 'utils/request';
import { createStyles, FormControl, InputLabel, Select, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface NotificationsDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  notifications?: NotificationsModel;
  createNotifications: (data: { id?: number; title: string; message: string; type: string | number }) => Promise<any>;
  updateNotifications: (
    id: number,
    data: { id?: number; title: string; message: string; type: string | number }
  ) => Promise<any>;
  notificationsSaving: boolean;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  message: Yup.string()
    .min(10)
    .max(150)
    .required(),
  type: Yup.string().required(),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
    img: {
      width: '100%',
    },
    fileUpload: {
      height: '50px',
    },
    formControl: {
      margin: '8px 0',
      width: '100%',
    },
  })
);

const NotificationsDialog: FC<NotificationsDialogProps> = ({
  open,
  onClose,
  notifications,
  showNotification,
  onSubmit,
  notificationsSaving,
  createNotifications,
  updateNotifications,
}) => {
  const classes = useStyles();

  const getInitialValues = (notifications?: CreateNotificationsModel) => {
    let initialValues;
    if (notifications) {
      initialValues = { ...notifications };
    } else {
      initialValues = { title: '', message: '', type: 'REMINDER' } as CreateNotificationsModel;
    }
    return initialValues;
  };

  const handleSubmit = async (values: CreateNotificationsModel) => {
    try {
      const data = { ...values, type };
      if (notifications) {
        await updateNotifications(notifications.id, data);
      } else {
        await createNotifications(data);
      }

      showNotification(<Trans i18nKey="save_successful" />, NotificationType.SUCCESS);
      onClose();
      onSubmit();
    } catch (e) {
      getErrors(e);
    }
  };

  const [type, setType] = React.useState<string | number>('');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={notifications ? <Trans i18nKey="edit_notifications" /> : <Trans i18nKey="notification_notifications" />}
      showClose
    >
      <Formik
        initialValues={getInitialValues(notifications)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        <Form>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              <Trans i18nKey="notifications_type" />
            </InputLabel>
            <Select
              native
              id="type"
              name="type"
              value={type}
              onChange={handleChange}
              label={<Trans i18nKey="notifications_type" />}
              inputProps={{
                name: 'type',
                id: 'type-simple',
              }}
            >
              {['REMINDER', 'INTAKE', 'DRINK_MORE', 'TENK_BOTTLES_DRINKED'].map((type) => (
                <option value={type} key={type}>
                  {type}
                  {/*<Trans i18nKey={'notifications_type_' + type}/>*/}
                </option>
              ))}
            </Select>
          </FormControl>
          <Field
            component={TextField}
            name="title"
            fullWidth
            label={<Trans i18nKey="notifications_title" />}
            margin="normal"
          />
          <Field
            component={TextField}
            name="message"
            multiline
            rows={6}
            rowsMax={6}
            maxLength={150}
            fullWidth
            label={<Trans i18nKey="notifications_message" />}
            margin="normal"
          />
          <Box textAlign="right">
            <Button type="submit" loading={notificationsSaving}>
              <Trans i18nKey="save" />
            </Button>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default inject(({ notifications, common }) => {
  return {
    showNotification: common.showNotification,
    createNotifications: notifications.createNotifications,
    updateNotifications: notifications.updateNotifications,
    notificationsSaving: notifications.notificationsSaving,
  };
})(observer(NotificationsDialog));
