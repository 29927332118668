import { createMuiTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';

import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';

const shadows: string[] = [];

const defaultTheme = createMuiTheme();


//https://mui.com/material-ui/
const theme = createMuiTheme({
  mixins: {
    drawer: {
      width: 260,
    },
    closedDrawer: {
      width: 56,
    },
  },
  breakpoints: {
    mobileBreakpoint: 'md',
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [],
      },
    },
    MuiDialogActions: {
      root: {
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
        paddingBottom: defaultTheme.spacing(2),
      },
    },
    MuiTableCell: {
      head:{
          fontWeight: 'bold',
      }
    },
    MuiTablePagination: {
      toolbar: {
        paddingLeft: defaultTheme.spacing(2),
        paddingRight: defaultTheme.spacing(2),
        paddingTop: defaultTheme.spacing(1),
        paddingBottom: defaultTheme.spacing(1),
      },
    },
  },
  palette: {
    action: {
      hoverDark: 'rgba(255, 255, 255, 0.1)',
    },
    primary: defaultTheme.palette.augmentColor({ main: '#2F4259' }),
    secondary: defaultTheme.palette.augmentColor({ main: '#1EA2B1' }),
    success: defaultTheme.palette.augmentColor({ main: green[600] }),
    warning: defaultTheme.palette.augmentColor({ main: amber[700] }),
    dark: defaultTheme.palette.augmentColor({ main: '#333333', contrastText: '#cccccc' }),
  },
  defaultSpacing: 3,
  shape: {
    activeBorderWidth: 3,
  },
  shadows: defaultTheme.shadows.map((s, i) => shadows[i] || s) as Shadows,
  typography: {
    fontFamily: ['Helvetica', 'Arial', 'sans-serif'].join(','),
    h6: {
      fontWeight: 'bold',
    },
  },
});

// Let the theme object be available quickly during development
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log(theme);
}

export default theme;
