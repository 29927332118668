import React, { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';

import Chip from '@material-ui/core/Chip';

import { FirmwareModel } from 'models';
import { TableRow, TableCell, TableRowProps, useDialogState } from 'components';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from 'assets/icons';

import FirmwareDialog from './firmware-dialog.component';
import FirmwareDelete from './firmware-delete.component';
import { DATE_FORMAT, formatDate } from '../../utils/dates';

interface FirmwareTableRowProps extends TableRowProps {
  firmware: FirmwareModel;
  loadList: () => void;
}

const FirmwareTableRow: FC<FirmwareTableRowProps> = ({ firmware, loadList, ...props }) => {
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{firmware.id}</TableCell>
        <TableCell>{firmware.version}</TableCell>
        <TableCell>{firmware.description}</TableCell>
        <TableCell>
          <a target="_blank" rel="noopener noreferrer" href={firmware.fileUrl}>
            {firmware.fileName}
          </a>
        </TableCell>
        <TableCell>{formatDate(firmware.createdAt * 1000, DATE_FORMAT)}</TableCell>
        <TableCell>
          {firmware.enabled ? <Chip color="primary" size="small" label={<Trans i18nKey="firmware_enabled" />} /> : null}
        </TableCell>
        <TableCell>
          {firmware.isDefault ? <Chip color="secondary" size="small" label={<Trans i18nKey="firmware_default" />} /> : null}
        </TableCell>
        <TableCell>
          {firmware.isExperimental ? <Chip color="secondary" size="small" label={<Trans i18nKey="firmware_experimental" />} /> : null}
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={openEditDialog}>
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <FirmwareDelete firmware={firmware} onSubmit={loadList} />
        </TableCell>
      </TableRow>

      <FirmwareDialog open={editDialogOpen} onClose={closeEditDialog} firmware={firmware} onSubmit={loadList} />
    </Fragment>
  );
};

export default FirmwareTableRow;
