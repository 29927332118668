import React, { FC, memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MuiPickersContext } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  daysHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dayLabel: {
    width: 36,
    height: 40,
    margin: '0 2px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.hint,
  },
}));

const DaysHeader: FC = () => {
  const classes = useStyles();
  const utils = useContext(MuiPickersContext);
  const weekDays = utils ? utils.getWeekdays().map((day, i) => ({ day, key: i + 1 })) : [];

  return (
    <div className={classes.daysHeader}>
      {weekDays.map(({ day, key }) => (
        <Typography key={key} variant="caption" className={classes.dayLabel}>
          {day}
        </Typography>
      ))}
    </div>
  );
};

export default memo(DaysHeader);
