import React, { useContext, useCallback, useMemo, memo } from 'react';
import { IUtils } from '@date-io/core/IUtils';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { MuiPickersContext } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { formatDate, DateType } from 'utils/dates';

import DaysHeader from './days-header.component';
import Day, { DayVariant } from './day.component';
import DayWrapper from './day-wrapper.component';
export { DayVariant } from './day.component';

export interface SelectedDate {
  date: DateType;
  variant: DayVariant;
}

export interface CalendarProps {
  selected: SelectedDate | SelectedDate[];
  onSelect?: (e: React.SyntheticEvent, date: DateType | null) => void;
  currentMonth: DateType;
}

const useStyles = makeStyles(() => ({
  monthTitleContainer: {
    flex: 1,
    display: 'flex',
    maxHeight: 30,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  week: {
    display: 'flex',
    justifyContent: 'center',
  },
  daysContainer: {
    overflow: 'hidden',
  },
}));

const FORMAT = {
  MONTH: 'MMMM',
  DAY: 'D',
};

export const Calendar: React.FC<CalendarProps> = memo(({ onSelect, currentMonth, selected }) => {
  const utils = useContext(MuiPickersContext as React.Context<IUtils<MaterialUiPickersDate>>);
  const classes = useStyles();

  const now = utils.date();

  const handleDaySelect = useCallback(
    (e: React.SyntheticEvent, day: MaterialUiPickersDate) => {
      if (onSelect) {
        onSelect(e, day);
      }
    },
    [onSelect]
  );

  const selectedDates = useMemo(() => (Array.isArray(selected) ? selected : [selected]), [selected]);
  const currentMonthNumber = useMemo(() => utils.getMonth(currentMonth), [utils, currentMonth]);

  return (
    <div>
      <div className={classes.monthTitleContainer}>
        <Typography align="center" variant="subtitle1">
          {formatDate(currentMonth, FORMAT.MONTH, { utc: false })}
        </Typography>
      </div>
      <DaysHeader />

      <div className={classes.daysContainer}>
        {utils.getWeekArray(currentMonth).map((week, index) => {
          const key = week && week[0] ? week[0].week() : index;

          return (
            <div key={key} className={classes.week}>
              {week.map((day) => {
                const isDayInCurrentMonth = utils.getMonth(day) === currentMonthNumber;
                const date = selectedDates.find((selected) => utils.isSameDay(selected.date, day));

                return day ? (
                  <DayWrapper
                    key={day.day()}
                    value={day}
                    dayInCurrentMonth={isDayInCurrentMonth}
                    onSelect={handleDaySelect}
                  >
                    <Day
                      current={utils.isSameDay(day, now)}
                      variant={date?.variant}
                      hidden={!isDayInCurrentMonth}
                      selected={Boolean(date)}
                    >
                      {formatDate(day, FORMAT.DAY, { utc: false })}
                    </Day>
                  </DayWrapper>
                ) : null;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
});
