export interface RoleModel {
  id: number;
  displayName: string;
  name: Role;
}

export enum Role {
  USER = 'user',
  OPERATOR = 'operator',
  ADMIN = 'admin',
  CUSTOMER_ADMIN = 'customerAdmin',
}

export const FixedRoles = [Role.USER];
