import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  return {
    text: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
});

const DetailRowTitle: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box mr={1} display="flex" alignItems="center">
      <Typography variant="subtitle1" display="inline" gutterBottom={false} className={classes.text}>
        {children}
      </Typography>
    </Box>
  );
};

export default DetailRowTitle;
