import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Page } from 'components';
import TeamList from './team-list.component';

const Teams: FC = () => {
  return (
    <Page title={<Trans i18nKey="teams" />}>
      <TeamList />
    </Page>
  );
};

export default Teams;
