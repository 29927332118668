import { RoleModel } from 'models';
import request from 'utils/request';

export class RoleService {
  private readonly resource = 'admin/roles';

  getRoles() {
    return request<RoleModel[]>({ resource: this.resource });
  }
}
