import React, { FC, Fragment, useState } from 'react';
import { Trans } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { DeleteIcon } from 'assets/icons';
import { useDialogState, Dialog, Button } from 'components';

interface ConfirmDialogProps {
  title: React.ReactNode;
  button?: React.ReactNode;
  confirmTitle?: React.ReactNode;
  cancelTitle?: React.ReactNode;
  icon?: React.ComponentType<SvgIconProps>;
  edge?: 'start' | 'end' | false;
  onSubmit?: () => any;
  loading?: boolean;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  button,
  icon: Icon,
  edge,
  children,
  onSubmit,
  loading: loadingProp,
  confirmTitle,
  cancelTitle,
}) => {
  const [open, handleOpen, handleClose] = useDialogState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (onSubmit) {
      setLoading(true);

      try {
        await onSubmit();
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  return (
    <Fragment>
      {React.isValidElement(button) ? (
        React.cloneElement(button, {
          onClick: (e: React.MouseEvent<any>) => {
            if (button.props.onClick) {
              button.props.onClick(e);
            }
            handleOpen();
          },
        })
      ) : (
        <IconButton onClick={handleOpen} edge={edge}>
          {Icon ? <Icon fontSize="small" /> : <DeleteIcon fontSize="small" />}
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        title={title}
        actions={
          <>
            <Box mr={2}>
              <Button variant="outlined" color="secondary" onClick={handleClose} disabled={loading || loadingProp}>
                {cancelTitle || <Trans i18nKey="cancel" />}
              </Button>
            </Box>
            <Button onClick={handleSubmit} loading={loading || loadingProp}>
              {confirmTitle || <Trans i18nKey="delete" />}
            </Button>
          </>
        }
      >
        <Typography>{children}</Typography>
      </Dialog>
    </Fragment>
  );
};
