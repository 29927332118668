import React, { FC, Fragment } from 'react';

import { TeamModel } from 'models';
import { TableRow, TableCell, TableRowProps, useDialogState } from 'components';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from 'assets/icons';

import TeamDialog from './team-dialog.component';
import TeamDelete from './team-delete.component';
import { DTO_DATE_TIME_FORMAT, formatDate } from '../../../utils/dates';

interface TeamTableRowProps extends TableRowProps {
  team: TeamModel;
  loadList: () => void;
}

const TeamTableRow: FC<TeamTableRowProps> = ({ team, loadList, ...props }) => {
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{team.id}</TableCell>
        <TableCell>{team.name}</TableCell>
        <TableCell style={{ textAlign: 'center', paddingRight: 36 }}>{team.code}</TableCell>
        <TableCell>{team.adminEmail}</TableCell>
        <TableCell>{formatDate(team.createdAt * 1000, DTO_DATE_TIME_FORMAT)}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{team.bottlesCollected}</TableCell>
        <TableCell>{team.goal}</TableCell>
        <TableCell>{team.endedAt ? formatDate(team.endedAt * 1000, DTO_DATE_TIME_FORMAT) : 'In process'}</TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={openEditDialog}>
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <TeamDelete team={team} onSubmit={loadList} />
        </TableCell>
      </TableRow>

      <TeamDialog open={editDialogOpen} onClose={closeEditDialog} team={team} onSubmit={loadList} />
    </Fragment>
  );
};

export default TeamTableRow;
