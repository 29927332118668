import React, { FC } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { isFunction } from 'utils/function';

import { TransformWrapperChildrenProps } from './image-zoom.types';

type ImageZoomProviderChildren = (props: TransformWrapperChildrenProps) => JSX.Element;

interface ImageZoomProviderProps {
  children: ImageZoomProviderChildren | React.ReactNode;
}

export const ImageZoomProvider: FC<ImageZoomProviderProps> = ({ children }) => {
  return (
    <TransformWrapper
      options={{ limitToBounds: true, transformEnabled: true }}
      wheel={{
        wheelEnabled: true,
        touchPadEnabled: true,
      }}
      pan={{
        velocity: false,
      }}
    >
      {isFunction(children)
        ? (props: TransformWrapperChildrenProps) => (children as ImageZoomProviderChildren)(props)
        : children}
    </TransformWrapper>
  );
};
