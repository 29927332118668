import React, { FC, Fragment } from 'react';

import { BottleModelModel, FirmwareModel } from 'models';
import { TableRow, TableCell, TableRowProps, useDialogState } from 'components';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from 'assets/icons';

import BottleModelDialog from './bottle-model-dialog.component';
import BottleModelDelete from './bottle-model-delete.component';

interface BottleModelTableRowProps extends TableRowProps {
  bottleModel: BottleModelModel;
  loadList: () => void;
  firmwareList: FirmwareModel[];
}

const BottleModelTableRow: FC<BottleModelTableRowProps> = ({ bottleModel, loadList, firmwareList, ...props }) => {
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();

  return (
    <Fragment>
      <TableRow {...props}>
        <TableCell>{bottleModel.id}</TableCell>
        <TableCell>{bottleModel.version}</TableCell>
        <TableCell>{bottleModel.description}</TableCell>
        <TableCell>
          {bottleModel.firmware_id ? firmwareList.find((v) => bottleModel.firmware_id === v.id)?.version : null}
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={openEditDialog}>
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <BottleModelDelete bottleModel={bottleModel} onSubmit={loadList} />
        </TableCell>
      </TableRow>

      <BottleModelDialog
        open={editDialogOpen}
        onClose={closeEditDialog}
        bottleModel={bottleModel}
        onSubmit={loadList}
        firmwareList={firmwareList}
      />
    </Fragment>
  );
};

export default BottleModelTableRow;
