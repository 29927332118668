import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';

interface SectionTitleProps {
  title: React.ReactNode;
  noWrap?: boolean;
}

const SectionTitle: FC<SectionTitleProps> = ({ title, noWrap }) => {
  return (
    <Typography variant="h6" noWrap={noWrap}>
      <small>{title}</small>
    </Typography>
  );
};

export default SectionTitle;
