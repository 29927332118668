import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';

const LINE_HEIGHT = 1.43;

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    whiteSpace: 'pre-line',
    '&:after': {
      content: '""',
      textAlign: 'right',
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '100%',
      height: `${LINE_HEIGHT}em`,
      background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%)',
    },
  },
}));

interface MultilineTruncateProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  maxLine: number;
}

const MultilineTruncate: FC<MultilineTruncateProps> = ({ maxLine, style, ...props }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={maxLine ? { height: `${maxLine * LINE_HEIGHT}em`, ...(style || {}) } : style}
      {...props}
    />
  );
};

export default MultilineTruncate;
