import request, { generateUrl, Methods } from 'utils/request';
import { CreateEventDto, QueryParamModel, EventModel, ListResponseModel } from 'models';

export class EventService {
  private readonly resource = 'water-events';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<EventModel>>({ resource: `${this.resource}/allfront`, params });
  }

  create(data: CreateEventDto) {
    return request<EventModel>({ method: Methods.POST, resource: this.resource, data });
  }

  update(id: number, data: CreateEventDto) {
    return request<EventModel>({ method: Methods.PUT, resource: `${this.resource}/${id}`, data });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `${this.resource}/${id}` });
  }

  usage(userId: number): Promise<any> {
    return request<any>({resource: `${this.resource}/usage/${userId}` });
  }

  getExportUrl(token: string | null, params?: QueryParamModel) {
    return generateUrl({ resource: `${this.resource}/export`, params: { ...params, token } });
  }
}
