import React, { FC } from 'react';
import Container from '@material-ui/core/Container';

import { Header, BreadcrumbStep, Loader } from 'components';

interface PageProps {
  title?: React.ReactNode;
  steps?: BreadcrumbStep[];
  className?: string;
  loading?: boolean;
}

const Page: FC<PageProps> = ({ title, steps, className, loading, children }) => {
  return (
    <Container maxWidth="xl" className={className}>
      <Loader show={loading} />
      <Header title={title} steps={steps} />
      {children}
    </Container>
  );
};

export default Page;
