import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import Box from '@material-ui/core/Box';

import { Button, Dialog, TextField } from 'components';
import { NotificationType, SubTeamModel, AddSubTeamModel } from 'models';
import { WithNotification } from 'store';
import { Yup } from 'utils/yup';
import { getErrors } from 'utils/request';

interface SubTeamDialogProps extends WithNotification {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  subTeam?: SubTeamModel;
  addSubTeam: (data: AddSubTeamModel) => Promise<any>;
  updateSubTeam: (id: number, data: AddSubTeamModel) => Promise<any>;
  subTeamSaving: boolean;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(20)
    .required(),
  code: Yup.string()
    .max(3)
    .required(),
});

const SubTeamDialog: FC<SubTeamDialogProps> = ({
  open,
  onClose,
  subTeam,
  showNotification,
  onSubmit,
  subTeamSaving,
  addSubTeam,
  updateSubTeam,
}) => {
  const getInitialValues = (subTeam?: SubTeamModel) => {
    return subTeam
      ? { ...subTeam }
      : ({
          name: '',
          code: '',
        } as AddSubTeamModel);
  };

  const handleSubmit = async (values: AddSubTeamModel, { setFieldError }: FormikHelpers<AddSubTeamModel>) => {
    try {
      const data = { ...values };
      if (subTeam) {
        await updateSubTeam(subTeam.id, data);
      } else {
        await addSubTeam(data);
      }
      showNotification(<Trans i18nKey="save_successful" />, NotificationType.SUCCESS);
      onClose();
      onSubmit();
    } catch (e) {
      getErrors(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={subTeam ? <Trans i18nKey="sub_team_edit" /> : <Trans i18nKey="sub_team_new" />}
      showClose
    >
      <Formik
        initialValues={getInitialValues(subTeam)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        <Form>
          <Field
            component={TextField}
            name="name"
            maxLength={20}
            fullWidth
            label={<Trans i18nKey="team_name" />}
            margin="normal"
          />
          <Field
            component={TextField}
            name="code"
            maxLength={3}
            fullWidth
            label={<Trans i18nKey="team_code" />}
            margin="normal"
          />
          <Box textAlign="right">
            <Button type="submit" loading={subTeamSaving}>
              <Trans i18nKey="save" />
            </Button>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default inject(({ subTeam, common }) => {
  return {
    showNotification: common.showNotification,
    addSubTeam: subTeam.addTeam,
    updateSubTeam: subTeam.updateTeam,
    subTeamSaving: subTeam.teamSaving,
  };
})(observer(SubTeamDialog));
