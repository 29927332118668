import { action, observable } from 'mobx';

import { EventService } from 'services';
import { CreateEventDto, QueryParamModel, EventModel } from 'models';
import { AuthStore } from './auth.store';

export class EventStore {
  private readonly eventService = new EventService();
  private readonly authStore: AuthStore;

  @observable events: EventModel[] = [];
  @observable eventCount = 0;

  @observable eventLoading = false;
  @observable eventSaving = false;
  @observable eventDeleting = false;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  @action.bound
  async getEvents(params?: QueryParamModel) {
    this.eventLoading = true;

    try {
      const response = await this.eventService.getList(params);

      this.eventCount = response.count;
      this.events = response.data;

      return response;
    } finally {
      this.eventLoading = false;
    }
  }

  @action.bound
  async updateEvent(id: number, data: CreateEventDto) {
    this.eventSaving = true;

    try {
      await this.eventService.update(id, data);
    } finally {
      this.eventSaving = false;
    }
  }

  @action.bound
  async createEvent(data: CreateEventDto) {
    this.eventSaving = true;

    try {
      await this.eventService.create(data);
    } finally {
      this.eventSaving = false;
    }
  }

  @action.bound
  async deleteEvent(id: number) {
    this.eventDeleting = true;

    try {
      await this.eventService.delete(id);
    } finally {
      this.eventDeleting = false;
    }
  }

  @action.bound
  getExportUrl(params?: QueryParamModel) {
    return this.eventService.getExportUrl(this.authStore.token, params);
  }
}
