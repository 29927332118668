import React, { FC, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { Formik, Form, FormikProps, FormikValues } from 'formik';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FilterListIcon from '@material-ui/icons/FilterList';
import { Button } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  filterContainer: {
    width: 600,
    maxWidth: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    ...theme.mixins.gutters(),
  },
  header: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterButton: {
    marginLeft: theme.spacing(2),
  },
}));

interface ComplexTableFilterProps<Values extends FormikValues = FormikValues> {
  filters: Values;
  tooltip?: React.ReactNode;
  onCancel?: () => void;
  onOpen?: () => void;
  onFilter?: (values: Values) => void;
  children?: (props: FormikProps<Values>) => React.ReactNode;
}

const ComplexTableFilter: FC<ComplexTableFilterProps> = ({
  children,
  filters,
  onCancel,
  onFilter,
  onOpen,
  tooltip,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const disableScrollLock = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const open = Boolean(anchorEl);

  const openFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onOpen) {
      onOpen();
    }

    setAnchorEl(event.currentTarget);
  };

  const closeFilter = () => {
    if (onCancel) {
      onCancel();
    }

    setAnchorEl(null);
  };

  const onClose = <Values extends FormikValues>(props: FormikProps<Values>) => () => {
    props.resetForm();

    closeFilter();
  };

  const onClear = <Values extends FormikValues>(props: FormikProps<Values>) => () => {
    props.setValues(
      Object.keys(filters).reduce((value, key: keyof Values) => {
        value[key] = '' as any;
        return value;
      }, {} as Values)
    );
  };

  const onSubmit = <Values extends FormikValues>(values: Values) => {
    if (onFilter) {
      onFilter(values);
    }

    closeFilter();
  };

  const getFilterCount = () => {
    return filters ? Object.values(filters).filter((v) => v).length || null : null;
  };

  return (
    <Fragment>
      <Tooltip title={tooltip || <Trans i18nKey="filter_list" />}>
        <IconButton onClick={openFilter} tabIndex={-1}>
          <Badge badgeContent={getFilterCount()} color="primary">
            <FilterListIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
        disableScrollLock={disableScrollLock}
      >
        <div className={classes.filterContainer}>
          <Formik initialValues={filters} onSubmit={onSubmit}>
            {(props) => (
              <Form>
                <div className={classes.header}>
                  <Typography variant="h6" className={classes.headerTitle}>
                    <Trans i18nKey="filter" />
                  </Typography>
                  <Button variant="text" color="secondary" size="small" onClick={onClear(props)}>
                    <Trans i18nKey="clear" />
                  </Button>
                </div>

                <div className={classes.content}>{children ? children(props) : null}</div>
                <div className={classes.actions}>
                  <Button variant="outlined" color="secondary" onClick={onClose(props)}>
                    <Trans i18nKey="cancel" />
                  </Button>
                  <Button type="submit" className={classes.filterButton}>
                    <Trans i18nKey="filter" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Popover>
    </Fragment>
  );
};

export default ComplexTableFilter;
