import { action, observable } from 'mobx';

import { QueryParamModel, LogsModel } from 'models';
import { LogsService } from '../services/logs.service';

export class LogsStore {
  private readonly logsService = new LogsService();

  @observable logs: LogsModel[] = [];
  @observable logsLoading = false;
  @observable logsCount = 0;

  @action.bound
  async getLogs(params?: QueryParamModel) {
    this.logsLoading = true;

    try {
      const response = await this.logsService.getList(params);

      this.logsCount = response.count;
      this.logs = response.data;

      return response;
    } finally {
      this.logsLoading = false;
    }
  }

  @action.bound
  async getExportUrl(params?: QueryParamModel) {
    this.logsLoading = true;

    try {
      const response = await this.logsService.getList(params);

      this.logsCount = response.count;
      this.logs = response.data;

      return response;
    } finally {
      this.logsLoading = false;
    }
  }
}
