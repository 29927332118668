import React, { FC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { AccessController, AccessRestriction } from 'components';

interface ProtectedRouteProps extends RouteProps, AccessRestriction {
  noAccessRedirect?: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component: Component,
  render,
  allowedFor,
  noAccessRedirect,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(routeProps) => (
        <AccessController
          allowedFor={allowedFor}
          noAccessChildren={noAccessRedirect ? <Redirect to={noAccessRedirect} /> : null}
        >
          {Component ? <Component {...routeProps} /> : render ? render(routeProps) : null}
        </AccessController>
      )}
    />
  );
};

export default ProtectedRoute;
