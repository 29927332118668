import { action, observable } from 'mobx';

import { NotificationContent, NotificationModel, NotificationType, QueryParamModel } from 'models';

export const DEFAULT_QUERY_PARAMS = {
  page: 1,
  pageSize: 20,
  filter: {},
};

export interface WithNotification {
  showNotification: (content: NotificationContent, type: NotificationType) => void;
  hideNotification?: () => void;
}

export class CommonStore implements WithNotification {
  @observable drawerOpen = localStorage.getItem('drawerOpen') !== 'false';
  @observable mobileDrawerOpen = false;
  @observable isLoading = false;
  @observable notification: NotificationModel | null = null;
  @observable filterParams: QueryParamModel = DEFAULT_QUERY_PARAMS;

  @action.bound
  toggleDrawer() {
    this.drawerOpen = !this.drawerOpen;

    localStorage.setItem('drawerOpen', String(this.drawerOpen));
  }

  @action.bound
  toggleMobileDrawer() {
    this.mobileDrawerOpen = !this.mobileDrawerOpen;
  }

  @action.bound
  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  @action.bound
  hideLoader() {
    this.isLoading = false;
  }

  @action.bound
  showLoader() {
    this.isLoading = true;
  }

  @action.bound
  showNotification(content: NotificationContent, type: NotificationType = NotificationType.INFO) {
    this.notification = {
      content,
      type,
    };
  }

  @action.bound
  hideNotification() {
    this.notification = null;
  }

  @action.bound
  setFilterParams(filterParams: QueryParamModel) {
    this.filterParams = filterParams;
  }
}
