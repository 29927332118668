import React, { FC, Fragment, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { TabPanel } from 'components';

export interface DetailTabItem {
  key: string | number;
  title: React.ReactNode;
  content: React.ReactNode;
}

interface DetailTabsProps {
  tabs: DetailTabItem[];
}

const useStyles = makeStyles((theme: Theme) => ({
  tabsContainer: {
    zIndex: 1,
  },
  scrollButtons: {
    transition: theme.transitions.create('box-shadow'),
    '&:not(:empty)': {
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
    },
  },
  content: {
    overflowY: 'auto',
  },
}));

const DetailTabs: FC<DetailTabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, activeTab: number) => {
    setActiveTab(activeTab);
  };

  return (
    <Fragment>
      <Paper square elevation={2} className={classes.tabsContainer}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          onChange={handleTabChange}
          classes={{
            scrollButtons: classes.scrollButtons,
          }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.key} label={tab.title} />
          ))}
        </Tabs>
      </Paper>
      {tabs.map((tab, index) => (
        <TabPanel active={activeTab} index={index} key={tab.key} className={classes.content}>
          {tab.content}
        </TabPanel>
      ))}
    </Fragment>
  );
};

export default DetailTabs;
