import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { Trans } from 'react-i18next';

import { UserDetailModel, UserModel } from 'models';
import { Dialog, DetailTabs, DetailTabItem } from 'components';

import GeneralUserInformation from './general-user-information.component';
import UserRoles from './user-roles.component';

export interface UserDetailModalProps {
  open: boolean;
  title: string;
  userId: number;
  onClose: () => void;
  getUser: (id: number) => Promise<UserDetailModel>;
  refreshList: () => Promise<UserModel[]>;
  resetUser: () => void;
  user: UserDetailModel;
  userLoading: boolean;
}
/*
   <UserDetailModal
          open={dialogOpen}
          onClose={handleDialogClose}
          title={user.name}
          userId={user.id}
          refreshList={refreshList}
        />
 */

interface UserDetailModalState {
  isRefreshNeeded: boolean;
}

class UserDetailModal extends PureComponent<UserDetailModalProps, UserDetailModalState> {
  readonly state: UserDetailModalState = {
    isRefreshNeeded: false,
  };

  componentDidUpdate(prevProps: Readonly<UserDetailModalProps>) {
    if (!prevProps.open && this.props.open) {
      this.props.getUser(this.props.userId);
    }

    if (prevProps.open && !this.props.open && this.state.isRefreshNeeded) {
      this.props.refreshList();
    }
  }

  componentWillUnmount() {
    this.props.resetUser();
  }

  onDialogExited = () => {
    this.props.resetUser();
  };

  setRefreshNeeded = (isRefreshNeeded = true) => {
    this.setState({ isRefreshNeeded });
  };

  render() {
    const { onClose, open, title, userLoading, user } = this.props;

    const tabs: DetailTabItem[] = [
      {
        key: 'user-general-information',
        title: <Trans i18nKey="user_general_information" />,
        content: <GeneralUserInformation user={user} />,
      },
      /*{
         key: 'user-roles',
         title: <Trans i18nKey="user_roles" />,
         content: <UserRoles user={user} />,
      },*/
    ];

    return (
      <Dialog
        maxWidth="md"
        onClose={onClose}
        open={open}
        title={title}
        loading={userLoading && !user}
        onExited={this.onDialogExited}
        showClose
        disableContentPadding
      >
        {user ? <DetailTabs tabs={tabs} /> : null}
      </Dialog>
    );
  }
}

const mapStoreToProps = ({ users }: StoreModel) => {
  return {
    getUser: users.getUser,
    resetUser: users.resetUser,
    user: users.user,
    userLoading: users.userLoading,
  };
};

export default inject(mapStoreToProps)(observer(UserDetailModal));
