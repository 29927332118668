import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    color: 'inherit',
    padding: theme.spacing(2),
    textAlign: 'inherit',
    flexDirection: 'inherit',
  },
  linkCell: {
    padding: 0,
    '&.MuiTableCell-sizeSmall': {
      '& $link': {
        padding: '6px 24px 6px 16px',
      },
    },
    '&.MuiTableCell-paddingCheckbox': {
      '& $link': {
        padding: '0 0 0 4px',
      },
    },
  },
}));

export interface TableLinkProps {
  link?: boolean;
  to?: string;
  replace?: boolean;
}

export interface TableCellProps extends MuiTableCellProps, TableLinkProps {
  noWrap?: boolean;
}

const TableCell: FC<TableCellProps> = ({ noWrap, className, children, link, to, replace, ...props }) => {
  const classes = useStyles();

  return (
    <MuiTableCell
      className={classNames(className, { [classes.noWrap]: noWrap, [classes.linkCell]: link && to })}
      {...props}
    >
      {link && to ? (
        <Link to={to} className={classes.link} replace={replace}>
          {children}
        </Link>
      ) : (
        children
      )}
    </MuiTableCell>
  );
};

export default TableCell;
