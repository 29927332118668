import request, { generateUrl } from 'utils/request';
import { ListResponseModel, QueryParamModel, WorkoutEventModel } from 'models';

export class WorkoutEventService {
  private readonly resource = 'workout-events';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<WorkoutEventModel>>({ resource: `${this.resource}/all`, params });
  }

  getExportUrl(token: string | null, params?: QueryParamModel) {
    return generateUrl({ resource: `${this.resource}/export`, params: { ...params, token } });
  }
}
