import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormHelperText from '@material-ui/core/FormHelperText';

import { ApiErrorMessageMap, ApiErrorModel } from 'models';
import { getErrors } from 'utils/request';

interface ApiErrorsProps {
  error: ApiErrorModel | null;
}

const ApiErrors: FC<ApiErrorsProps> = ({ error }) => {
  const { t } = useTranslation();
  const errors = getErrors(error);

  return error && errors.length ? (
    <>
      {errors.map((error) =>
        ApiErrorMessageMap[error] ? (
          <FormHelperText error key={error}>
            {t(ApiErrorMessageMap[error])}
          </FormHelperText>
        ) : null
      )}
    </>
  ) : null;
};

export default ApiErrors;
