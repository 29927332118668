import { action, observable } from 'mobx';

import { SettingsService } from 'services';
import { CalendarModel } from 'models';

export class SettingsStore {
  private readonly settingsService = new SettingsService();

  @observable calendarSettings: CalendarModel = {
    holidays: [],
    extraDays: [],
  };

  @observable calendarSettingsLoading = false;
  @observable calendarSettingsSaving = false;

  @action.bound
  async getCalendarSettings(year: number) {
    this.calendarSettingsLoading = true;
    this.calendarSettings = {
      holidays: [],
      extraDays: [],
    };

    try {
      const { holidays, extraDays } = await this.settingsService.getCalendarSettings(year);

      this.calendarSettings = {
        holidays,
        extraDays,
      };

      return this.calendarSettings;
    } finally {
      this.calendarSettingsLoading = false;
    }
  }

  @action.bound
  async saveCalendarSettings(year: number, settings: CalendarModel) {
    this.calendarSettingsSaving = true;

    try {
      this.calendarSettings = await this.settingsService.saveCalendarSettings(year, settings);

      return this.calendarSettings;
    } finally {
      this.calendarSettingsSaving = false;
    }
  }
}
