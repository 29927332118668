import React, { FC } from 'react';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import LoadingOverlay from './loading-overlay.component';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: 1100,
    background: fade(theme.palette.common.white, 0.9),
    [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  shift: {
    [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
      left: theme.mixins.drawer.width,
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  fullWidth: {
    left: 0,
  },
}));

interface LoaderProps {
  show?: boolean;
  fullWidth?: boolean;
  isAppLoading: boolean;
  drawerOpen: boolean;
}

const Loader: FC<LoaderProps> = ({ show = false, isAppLoading, drawerOpen, fullWidth }) => {
  const classes = useStyles();

  return (
    <LoadingOverlay
      show={show || isAppLoading}
      className={classNames(classes.root, {
        [classes.shift]: drawerOpen,
        [classes.fullWidth]: fullWidth,
      })}
    />
  );
};

export default inject(({ common }) => ({ isAppLoading: common.isLoading, drawerOpen: common.drawerOpen }))(
  observer(Loader)
);
