import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import { CommonStore } from 'store';
import { ProfileModel } from 'models';
import { Breadcrumb, BreadcrumbStep } from 'components';

import UserMenu from './user-menu.component';

const useStyles = makeStyles((theme: Theme) => {
  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
    appBarShift: {
      [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
        width: `calc(100% - ${theme.mixins.drawer.width}px)`,
        marginLeft: theme.mixins.drawer.width,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    hamburgerIcon: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
        marginLeft: -theme.spacing(3.5),
        marginRight: theme.spacing(4.5),
      },
    },
    toolbar: {
      [theme.breakpoints.up(theme.breakpoints.mobileBreakpoint)]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    titleContainer: {
      overflowX: 'hidden',
    },
  };
});

interface HeaderProps {
  title?: React.ReactNode;
  steps?: BreadcrumbStep[];
  commonStore: CommonStore;
  profile: ProfileModel;
  logout: () => Promise<void>;
}

const Header: FC<HeaderProps> = ({ title, commonStore, profile, logout, steps }) => {
  const classes = useStyles();
  const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up(theme.breakpoints.mobileBreakpoint));

  return (
    <AppBar position="fixed" className={classNames(classes.appBar, { [classes.appBarShift]: commonStore.drawerOpen })}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center" width="100%" height="100%">
          {!commonStore.drawerOpen || !largeScreen ? (
            <IconButton
              edge="start"
              className={classes.hamburgerIcon}
              onClick={largeScreen ? commonStore.toggleDrawer : commonStore.toggleMobileDrawer}
              color="inherit"
            >
              <MenuIcon color="inherit" />
            </IconButton>
          ) : null}
          <Box mr="auto" className={classes.titleContainer}>
            {!steps ? (
              <Typography variant="h6" noWrap>
                {title}
              </Typography>
            ) : null}
            {steps ? <Breadcrumb variant="h6" steps={steps} /> : null}
          </Box>
          <UserMenu profile={profile} logout={logout} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default inject(({ common, auth }) => ({
  commonStore: common,
  profile: auth.profile,
  logout: auth.logout,
}))(observer(Header));
