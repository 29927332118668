import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Page } from 'components';

import EventList from './event-list.component';

const Event: FC = () => {
  return (
    <Page title={<Trans i18nKey="events" />}>
      <EventList />
    </Page>
  );
};

export default Event;
