import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Page } from 'components';

import NewsList from './news-list.component';

const News: FC = () => {
  return (
    <Page title={<Trans i18nKey="news" />}>
      <NewsList />
    </Page>
  );
};

export default News;
