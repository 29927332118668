import React, { FC, ReactElement, useState } from 'react';

import MuiMenu, { MenuProps as MuiMenuProps } from '@material-ui/core/Menu';
import { PopoverOrigin } from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';

interface MenuProps extends Omit<MuiMenuProps, 'open' | 'anchorEl' | 'onClose'> {
  children: (props: { onClick: (event: React.MouseEvent<any>) => void }) => React.ReactNode;
  items: React.ReactNode;
}

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = { vertical: 'bottom', horizontal: 'center' };
const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'center' };

const Menu: FC<MenuProps> = ({ children, items, ...props }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {children({ onClick: handleOpen })}
      <MuiMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={DEFAULT_ANCHOR_ORIGIN}
        transformOrigin={DEFAULT_TRANSFORM_ORIGIN}
        {...props}
      >
        {React.Children.map(items, (item) => {
          if (React.isValidElement(item) && item.type === MenuItem) {
            return React.cloneElement(item as ReactElement, {
              onClick: (...args: any[]) => {
                if (item.props.onClick) {
                  item.props.onClick(...args);
                }

                handleClose();
              },
            });
          }

          return item;
        })}
      </MuiMenu>
    </>
  );
};

export default Menu;
