import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Page } from 'components';
import UserList from './user-list/user-list.component';

const Users: FC = () => {
  return (
    <Page title={<Trans i18nKey="users" />}>
      <UserList />
    </Page>
  );
};

export default Users;
