import request, { Methods } from 'utils/request';
import { CreateNotificationsDto, ListResponseModel, QueryParamModel, NotificationsModel } from 'models';

export class NotificationsService {
  private readonly resource = 'notification';

  getList(params?: QueryParamModel) {
    return request<ListResponseModel<NotificationsModel>>({ resource: this.resource, params });
  }

  create(data: CreateNotificationsDto) {
    return request<NotificationsModel>({ method: Methods.POST, resource: this.resource, data });
  }

  update(id: number, data: CreateNotificationsDto) {
    return request<NotificationsModel>({ method: Methods.PUT, resource: `${this.resource}/${id}`, data });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `${this.resource}/${id}` });
  }
}
