import { extract } from 'i18n';

export enum ApiErrorEnum {
  MISSING_TOKEN = 0,
  INVALID_TOKEN = 119,
  EXPIRED_TOKEN = 116,
  EXPIRED_REFRESH_TOKEN = 117,
  INVALID_CREDENTIAL = 101,
  COULD_NOT_CREATE_TOKEN = 0,
  UNAUTHORIZED = 101,

  MUST_BE_FILE = 3007,
  FIELD_MUST_BE_DATE = 3008,
  DATE_FIELD_MUST_BE_AFTER_OR_EQUAL_TODAY = 3009,
  FIELD_UNIQUE = 3129,
  PRODUCT_IS_STANDARD = 3141,
  USER_ROLE_CANT_BE_DELETED = 3142,

  NOT_ENOUGH_COUPON_FOR_ALL_USERS = 3500,
  COUPON_WAS_EXPIRED = 3501,
}

export const ApiErrorMessageMap: Record<number, string> = {
  [ApiErrorEnum.MUST_BE_FILE]: extract('error_must_be_file'),
  [ApiErrorEnum.FIELD_MUST_BE_DATE]: extract('error_field_must_be_date'),
  [ApiErrorEnum.DATE_FIELD_MUST_BE_AFTER_OR_EQUAL_TODAY]: extract('error_date_field_must_be_after_or_equal_today'),

  [ApiErrorEnum.FIELD_UNIQUE]: extract('error_field_unique'),
  [ApiErrorEnum.PRODUCT_IS_STANDARD]: extract('error_product_is_standard'),
  [ApiErrorEnum.USER_ROLE_CANT_BE_DELETED]: extract('error_user_role_cant_be_deleted'),

  [ApiErrorEnum.NOT_ENOUGH_COUPON_FOR_ALL_USERS]: extract('not_enough_coupon_for_all_users'),
  [ApiErrorEnum.COUPON_WAS_EXPIRED]: extract('coupon_was_expired'),
};
