import { ListResponseModel, QueryParamModel, BottleVariantModel, BottleModel} from 'models';
import request from 'utils/request';

export class BottleService {
  private readonly resource = 'bottle-hardware';

  getBottleVariants(params?: QueryParamModel) {
    return request<ListResponseModel<BottleVariantModel>>({ resource: `${this.resource}/variants`, params });
  }

  getBottleByOwner(userId: number, params?: QueryParamModel) {
    return request<ListResponseModel<BottleModel>>({ resource: `${this.resource}/user/${userId}`, params });
  }

}
