import * as Yup from 'yup';
import { extract } from 'i18n';

Yup.setLocale({
  mixed: {
    required: extract('errors_field_required'),
  },
  string: {
    email: extract('errors_email_invalid'),
  },
  number: {
    integer: extract('errors_field_must_be_integer'),
  },
});

export { Yup };
