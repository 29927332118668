function fallbackCopyTextToClipboard(text: string): Promise<boolean> {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  return new Promise((resolve, rejects) => {
    try {
      const successful = document.execCommand('copy');
      resolve(successful);
    } catch (err) {
      rejects();
    }

    document.body.removeChild(textArea);
  });
}
export function copyTextToClipboard(text: string): Promise<boolean> {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return navigator.clipboard.writeText(text).then(() => true);
}
