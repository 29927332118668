import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import request from '../../../utils/request';
import Chart from 'react-google-charts';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

interface StatisticRow {
  userIds: number[];
  bottlesSaved: number;
  bottlesCollected: number;
  totalNumber: number;
}

interface StatisticByDate {
  [date: string]: StatisticRow;
}

interface StatisticByDateWithUsers {
  stat: StatisticByDate;
  userIds: number[];
}

interface StatisticByTeam {
  [subTeam: string]: StatisticByDateWithUsers;
}

interface StatisticResponse {
  stat: StatisticByTeam;
  dates: string[];
}

export default function Stat() {
  const params = useLocation();
  const teamCode = params.pathname.replace('/stat/', '');

  const [statistic, setStatistic] = useState<StatisticResponse>({
    stat: {},
    dates: [],
  });

  useEffect(() => {
    request<StatisticResponse>({
      resource: `leaderboard/${teamCode}`,
    }).then((result) => {
      setStatistic(result);
    });
  }, []);

  const teamWeeklyData = useMemo(() => {
    const headers = ['Date'];
    const data: { [date: string]: [string, ...number[]] } = {};

    Object.keys(statistic.stat).forEach((team) => {
      headers.push(team);
      const dataSet = statistic.stat[team].stat;
      statistic.dates.forEach((dateString: string) => {
        if (!data[dateString]) {
          data[dateString] = [dateString];
        }
        data[dateString].push(dataSet[dateString] ? dataSet[dateString].bottlesSaved : 0);
      });
    });

    const result = Object.values(data).filter((row) => {
      const [, ...values] = row;
      return Math.max(...values) > 0;
    });

    return result.length > 0 ? [headers, ...result] : [];
  }, [statistic]);

  const teamCumulativeData = useMemo(() => {
    const headers = ['Team', 'Data'];
    const result: [string, number][] = [];

    Object.keys(statistic.stat).forEach((team) => {
      const dataSet = statistic.stat[team].stat;
      result.push([team, Object.values(dataSet).reduce((acc, row) => acc + row.bottlesSaved, 0)]);
    });
    return result.length > 0 ? [headers, ...result] : [];
  }, [statistic]);

  const teamWeeklyAverageData = useMemo(() => {
    const headers = ['Date'];
    const data: { [date: string]: [string, ...number[]] } = {};

    Object.keys(statistic.stat).forEach((team) => {
      headers.push(team);
      const userCount = statistic.stat[team].userIds.length;
      const dataSet = statistic.stat[team].stat;
      statistic.dates.forEach((dateString: string) => {
        if (!data[dateString]) {
          data[dateString] = [dateString];
        }
        const record = dataSet[dateString];
        data[dateString].push(record ? Math.round((record.bottlesSaved / userCount) * 10) / 10 : 0);
      });
    });

    const result = Object.values(data).filter((row) => {
      const [, ...values] = row;
      return Math.max(...values) > 0;
    });

    return result.length > 0 ? [headers, ...result] : [];
  }, [statistic]);

  const teamCumulativeAverageData = useMemo(() => {
    const headers = ['Team', 'Data'];
    const result: [string, number][] = [];

    Object.keys(statistic.stat).forEach((team) => {
      const userCount = statistic.stat[team].userIds.length;
      const dataSet = statistic.stat[team].stat;
      result.push([
        team,
        Math.round((Object.values(dataSet).reduce((acc, row) => acc + row.bottlesSaved, 0) / userCount) * 10) / 10,
      ]);
    });

    return result.length > 0 ? [headers, ...result] : [];
  }, [statistic]);

  return (
    <Container fixed>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>Statistic for team {teamCode.substr(0, 2)}</h1>
        </Grid>
        <Grid item xs={6}>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={teamWeeklyData}
            options={{
              title: 'Team weekly view',
              hAxis: {
                title: 'Week',
                minValue: 0,
              },
              vAxis: {
                title: 'Number of bottles',
              },
              legend: 'bottom',
            }}
            legendToggle
          />
        </Grid>
        <Grid item xs={6}>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={teamCumulativeData}
            options={{
              title: 'Team cumulative view',
              hAxis: {
                title: 'Team',
                minValue: 0,
              },
              vAxis: {
                title: 'Number of bottles',
              },
              legend: 'bottom',
            }}
            legendToggle
          />
        </Grid>

        <Grid item xs={6}>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={teamWeeklyAverageData}
            options={{
              title: 'Average Team Member weekly contribution',
              hAxis: {
                title: 'Week',
                minValue: 0,
              },
              vAxis: {
                title: 'Number of bottles',
              },
              legend: 'bottom',
            }}
            legendToggle
          />
        </Grid>
        <Grid item xs={6}>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={teamCumulativeAverageData}
            options={{
              title: 'Average Team Member Cumulative contribution',
              hAxis: {
                title: 'Team',
                minValue: 0,
              },
              vAxis: {
                title: 'Number of bottles',
              },
              legend: 'bottom',
            }}
            legendToggle
          />
        </Grid>
      </Grid>
    </Container>
  );
}
