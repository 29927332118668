import { ListResponseModel, QueryParamModel, UserDetailModel, UserModel } from 'models';
import request, { generateUrl, Methods } from 'utils/request';

export class UserService {
  private readonly resource = 'users';

  getUsers(params?: QueryParamModel) {
    return request<ListResponseModel<UserModel>>({ resource: this.resource, params });
  }

  getUser(id: number) {
    return request<UserDetailModel>({ resource: `${this.resource}/${id}` });
  }

  updateUserRoles(id: number, roles: number[]) {
    return request<UserDetailModel>({ method: Methods.PUT, resource: `${this.resource}/${id}/roles`, data: { roles } });
  }

  resetPassword(id: number, email: string) {
    return request({ method: Methods.POST, resource: `auth/reset-password`, data: { email } });
  }

  addAdditionalBottles(id: number, count: number) {
    return request({ method: Methods.POST, resource: `users/addAdditionalBottles/${id}/${count}`, data: {} });
  }

  delete(id: number) {
    return request<void>({ method: Methods.DELETE, resource: `${this.resource}/${id}` });
  }

  getExportUrl(token: string | null, params?: any) {
    return generateUrl({ resource: `${this.resource}/export`, params: { ...params, token } });
  }
}
