import React, { Component } from 'react';
import { Autocomplete, AutocompleteProps, OptionType } from 'components';
import { WithTranslation } from 'react-i18next';
import { debounce } from 'utils/function';

export interface AsyncAutocompleteProps<ValueType extends OptionType = OptionType>
  extends Omit<AutocompleteProps<ValueType>, keyof WithTranslation | 'classes'> {
  loadOptions(inputValue: string, page: number): Promise<{ options: any[]; hasNext: boolean }>;
  filters: Record<string, any>;
}

interface AsyncAutocompleteState {
  options: OptionType[];
  fetchCounter: number;
  isLoading: boolean;
  isPaging: boolean;
  page: number;
  hasNext: boolean;
  inputValue: string;
}

export class AsyncAutocomplete extends Component<AsyncAutocompleteProps, AsyncAutocompleteState> {
  readonly state: AsyncAutocompleteState = {
    options: [],
    fetchCounter: 0,
    isLoading: false,
    isPaging: false,
    page: 1,
    hasNext: true,
    inputValue: '',
  };

  componentDidUpdate(prevProps: Readonly<AsyncAutocompleteProps>) {
    if (prevProps.filters !== this.props.filters) {
      this.loadOptions(this.state.inputValue);
    }
  }

  loadOptions = async (inputValue: string, page = 1) => {
    this.setState((prevState) => ({
      isLoading: true,
      isPaging: page > 1,
      options: page !== 1 ? prevState.options : [],
      fetchCounter: prevState.fetchCounter + 1,
    }));

    try {
      const { options, hasNext } = await this.props.loadOptions(inputValue, page);

      this.setState((prevState) => ({
        hasNext,
        options: page > 1 ? [...prevState.options, ...options] : options,
      }));
    } finally {
      this.setState((prevState) => ({
        isLoading: false,
        isPaging: false,
        fetchCounter: prevState.fetchCounter - 1,
      }));
    }
  };

  deferredLoadOptions = debounce((inputValue: string, page: number) => {
    this.loadOptions(inputValue, page);
  }, 250);

  componentDidMount() {
    this.loadOptions(this.state.inputValue);
  }

  handleScrollToBottom = async () => {
    if (this.state.hasNext) {
      this.setState(
        (prevState) => ({ page: prevState.page + 1 }),
        () => {
          this.loadOptions(this.state.inputValue, this.state.page);
        }
      );
    }
  };

  onInputChange = (newValue: string) => {
    if (newValue !== this.state.inputValue) {
      this.setState({ inputValue: newValue, isLoading: true });
      this.deferredLoadOptions(newValue, 1);
    }
  };

  render() {
    const { ...props } = this.props;
    const { options, inputValue, fetchCounter, isLoading, isPaging } = this.state;

    return (
      <Autocomplete
        {...props}
        options={options}
        inputValue={inputValue}
        isLoading={Boolean(fetchCounter) || isLoading}
        isPaging={isPaging}
        filterOption={null}
        onMenuScrollToBottom={this.handleScrollToBottom}
        onInputChange={this.onInputChange}
      />
    );
  }
}
