import React, { FC } from 'react';
import { TransformComponent } from 'react-zoom-pan-pinch';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ResetIcon from '@material-ui/icons/SettingsBackupRestore';

import { TransformWrapperChildrenProps } from './image-zoom.types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .react-transform-component': {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
    '& .react-transform-element': {
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
  },
  toolsContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
}));

export const ImageZoomContent: FC<TransformWrapperChildrenProps> = ({
  zoomIn,
  zoomOut,
  resetTransform,
  scale,
  children,
  options,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TransformComponent>{children}</TransformComponent>
      <div className={classes.toolsContainer}>
        <ButtonGroup color="secondary" variant="outlined">
          <Button onClick={zoomIn} disabled={scale === options.maxScale}>
            <ZoomInIcon fontSize="small" />
          </Button>
          <Button onClick={zoomOut} disabled={scale === options.minScale}>
            <ZoomOutIcon fontSize="small" />
          </Button>
          <Button onClick={resetTransform} disabled={scale === options.minScale}>
            <ResetIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
